import { IconNames } from '@hc/shared/components/icon'

import { useGetCart } from '@/domains/cart/hooks'
import { ROUTES } from '@/const/routes'

import { StyledCartButton } from './CartButton.style'

export const CartButton = () => {
  const { data } = useGetCart()

  return (
    <StyledCartButton
      link={{ href: ROUTES.SHOP.CART }}
      isReversed
      icon={IconNames.CART}
      variant="dark"
      label={data?.items?.length || 0}
    />
  )
}
