import styled from 'styled-components'

export const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.COLOR.GOLD_10};
`

export const StyledContentContainer = styled.main`
  padding: 8rem 0 2.4rem 0;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    width: 100%;
    padding: 6.4rem 0;
  }
`

export const StyledLoaderContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
