import { Locale } from '../utils/types'

const SITE_ROOT = '/'
const STORAGE_PREV_PAGE = 'prev_page'
const STORAGE_LANG = 'i18next_lang'
export { STORAGE_PREV_PAGE, SITE_ROOT, STORAGE_LANG }

export const ALL_LANGUAGES: Locale[] = ['nl', 'de', 'en']

export const SITE_EXCLUDES = [
  '/**/404',
  '/**/404.html',
  '/templates/*',
  '/redirects/*',
  '/test/*',
  '/test-folder/*',
  '/vacatures/*/*/solliciteren/bedankt'
]

export const SITEMAP_EXCLUDES = [...SITE_EXCLUDES, '/enable-preview-mode']
