import styled from 'styled-components'
import { Button } from '@hc/shared/components/button'

export const StyledCartButton = styled(Button)`
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  height: 3.2rem;
  display: none;

  > div {
    height: 1.7rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    height: 4.1rem;
    display: flex;
  }

  font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_XS}rem;
  margin-right: ${({ theme }) =>
    (theme.LAYOUT.LAYOUT_GRID_GAP.XS / 2) * 0.1}rem;
`
