import styled, { css } from 'styled-components'
import { HeadingTypes } from '@hc/shared/types/typography'
import { Icon } from '@hc/shared/components/icon'
import { LayoutContainer } from '@hc/shared/components/layout'

interface IProps {
  isActive?: boolean
  isFixed?: boolean
  isActiveIndex?: boolean
  isCaret?: boolean
  isArrow?: boolean
}

export const StyledContainer = styled(LayoutContainer)`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding: 0;
    margin: 0;
    width: unset;
    flex-grow: 4;
  }
`

export const StyledNav = styled.nav<IProps>`
  display: flex;
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    position: fixed;
    display: block;
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    border-radius: 0 0 ${({ theme }) => theme.BORDER_RADIUS.XL}rem
      ${({ theme }) => theme.BORDER_RADIUS.XL}rem;
    overflow: hidden;
    top: 6.4rem;
    left: 0;
    z-index: -1;
    transform: translateY(-100vh);
    transition: 0.2s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
    height: calc(100% - 6.4rem - 1.6rem);
    max-height: 100%;

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateY(0);
      `};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-left: auto;
    z-index: ${({ theme }) => theme.Z_INDEX.NAVIGATION};
  }
`

export const StyledNavList = styled.ul`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    overflow: auto;
    height: calc(100% - 6rem);
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
  }
`

export const StyledNavItem = styled.li`
  & + & {
    @media screen and (max-width: ${({ theme }) =>
        theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
      border-top: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-left: 3.8rem;
  }
`

export const StyledNavButton = styled.button<IProps>`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  position: relative;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2.8rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    color: ${({ theme }) => theme.COLOR.NEUTRAL_10};

    ${({ isActive, isFixed }) =>
      (isActive || isFixed) &&
      css`
        color: ${({ theme }) => theme.COLOR.BLUE_90};
      `};

    ${({ isActiveIndex }) =>
      isActiveIndex &&
      css`
        color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
          bottom: -2.3rem;
          left: 0;
        }
      `};
  }
`

export const StyledIcon = styled(Icon)<IProps>`
  fill: ${({ theme }) => theme.COLOR.BLUE_10};
  color: ${({ theme }) => theme.COLOR.BLUE_10};
  margin-left: 1.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    ${({ isCaret }) =>
      isCaret &&
      css`
        display: none;
      `};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    border: none;
    padding: unset;
    height: 1.2rem;

    ${({ isArrow }) =>
      isArrow &&
      css`
        display: none;
      `};

    ${({ isActive, isFixed }) =>
      (isActive || isFixed) &&
      css`
        fill: ${({ theme }) => theme.COLOR.BLUE_90};
      `};

    ${({ isActiveIndex }) =>
      isActiveIndex &&
      css`
        fill: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
      `};
  }
`
