const LAYOUT_MAX_CONTAINER_WIDTH = 1920
const LAYOUT_GRID_COLUMNS = 12

const LAYOUT_BREAKPOINT = {
  XXS: 0,
  XS: 375,
  SM: 768,
  MD: 1024,
  LG: 1440
}

const LAYOUT_GRID_GAP = {
  XXS: 16,
  XS: 16,
  SM: 16,
  MD: 16,
  LG: 24
}

const LAYOUT_GRID_OFFSET = {
  XXS: 32,
  XS: 32,
  SM: 32,
  MD: 32,
  LG: 72
}

const LAYOUT_EXTRA_WIDTH = {
  XXS: 0,
  XS: 0,
  SM: 0,
  MD: 0,
  LG: 32
}

export const LAYOUT = {
  LAYOUT_MAX_CONTAINER_WIDTH,
  LAYOUT_GRID_COLUMNS,
  LAYOUT_BREAKPOINT,
  LAYOUT_GRID_GAP,
  LAYOUT_GRID_OFFSET,
  LAYOUT_EXTRA_WIDTH
}
