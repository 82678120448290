import { useState, useCallback, useEffect } from 'react'
import { LayoutContainer } from '@hc/shared/components/layout'
import { IconNames } from '@hc/shared/components/icon'
import { IHeaderProps } from '@hc/shared/domains/storyblok/mappers/map-header'
import {
  ScrollDirection,
  useScrollDirection
} from '@hc/shared/hooks/use-scroll-direction'
import { useIsLG } from '@hc/shared/hooks/use-media-query'
import { SBPreview } from '@hc/shared/domains/storyblok/utils/sbPreview'
import { HeaderPreviewTokenMessage } from '@hc/shared/components/header/HeaderPreviewTokenMessage'

import { LanguageSwitchHeader } from '@/components/language-switch-header'
import { SITE_ROOT } from '@/const'
import { Navigation, LoginButton, CartButton } from '@/components/navigation'

import { HeaderContext } from './HeaderContext'
import {
  StyledHeader,
  StyledHeaderInner,
  StyledLogoLink,
  StyledLogo,
  NavButton,
  NavButtonIcon,
  StyledOverlay
} from './Header.style'

export const Header = ({ navList }: IHeaderProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const scrollDirection = useScrollDirection()
  const isLG = useIsLG()

  const handleToggleClick = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive])

  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP) {
      setIsFixed(true)
    } else if (scrollDirection === ScrollDirection.DOWN) {
      setIsFixed(false)
    }
  }, [scrollDirection])

  const showPreviewTokenMessage = SBPreview.isPreviewAndHasNoPreviewToken()

  return (
    <>
      {showPreviewTokenMessage && <HeaderPreviewTokenMessage />}
      <StyledOverlay isActive={isActive} onClick={handleToggleClick} />

      <StyledHeader
        isFixed={isFixed || isActive}
        {...{ showPreviewTokenMessage }}
      >
        <LayoutContainer>
          <StyledHeaderInner isFixed={isFixed} isActive={isActive}>
            <HeaderContext.Provider
              value={{
                isActive,
                setIsActive
              }}
            >
              <NavButton
                aria-label="Open navigation"
                onClick={handleToggleClick}
                isActive={isActive}
              >
                <NavButtonIcon
                  hasCircle
                  name={isActive ? IconNames.CROSS : IconNames.MENU}
                  isActive={isActive || isFixed}
                />
              </NavButton>
              <StyledLogoLink link={{ href: SITE_ROOT }} aria-label="Home">
                <StyledLogo
                  name={IconNames.LOGO}
                  isActive={isActive || isFixed}
                />
              </StyledLogoLink>
              <Navigation
                items={navList}
                isActive={isActive}
                isFixed={isFixed}
              />
              {isLG && <LanguageSwitchHeader isFixed={isFixed} />}
              <CartButton />
              <LoginButton isFixed={isFixed} isActive={isActive} />
            </HeaderContext.Provider>
          </StyledHeaderInner>
        </LayoutContainer>
      </StyledHeader>
    </>
  )
}
