import { DateTime } from 'luxon'
import { isoToDateTime } from '@hc/shared/utils/date'

import { Cart, Checkout, ValidatedCart } from './cart.api.models'
import {
  CartResponse,
  CartItemResponse,
  CheckoutResponse,
  ValidateCartResponse,
  ValidatedCartItemResponse,
  CartValidationError
} from './cart.api.types'

const mapCartItem = (cartItem: CartItemResponse) => {
  return {
    productId: parseInt(cartItem.ProductId),
    quantity: cartItem.Quantity,
    price: cartItem.Price,
    vendorId: cartItem.VendorId,
    description: cartItem.Description,
    startDate: isoToDateTime(cartItem.StartDate),
    startTime: isoToDateTime(cartItem.StartTime),
    venue: cartItem.Venue
  }
}

const mapCartItems = (items: CartItemResponse[]) => {
  let totalPrice = 0

  const cartItems = items.map(cartItem => {
    totalPrice += cartItem.Price
    return mapCartItem(cartItem)
  })

  return {
    items: cartItems,
    totalPrice
  }
}

export const mapValidatedItemError = (errors: CartValidationError[]) => {
  return errors.map(error => ({
    code: error.Code,
    message: error.Message
  }))
}

export const mapValidatedCartItems = (items: ValidatedCartItemResponse[]) => {
  const cartItems = items.map(cartItem => {
    return {
      ...mapCartItem(cartItem),
      errors: cartItem.Errors ? mapValidatedItemError(cartItem.Errors) : []
    }
  })

  return {
    items: cartItems
  }
}

export const mapCart = (response: CartResponse): Cart => {
  const cart = response.Cart
  const cartItems = mapCartItems(cart.Items)

  return {
    cartId: cart.CartId,
    items: cartItems.items,
    totalPrice: cartItems.totalPrice,
    expire: DateTime.fromSeconds(cart.TimeToLive).toJSON()
  }
}

export const mapCheckout = (checkoutResponse: CheckoutResponse): Checkout => {
  const payment = checkoutResponse.Payment

  return {
    redirectUrls: {
      success: payment.RedirectURLs.Success,
      error: payment.RedirectURLs.Error,
      back: payment.RedirectURLs.Back,
      pending: payment.RedirectURLs.Pending
    },
    cartId: payment.CartId,
    orderId: payment.OrderId,
    paymentNeeded: payment.Needed,
    paymentUrl: payment.URL
  }
}

export const mapValidateCart = (
  response: ValidateCartResponse
): ValidatedCart => {
  const cart = response.Cart
  const cartItems = mapValidatedCartItems(cart.Items)

  return {
    cartId: cart.CartId,
    items: cartItems.items,
    totalPrice: cart.TotalPrice,
    expire: DateTime.fromSeconds(cart.TimeToLive).toJSON(),
    valid: cart.Valid
  }
}
