import { DateTime } from 'luxon'

export const getDaysInMonth = (year: number, month: number) => {
  return DateTime.local(year, month).daysInMonth
}

export const getYearDateRange = () => {
  const minYear = 1907
  const maxYear = getMinimumBirthdate() + 1

  return Array.from(
    { length: maxYear - minYear },
    (el, index) => minYear + index
  ).sort((a, b) => b - a)
}

export const getMinimumBirthdate = () => {
  return parseInt(DateTime.now().minus({ years: 18 }).toFormat('y'))
}

export const setFullDateFromFormFields = (
  year: string,
  month: string,
  day: string
) => {
  day = day.length < 2 ? '0' + day : day
  month = month.length < 2 ? '0' + month : month
  return `${year}-${month}-${day}`
}
