import { ROUTES } from '@/const/routes'

export const myhcNavItems = [
  {
    title: 'home',
    url: ROUTES.MYHC.HOME
  },
  {
    title: 'personalDetails',
    url: ROUTES.MYHC.PERSONAL_DETAILS
  },
  {
    title: 'myPreferences',
    url: ROUTES.MYHC.PREFERENCES
  },
  {
    title: 'changePassword',
    url: ROUTES.MYHC.CHANGE_PASSWORD
  },
  {
    title: 'myPoker',
    url: ROUTES.MYHC.MY_POKER
  },
  {
    title: 'myReservations',
    url: ROUTES.MYHC.MY_RESERVATIONS
  }
]
