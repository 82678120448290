import styled from 'styled-components'

import { Icon } from '../icon'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    padding-bottom: 6.4rem;
    margin-bottom: 6.4rem;
    flex-direction: row;
  }

  &:last-child {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }
`

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
  color: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
  height: 4rem;
  margin-bottom: 1.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    margin-top: -1.2rem;
  }
`

export const StyledBody = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    padding-left: 8.5rem;
    padding-right: 11.5rem;
  }
`
