import axios, { AxiosInstance } from 'axios'

import {
  CDDNGetAddressDetailsResponse,
  CDDNGetAddressDetailsSchema,
  CDDNGetAddressDetailsValidationError
} from './cddn.api.types'

class HCCDDNApi {
  client: AxiosInstance

  constructor() {
    this.client = axios.create({
      baseURL: `${process.env.GATSBY_CDDN_API_GATEWAY_URL}`
    })
  }

  async getAddressDetails(
    postalCode: string,
    houseNumber: string
  ): Promise<CDDNGetAddressDetailsResponse> {
    const { data } = await this.client.get<CDDNGetAddressDetailsResponse>(
      `addresses/${postalCode}/${houseNumber}`
    )

    const parsed = CDDNGetAddressDetailsSchema.safeParse(data)
    if (!parsed.success) {
      throw new CDDNGetAddressDetailsValidationError(
        `Invalid response retrieved ${parsed.error.toString()}`
      )
    }

    return parsed.data
  }
}

export const CDDNApi = new HCCDDNApi()
