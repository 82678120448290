import styled, { css, keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { HeadingTypes, ParagraphTypes } from '@hc/shared/types/typography'
import { Icon } from '@hc/shared/components/icon'

interface PropsDropdown {
  isFixed?: boolean
}

interface PropsDropdownItem extends PropsDropdown {
  activeItem?: boolean
}

const KeyframesFadeIn = keyframes`
from {
    opacity: 0;
}
to {
    opacity: 1;
}
`

export const StyledDropdown = styled.ul<PropsDropdown>`
  animation: ${KeyframesFadeIn} 0.3s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  top: calc(100% + 3rem);
  min-width: 16.5rem;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;

  ${({ isFixed }) =>
    !isFixed &&
    css`
      top: calc(100% + 1rem);
    `}
`

export const StyledDropdownLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.COLOR.BLUE_10};
  padding: 1.2rem 2.4rem;

  &:hover {
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_20};
  }
`

export const StyledDropdownItem = styled.li<PropsDropdownItem>`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  color: ${({ theme }) => theme.COLOR.BLUE_90};

  ${({ activeItem }) =>
    activeItem &&
    css`
      padding: 1.2rem 2.4rem;

      &:last-child {
        padding-bottom: 1.8rem;
      }
      &:first-child {
        padding-top: 1.8rem;
      }
    `}

  &:last-child {
    ${StyledDropdownLink} {
      border-radius: 0 0 ${({ theme }) => theme.BORDER_RADIUS.S}rem
        ${({ theme }) => theme.BORDER_RADIUS.S}rem;
      padding-bottom: 1.6rem;
    }
  }
  &:first-child {
    ${StyledDropdownLink} {
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem
        ${({ theme }) => theme.BORDER_RADIUS.S}rem 0 0;
      padding-top: 1.6rem;
    }
  }
`

export const StyledCheckIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.COLOR.VERIFIED};
  margin-left: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
  padding: 0.2rem;
  margin-top: 0.2rem;
`
export const StyledDropdownActive = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDropdownTrigger = styled.button<PropsDropdown>`
  ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_M]};
  color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  font-weight: 700;
  display: flex;
  align-items: center;

  ${({ isFixed }) =>
    isFixed &&
    css`
      color: ${({ theme }) => theme.COLOR.BLUE_90};
    `}
`

export const StyledChevronIcon = styled(Icon)<PropsDropdown>`
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  margin-left: 1rem;

  ${({ isFixed }) =>
    isFixed &&
    css`
      fill: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
    `}
`

export const StyledLanguageSwitch = styled.div<PropsDropdown>`
  position: relative;
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    height: 3rem;
    background-color: transparent;
    top: 100%;
    width: 16.5rem;
    right: 0;

    ${({ isFixed }) =>
      !isFixed &&
      css`
        height: 1rem;
      `}
  }
`
