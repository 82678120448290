import { AxiosInstance } from 'axios'
import { HttpClient } from '@hc/shared/domains/api/http'

import {
  IMShadowDecomposeJWTResponse,
  IMShadowJWTResponse
} from './imshadow.types'

class HCIMShadowApi {
  client: AxiosInstance

  constructor() {
    this.client = HttpClient.create({
      baseURL: 'v1/IMShadow',
      requestConfig: {
        headers: {
          'X-API-KEY': process.env.GATSBY_IMSHADOW_API_KEY || ''
        },
        withCredentials: true
      }
    })
  }

  async composeJWT(
    username: string,
    temporaryToken: string,
    fields?: number[]
  ): Promise<IMShadowJWTResponse> {
    const { data } = await this.client.post<IMShadowJWTResponse>('ComposeJWT', {
      Username: username,
      TemporaryToken: temporaryToken,
      Fields: fields || [1, 2, 4]
    })

    return data
  }

  async decomposeJWT(): Promise<IMShadowDecomposeJWTResponse> {
    const { data } = await this.client.post<IMShadowDecomposeJWTResponse>(
      'DecomposeJWT',
      null
    )

    return data
  }

  async showAuthorization(): Promise<IMShadowJWTResponse> {
    const { data } = await this.client.get<IMShadowJWTResponse>(
      'ShowAuthorization'
    )

    return data
  }
}

export const IMShadowApi = new HCIMShadowApi()
