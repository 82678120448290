import { HttpClient } from '@hc/shared/domains/api/http'

import { Ijackpot } from './jackpot.types'
import { mapJackpotValuesList } from './jackpot.mappers'
import { JackpotValue } from './jackpot.models'

const client = HttpClient.create({
  baseURL: 'v2/Jackpot/Public/jackpot_values/',
  requestConfig: {
    headers: {
      'X-API-KEY': process.env.GATSBY_JACKPOT_API_KEY || ''
    }
  }
})

export class JackpotApi {
  /* API options:
  jackpot_values/  ( returns only the venue containers )
  jackpot_values/all ( progressive jackpot are added to response under a container with sitecode HK )
  jackpot_values/all?RemoveLDK=true  ( same as /all but now the progressive jackpots are removed from the specific venue containers)
  */
  static async getJackpotValues(): Promise<JackpotValue[]> {
    const { data } = await client.get<Ijackpot>('all/?RemoveLDK=true')
    return mapJackpotValuesList(data)
  }
}
