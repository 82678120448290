import styled, { css } from 'styled-components'

interface ImageProps {
  isCovered?: boolean
  hasBorderRadius?: boolean
}

export const ImageContainer = styled.img<ImageProps>(
  ({ isCovered, hasBorderRadius }) => css`
    display: flex;
    width: 100%;
    height: auto;

    ${isCovered &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    `}

    ${hasBorderRadius &&
    css`
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
    `}
  `
)
