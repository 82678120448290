import { CountryCode } from '@hc/shared/types/countries'

import { IMSCreateAccountErrorCodeResponse } from './ims.api.types'

type GENDER = 'M' | 'F'

export interface IMSAccount {
  firstName: string
  lastName: string
  gender?: GENDER
  initials?: string
  suffix?: string
  dateOfBirth: string // yyyy-mm-dd
  houseNumber?: string
  houseNumberSuffix?: string
  streetName?: string
  postalCode?: string
  city?: string
  countryCode?: CountryCode
  mobileNumber?: string
  emailAddress: string
  password: string
  optin?: boolean
}

export enum IMSCreateAccountErrorCode {
  MISSING_MANDATORY_FIELDS,
  ACCOUNT_ALREADY_EXISTS,
  INVALID_PASSWORD,
  UNKNOWN_ERROR
}

export class IMSCreateAccountError extends Error {
  errorCode: IMSCreateAccountErrorCode

  constructor(message: string, code?: IMSCreateAccountErrorCodeResponse) {
    super(`IMSCreateAccountError: [${code}] - ${message}`)

    switch (code) {
      case IMSCreateAccountErrorCodeResponse.MISSING_MANDATORY_FIELDS:
        this.errorCode = IMSCreateAccountErrorCode.MISSING_MANDATORY_FIELDS
        break
      case IMSCreateAccountErrorCodeResponse.ACCOUNT_ALREADY_EXISTS:
        this.errorCode = IMSCreateAccountErrorCode.ACCOUNT_ALREADY_EXISTS
        break
      // Password message merged into a single code
      case IMSCreateAccountErrorCodeResponse.INVALID_NEW_PASSWORD:
      case IMSCreateAccountErrorCodeResponse.PASSWORD_LENGTH_OUT_OF_RANGE:
        this.errorCode = IMSCreateAccountErrorCode.INVALID_PASSWORD
        break
      default:
        this.errorCode = IMSCreateAccountErrorCode.UNKNOWN_ERROR
        break
    }

    Object.setPrototypeOf(this, IMSCreateAccountError.prototype)
  }
}
