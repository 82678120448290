// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import { Provider } from 'react-redux'
import type { GatsbyBrowser } from 'gatsby'

import { SharedGatsbyBrowser } from '../shared/gatsby-browser'

import { THEME } from './src/theme'
import { AppLayout } from './src/layouts'
import { AuthenticationLayout } from './src/layouts/authentication'
import { MyHcLayout } from './src/layouts/myhc'
import { hcStore } from './src/store/hc.store'
import { ROUTES } from './src/const/routes'

// ROUTES to not redirect back to after logging in
const prevPageIgnoreList = [
  ROUTES.FORGOT_PASSWORD,
  ROUTES.LOGIN,
  ROUTES.REGISTER
]

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = args =>
  SharedGatsbyBrowser.onRouteUpdate(args, prevPageIgnoreList)

enum LayoutTypes {
  LOGIN = 'login',
  MY_HC = 'myhc'
}
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = args => {

  const getLayoutComponent = (layout?: string) => {
    switch (layout) {
      case LayoutTypes.LOGIN:
        return AuthenticationLayout
      case LayoutTypes.MY_HC:
        return MyHcLayout
      default:
        return AppLayout
    }
  }

  return SharedGatsbyBrowser.createWrapPageElement(
    args,
    getLayoutComponent(args.props?.pageContext?.layout as string)
  )
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = args => {
  const rootElement = SharedGatsbyBrowser.createWrapRootElement(args, THEME)
  return <Provider store={hcStore}>{rootElement}</Provider>
}
