module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-fix-fouc@1.0.2_gatsby@5.5.0/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.5.0_jnllck7k3iys5crwyjxfjxejwu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_x5zrgcet7dnxxwftyikh4rnsdi/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","de","en"],"defaultLanguage":"nl","redirect":false,"siteUrl":"https://vestigingen.hollandcasino.nl","trailingSlash":"always","i18nextOptions":{"defaultNS":"translation","lowerCaseLng":true,"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.5.0_ndlqhxleak6xpfuoqxybzi4qj4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
