import { ReactNode } from 'react'

import { ParagraphTypes } from '../../../types/typography'

import { StyledParagraph } from './Paragraph.styles'

export interface IParagraphProps {
  type?: ParagraphTypes
  children?: ReactNode
  align?: 'left' | 'right' | 'center'
}

export const Paragraph: React.FC<IParagraphProps> = ({
  type,
  children,
  align,
  ...props
}) => {
  return (
    <StyledParagraph {...{ type, align, ...props }}>{children}</StyledParagraph>
  )
}
