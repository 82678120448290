import { ParagraphTypes } from '../../types/typography'

import {
  StyledEnablePreviewModeLink,
  StyledPreviewTokenMessage
} from './HeaderPreviewTokenMessage.styles'

export const HeaderPreviewTokenMessage = () => {
  return (
    <StyledPreviewTokenMessage type={ParagraphTypes.TEXT_S}>
      Storyblok preview token missing while in preview mode. Please set it{' '}
      <StyledEnablePreviewModeLink href="/enable-preview-mode">
        here
      </StyledEnablePreviewModeLink>{' '}
      to have a fully functional preview mode.
    </StyledPreviewTokenMessage>
  )
}
