import { useState } from 'react'
import useLanguageSwitch from '@hc/shared/hooks/use-language-switch'
import {
  getSupportedLanguages,
  getFullTranslatedStringFromLocale,
  stringToLanguageUpper
} from '@hc/shared/i18n/locales'
import { IconNames } from '@hc/shared/components/icon'
import { COLOR } from '@hc/shared/theme/basic/color'
import { useHCTranslation } from '@hc/shared/hooks/use-hc-translation'

import {
  StyledLanguageSwitch,
  StyledDropdownTrigger,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownLink,
  StyledDropdownActive,
  StyledCheckIcon,
  StyledChevronIcon
} from './LanguageSwitchHeader.styles'

interface IPropsLanguageSwitch {
  isFixed?: boolean
  className?: string
}

export const LanguageSwitchHeader: React.FC<IPropsLanguageSwitch> = ({
  isFixed,
  className
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { t, i18n } = useHCTranslation()
  const { setLanguage, getLocaleRedirectUrl } = useLanguageSwitch()
  const { language } = i18n

  return (
    <StyledLanguageSwitch
      {...{ className }}
      isFixed={isFixed}
      onMouseOver={() => {
        setIsDropdownOpen(true)
      }}
      onMouseOut={() => {
        setIsDropdownOpen(false)
      }}
    >
      <StyledDropdownTrigger
        isFixed={isFixed}
        aria-label={t('languageSwitch.openLanguageSwitchButton')}
      >
        {stringToLanguageUpper(language)}
        <StyledChevronIcon
          {...{ isFixed }}
          name={IconNames.CARET_DOWN}
        ></StyledChevronIcon>
      </StyledDropdownTrigger>

      {isDropdownOpen && (
        <StyledDropdown isFixed={isFixed}>
          {getSupportedLanguages().map(lang => {
            const fullLanguage = getFullTranslatedStringFromLocale(lang)

            if (fullLanguage) {
              return (
                <StyledDropdownItem
                  activeItem={lang === language}
                  key={`lang-dropdown-${lang}`}
                >
                  {lang !== language && (
                    <StyledDropdownLink
                      to={getLocaleRedirectUrl(lang)}
                      onClick={() => {
                        setLanguage(lang)
                      }}
                    >
                      {t(fullLanguage)}
                    </StyledDropdownLink>
                  )}
                  {lang === language && (
                    <StyledDropdownActive>
                      {t(fullLanguage)}
                      <StyledCheckIcon
                        color={COLOR.NEUTRAL_10}
                        hasCircle={true}
                        name={IconNames.CHECK}
                      />
                    </StyledDropdownActive>
                  )}
                </StyledDropdownItem>
              )
            }
            return null
          })}
        </StyledDropdown>
      )}
    </StyledLanguageSwitch>
  )
}
