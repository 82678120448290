import styled, { css } from 'styled-components'

import { Heading } from '../typography'
import { Icon } from '../icon'
import { ParagraphTypes } from '../../types/typography'

export enum Variant {
  LARGE,
  SMALL
}

interface PropIsOpen {
  isOpen?: boolean
}

interface PropVariant {
  variant: Variant
}

interface PropsFooter {
  isInline?: boolean
}

export const StyledContainer = styled.div<PropIsOpen>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.Z_INDEX.MODAL};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100vh);
  transition: 0.3s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 100vw;
      height: 100vh;
      transform: translateY(0);
    `}
`

export const StyledOverlay = styled.div<PropIsOpen>`
  background-color: ${({ theme }) => theme.COMPONENTS.OVERLAY_COLOR};
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  transition: opacity 0.05s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: opacity 0.2s
        ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT} 0.2s;
      opacity: 0.7;
    `};
`

export const StyledModal = styled.div<PropVariant & PropIsOpen>`
  position: relative;
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.L}rem;
  margin: 0.8rem;
  flex-grow: 1;
  overflow: auto;
  width: calc(100% - 1.6rem);
  transition: transform 0.3s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT} 0.05s;
  transform: translateY(-100vh);

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
    `};

  ${({ variant }) =>
    variant === Variant.SMALL &&
    css`
      flex-grow: 0;
      width: 52rem;
      max-width: calc(100% - 1.6rem);
      text-align: center;
    `}
`

export const StyledHeader = styled.div`
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_20};
`

export const StyledButton = styled.button<PropVariant>`
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;

  ${({ variant }) =>
    variant === Variant.LARGE &&
    css`
      top: 1rem;
    `};
`

export const StyledIcon = styled(Icon)<PropVariant>`
  height: 3.2rem;
  padding: 1rem;

  ${({ variant }) =>
    variant === Variant.SMALL &&
    css`
      fill: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
      color: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
    `};
`

export const StyledBody = styled.div<PropVariant>`
  padding: 2.4rem;
  ${({ variant }) =>
    variant === Variant.SMALL &&
    css`
      ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_M]}
      padding: 4rem;
    `};
`

export const StyledSmallHeading = styled(Heading)`
  margin-bottom: 1.6rem;
`

export const StyledFooter = styled.div<PropsFooter>`
  margin-top: 5.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    ${({ isInline }) =>
      isInline &&
      css`
        gap: 2.4rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
  }
`

export const Footer = StyledFooter
