import { useState } from 'react'
import { navigate } from 'gatsby'
import { Loader } from '@hc/shared/components/loader'
import { Block } from '@hc/shared/components/block'

import { MyHcHeader } from '@/components/myhc-header'
import { useValidateSession } from '@/domains/ims/hooks'

import {
  StyledContentContainer,
  StyledLayout,
  StyledLoaderContainer
} from './myhc.style'

interface ImyHcLayoutProps {
  children?: React.ReactNode
}

export const MyHcLayout = ({ children }: ImyHcLayoutProps) => {
  const [isLoading, setIsLoading] = useState(true)

  useValidateSession({
    onSuccess: () => {
      setIsLoading(false)
    },
    onError: () => {
      navigate('/')
    }
  })

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Block>
          <Loader />
        </Block>
      </StyledLoaderContainer>
    )
  }
  return (
    <StyledLayout>
      <MyHcHeader />
      <StyledContentContainer>{children}</StyledContentContainer>
    </StyledLayout>
  )
}
