import axios, { AxiosInstance } from 'axios'
import { HttpClient } from '@hc/shared/domains/api/http'

import { IMSAccount, IMSCreateAccountError } from './ims.api.models'
import {
  IMSCreateAccountErrorResponse,
  IMSCreateAccountResponse,
  IMSCreateAccountSchema,
  isCreateAccountError
} from './ims.api.types'

class HCIMSApi {
  client: AxiosInstance

  constructor() {
    this.client = HttpClient.create({
      baseURL: 'v1/IMS/Public',
      requestConfig: {
        headers: {
          'X-API-KEY': process.env.GATSBY_IMS_API_KEY || ''
        },
        withCredentials: false
      }
    })
  }

  async createAccount(account: IMSAccount) {
    const accountData = {
      ...account,
      clientType: 'casino', // TODO: Change back to landbasedcasino later, for now T&C won't work with landbasedcasino.
      clientPlatform: 'web'
    }

    const parseStatus = (
      response: IMSCreateAccountResponse | IMSCreateAccountErrorResponse
    ) => {
      if (!isCreateAccountError(response)) {
        return null
      }

      const message = `[Error: ${response.errorCode}, Status: ${response.statusCode}] - ${response.message}`
      throw new IMSCreateAccountError(message, response.errorCode)
    }

    try {
      const { data } = await this.client.post<IMSCreateAccountResponse>(
        'Anonymous/Account/Create',
        accountData
      )

      const parsed = IMSCreateAccountSchema.safeParse(data)
      if (!parsed.success) {
        throw new IMSCreateAccountError(
          `Invalid response retrieved ${parsed.error.toString()}`
        )
      }

      parseStatus(parsed.data)
      return parsed.data
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e.response?.data as IMSCreateAccountErrorResponse
        parseStatus(error)
      } else {
        throw e
      }
    }
  }
}

export const IMSApi = new HCIMSApi()
