import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image'
import { GetGatsbyImageOptions } from 'gatsby-plugin-storyblok-image/dist/typings/module'
import React from 'react'

import { replaceStoryblokCDN } from '../../utils/url'

import { SBImageContainer } from './SBImageContainer.styles'

/**
 * Replace the storyblok domain with our custom domain.
 * Have to modify the GatsbyImageData object since it only whitelists the storyblok domain.
 *
 * @param image GatsbyImageData
 * @returns
 */
export const replaceStoryblokCDNGatsbyImage = (
  image: IGatsbyImageData
): IGatsbyImageData => {
  return {
    ...image,
    images: {
      ...image.images,
      fallback: {
        ...image.images.fallback,
        src: replaceStoryblokCDN(image?.images?.fallback?.src) || '',
        srcSet: replaceStoryblokCDN(image?.images?.fallback?.srcSet)
      }
    },
    placeholder: {
      ...image.placeholder,
      fallback: replaceStoryblokCDN(image?.placeholder?.fallback)
    }
  }
}

export interface IStoryBlokImage {
  alt: string
  copyright: string
  fieldtype: string
  filename: string
  focus?: null
  id: number
  name: string
  title: string
  is_external_url?: boolean
}

interface ISBImageProps {
  image: IStoryBlokImage
  isCovered?: boolean
  hasBorderRadius?: boolean
  options?: GetGatsbyImageOptions
}

const SBImageComponent: React.FC<
  ISBImageProps & React.HTMLAttributes<HTMLDivElement>
> = ({ image, isCovered, hasBorderRadius, className, options = {} }) => {
  if (!image) {
    return null
  }

  // gatsby-plugin-storyblok-image checks if the domain contains storyblok.com
  // so can't replace the domain before passing it to the plugin
  const gatsbyImageData = getGatsbyImage(image.filename, {
    layout: 'constrained',
    fitIn: true,
    ...options
  })

  if (!gatsbyImageData) {
    return null
  }

  const imageData = replaceStoryblokCDNGatsbyImage(gatsbyImageData)

  return (
    <SBImageContainer
      isCovered={isCovered}
      hasBorderRadius={hasBorderRadius}
      className={className}
    >
      <GatsbyImage image={imageData} alt={image.alt} />
    </SBImageContainer>
  )
}

export const SBImage = React.memo(SBImageComponent)
