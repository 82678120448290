import useLanguageSwitch from '../../hooks/use-language-switch'
import {
  getSupportedLanguages,
  stringToLanguageUpper
} from '../../i18n/locales'
import { useHCTranslation } from '../../hooks/use-hc-translation'
import { useMounted } from '../../hooks/use-mounted'

import {
  StyledLangSwitch,
  StyledLangSwitchItem,
  StyledLink
} from './LanguageSwitch.styles'

import { LanguageSwitchVariant } from './'

interface IPropsLanguageSwitch {
  isFixed?: boolean
  variant?: LanguageSwitchVariant
  className?: string
}

const isSolidSeparator = (activeIndex: number, itemIndex: number) => {
  return activeIndex === itemIndex || activeIndex - itemIndex === 1
}

export const LanguageSwitch: React.FC<IPropsLanguageSwitch> = ({
  isFixed,
  variant = LanguageSwitchVariant.DEFAULT,
  className
}) => {
  const hasMounted = useMounted()
  const { i18n } = useHCTranslation()
  const { language } = i18n
  const supportedLanguages = getSupportedLanguages()

  const indexActiveLang = supportedLanguages.indexOf(language)
  const { setLanguage, getLocaleRedirectUrl } = useLanguageSwitch()

  // Avoid Hydration issues
  if (!hasMounted) {
    return null
  }

  return (
    <StyledLangSwitch variant={variant} className={className}>
      {supportedLanguages.map((lang: string, index: number) => {
        return (
          <StyledLangSwitchItem
            key={`lang-switch-${lang}`}
            $isFixed={isFixed}
            isSolidSeparator={isSolidSeparator(indexActiveLang, index)}
            variant={variant}
          >
            {language !== lang && (
              <StyledLink
                to={getLocaleRedirectUrl(lang)}
                $isFixed={isFixed}
                onClick={() => {
                  setLanguage(lang)
                }}
              >
                {stringToLanguageUpper(lang)}
              </StyledLink>
            )}
            {language === lang && stringToLanguageUpper(lang)}
          </StyledLangSwitchItem>
        )
      })}
    </StyledLangSwitch>
  )
}
