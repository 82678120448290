import { navigate } from 'gatsby'

import { HCStorage } from '../utils/storage'
import { STORAGE_LANG } from '../const'
import { cleanURLPathSlashes } from '../utils/url'
import { getCurrentLanguagePath, getSupportedLanguages } from '../i18n/locales'

const useLanguageSwitch = () => {
  const setLanguage = (lang: string) => {
    HCStorage.setItem(STORAGE_LANG, lang)
  }

  const getLanguage = (): string | null => {
    return HCStorage.getItem(STORAGE_LANG)
  }

  const getLocaleRedirectUrl = (targetLanguage: string) => {
    // SSR
    if (typeof window === 'undefined') {
      return ''
    }

    const currentUrl = (
      window.location.pathname + window.location.search
    ).replace(/\/(en|de)\//, '')

    const languagePath = getCurrentLanguagePath(targetLanguage)

    return cleanURLPathSlashes(`/${languagePath}/${currentUrl}`)
  }

  const languageRedirect = (currentLang: string) => {
    if (typeof window === 'undefined') {
      return
    }

    const supportedLanguage = getSupportedLanguages()

    const localeMatches = window.location.pathname.match(/\/(en|de)\//)
    if (localeMatches && supportedLanguage.includes(localeMatches[1])) {
      // Update the current language
      setLanguage(localeMatches[1])
      return
    }

    const storedLanguage = getLanguage()
    if (storedLanguage && !supportedLanguage.includes(storedLanguage)) {
      setLanguage('nl')
      return
    }

    if (storedLanguage && currentLang !== storedLanguage) {
      navigate(getLocaleRedirectUrl(storedLanguage))
    }
  }

  return {
    setLanguage,
    getLanguage,
    getLocaleRedirectUrl,
    languageRedirect
  }
}

export default useLanguageSwitch
