/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'

import {
  Cart,
  CartBaseItem,
  CartItem,
  Checkout,
  ValidatedCart
} from '@/domains/api/cart/cart.api.models'
import { CartApi } from '@/domains/api'
import { useSession } from '@/store/selectors'

const QUERY_KEY_CART = 'cart'
const QUERY_KEY_VALIDATE = 'cart-validate'

const useInvalidateQueries = () => {
  const queryClient = useQueryClient()
  const session = useSession()

  return {
    invalidateCart: () => {
      queryClient.invalidateQueries([QUERY_KEY_CART, session.isAuthenticated])
    }
  }
}

export const useGetCartItem = (
  itemId: number,
  options?: Omit<UseQueryOptions<CartItem | undefined>, 'queryKey' | 'queryFn'>
) => {
  const query = useGetCart<CartItem | undefined>(
    cart => cart.items.find(cartItem => cartItem.productId === itemId),
    options
  )

  return {
    ...query
  }
}

export const useGetCart = <T = Cart>(
  select?: (data: Cart) => T,
  options?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>
) => {
  const session = useSession()

  return useQuery<T, Error>({
    queryKey: [QUERY_KEY_CART, session.isAuthenticated],
    queryFn: () => CartApi.getCart(session.isAuthenticated),
    select,
    // @TODO: Lets test if this make sense, currently manually invalidating the cache so should be fine
    staleTime: Infinity,
    enabled: session.sessionValidated,
    ...(options as any)
  })
}

export const useAddToCart = (
  options?: UseMutationOptions<Cart, Error, CartItem>
) => {
  const session = useSession()
  const { invalidateCart } = useInvalidateQueries()

  return useMutation(
    (cartItem: CartItem) =>
      CartApi.addToCart([cartItem], session.isAuthenticated),
    {
      ...(options as any),
      onSuccess: (data, variables, context) => {
        invalidateCart()

        options?.onSuccess?.(data, variables, context)
      }
    }
  )
}

export const useRemoveFromCart = (
  options?: UseMutationOptions<Cart, Error, CartBaseItem>
) => {
  const { invalidateCart } = useInvalidateQueries()

  return useMutation(
    (cartItem: CartBaseItem) => CartApi.removeFromCart([cartItem]),
    {
      ...(options as any),
      onSuccess: (data, variables, context) => {
        invalidateCart()
        options?.onSuccess?.(data, variables, context)
      }
    }
  )
}

export const useCheckout = (options?: UseMutationOptions<Checkout, Error>) => {
  const { invalidateCart } = useInvalidateQueries()

  return useMutation(() => CartApi.checkout(), {
    ...(options as any),
    onSuccess: (data, variables, context) => {
      invalidateCart()
      options?.onSuccess?.(data, variables, context)
    }
  })
}

export const useDeleteCart = (options?: UseMutationOptions<Cart, Error>) => {
  const session = useSession()
  const { invalidateCart } = useInvalidateQueries()

  return useMutation(() => CartApi.deleteCart(session.isAuthenticated), {
    ...(options as any),
    onSuccess: (data, variables, context) => {
      invalidateCart()
      options?.onSuccess?.(data, variables, context)
    }
  })
}

export const useValidateCart = (options?: UseQueryOptions<Cart, Error>) => {
  const session = useSession()

  return useQuery<ValidatedCart, Error>({
    queryKey: [QUERY_KEY_VALIDATE, session.isAuthenticated],
    queryFn: () => {
      return CartApi.validate(session.isAuthenticated)
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    enabled: session.sessionValidated,
    ...(options as any)
  })
}
