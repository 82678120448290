import { HeadingTypes, ParagraphTypes } from '../../types/typography'

import {
  StyledMenuItem,
  StyledHeading,
  StyledParagraph,
  StyledPrice
} from './RestaurantMenuItem.styles'

export interface IRestaurantMenuItemProps {
  _uid: string
  title: string
  description: string
  price: number
}

export const RestaurantMenuItem: React.FC<IRestaurantMenuItemProps> = ({
  title,
  description,
  price
}) => {
  return (
    <StyledMenuItem>
      <div>
        {title && (
          <StyledHeading type={HeadingTypes.DISPLAY_XXS}>{title}</StyledHeading>
        )}
        {description && (
          <StyledParagraph type={ParagraphTypes.TEXT_XS}>
            {description}
          </StyledParagraph>
        )}
      </div>
      {price && (
        <StyledPrice type={HeadingTypes.DISPLAY_XXS}>
          &euro; {price}
        </StyledPrice>
      )}
    </StyledMenuItem>
  )
}
