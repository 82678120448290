import { FC } from 'react'
import { IStoryBlokImage, SBImage } from '@hc/shared/components/sb-image'

interface LoginHeroProps {
  image: IStoryBlokImage
}

export const LoginHero: FC<LoginHeroProps> = ({ image }) => {
  return <SBImage image={image} isCovered />
}
