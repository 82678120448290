import axios, { AxiosInstance } from 'axios'
import { getFullSiteUrl } from '@hc/shared/utils/url'
import { ConsoleLogger } from '@hc/shared/utils/logger'
import { HttpClient } from '@hc/shared/domains/api/http'

import { ROUTES } from '@/const/routes'

import { mapCart, mapCheckout, mapValidateCart } from './cart.api.mappers'
import { Cart, CartBaseItem, CartError, CartItem } from './cart.api.models'
import {
  CartGenericError,
  CartResponse,
  CheckoutResponse,
  ValidateCartResponse
} from './cart.api.types'
import { AnonymousCartStorage } from './cart.storage'

const logger = new ConsoleLogger({
  prefix: '[CART API]',
  prefixColor: '#f57e42'
})

class HCCartApi {
  client: AxiosInstance
  cartStorage: AnonymousCartStorage
  cartId: string | null = null

  constructor() {
    // @TODO: Generic 401 handling.
    this.client = HttpClient.create({
      baseURL: 'v1/Cart',
      requestConfig: {
        headers: {
          'X-API-KEY': process.env.GATSBY_CART_API_KEY || ''
        }
      }
    })

    this.cartStorage = new AnonymousCartStorage()
    this.cartId = this.cartStorage.cartData.cartId
    logger.log('CartAPI setup', this.cartId)
  }

  getAnonymousCartId() {
    return this.cartStorage.cartData.cartId
  }

  getCartId() {
    return this.cartId
  }

  updateCartId(cartId: string, authenticated = false) {
    this.cartId = cartId

    if (!authenticated) {
      // Only need to update anonymous carts
      this.cartStorage.updateCart({
        cartId
      })
    }
  }

  /**
   * Get a cart
   *
   * @param cartId string, the cartId to get
   * @param authenticated Boolean, user logged in or not
   * @returns Promise<Cart>
   */
  async getCart(authenticated = false): Promise<Cart> {
    const anonymousCartId = this.getAnonymousCartId()
    if (authenticated && anonymousCartId) {
      // Already have an anonymous cart, merge
      try {
        await this.mergeCart(anonymousCartId)
      } catch (e) {
        // Ignore exceptions
        logger.error('getCart error, merge cart failed!', e)
      }
    } else if (!authenticated && !this.getAnonymousCartId()) {
      // No cart yet, so create one. Only applies to anonymous carts.
      // Authenticated cart is created automatically
      await this.createCart(authenticated)
    }

    const getCartUrl = authenticated
      ? `Authenticated/Get`
      : `Anonymous/Get/${this.getCartId()}`

    try {
      const { data } = await this.client.get<CartResponse>(getCartUrl, {
        withCredentials: authenticated
      })

      this.updateCartId(data.Cart.CartId, authenticated)

      logger.log('getCart, success', data)
      return mapCart(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (this.getCartId()) {
          logger.error('getCart error with cartId', e)

          // Invalid cart, so delete the local cart id and retry the request
          const error = (e.response?.data as CartGenericError).Error

          if (error.toLowerCase().includes('cart not found')) {
            this.cartId = null
            this.cartStorage.deleteCart()

            return this.getCart(authenticated)
          }
        }
      }

      logger.error('getCart error without cartId', e)
      throw e
    }
  }

  /**
   * Clear a cart
   *
   * @returns Promise<Cart>
   */
  async clearCart(): Promise<Cart> {
    const { data } = await this.client.post<CartResponse>(
      `Anonymous/Clear/${this.getCartId()}`
    )

    logger.log('clearCart, cleared cart', this.getCartId())

    return mapCart(data)
  }

  /**
   * Delete a cart
   *
   * @param cartId string, the cartId to get
   * @param authenticated Boolean, user logged in or not
   * @returns Promise<Cart>
   */
  async deleteCart(authenticated = false): Promise<Cart> {
    if (!authenticated && !this.getCartId()) {
      logger.error('deleteCart, failed to delete cart, no cartId')
      throw new CartError('DeleteCart - No cartId')
    }

    const deleteCartUrl = authenticated
      ? 'Authenticated/Delete'
      : `Anonymous/Delete/${this.getCartId()}`

    const { data } = await this.client.post<CartResponse>(
      deleteCartUrl,
      undefined,
      {
        withCredentials: authenticated
      }
    )

    // Delete unauthenticated cart
    this.cartStorage.deleteCart()

    logger.log('deleteCart, deleted cart', this.getCartId())

    return mapCart(data)
  }

  /**
   * Create a cart
   *
   * @param authenticated Boolean, user logged in or not
   * @returns Promise<Cart>
   */
  async createCart(authenticated = false): Promise<Cart> {
    const createCartUrl = authenticated
      ? 'Authenticated/Create'
      : 'Anonymous/Create'

    const { data } = await this.client.post<CartResponse>(createCartUrl, null, {
      withCredentials: authenticated
    })

    const mappedData = mapCart(data)

    this.updateCartId(mappedData.cartId, authenticated)
    logger.log('createCart, cart created!', mappedData.cartId)

    return mappedData
  }

  /**
   * Add items to a cart
   *
   * @param cartData
   * @returns Cart
   */
  async addToCart(cartItems: CartItem[], authenticated = false): Promise<Cart> {
    if (!this.getCartId()) {
      await this.getCart(authenticated)
    }

    const { data } = await this.client.post<CartResponse>(
      `Anonymous/Modify/${this.getCartId()}`,
      {
        Cart: {
          CartId: this.getCartId(),
          Items: cartItems.map(cartItem => ({
            VendorId: cartItem.vendorId,
            ProductId: cartItem.productId.toString(),
            Quantity: cartItem.quantity,
            Price: cartItem.price,
            Description: cartItem.description,
            StartDate: cartItem.startDate.toISODate(), // 2022-10-21
            StartTime: cartItem.startTime.toFormat('TT'), // 23:00:00
            Venue: cartItem.venue
          }))
        }
      }
    )

    logger.log('addToCart, added items to the cart', cartItems)

    return mapCart(data)
  }

  /**
   * Remove an item from the cart
   *
   * @param items CartBaseItem[] items to remove
   * @returns Cart
   */
  async removeFromCart(items: CartBaseItem[]): Promise<Cart> {
    if (!this.getCartId()) {
      await this.getCart()
    }

    const { data } = await this.client.post<CartResponse>(
      `Anonymous/Modify/${this.getCartId()}`,
      {
        Cart: {
          CartId: this.getCartId(),
          Items: items.map(item => ({
            ProductId: item.productId.toString(),
            VendorId: item.vendorId,
            Quantity: 0
          }))
        }
      }
    )

    logger.log('removeFromCart, removed items fromt the cart', items)

    return mapCart(data)
  }

  /**
   * Merge the cart in the session with an anonymous cart
   *
   * @param cartId The anonymous cart to merge with
   * @returns Cart
   */
  async mergeCart(cartId: string): Promise<Cart | null> {
    const { data, status } = await this.client.post<CartResponse>(
      `Authenticated/Merge/${cartId}`,
      undefined,
      {
        withCredentials: true
      }
    )

    if (status >= 500) {
      this.cartStorage.deleteCart()
      logger.error('mergeCart failed', data, status)

      throw new Error('Failed to merge cart')
    }

    const mappedData = mapCart(data)

    logger.log('mergeCart, merged carts', cartId, mappedData.cartId)

    // Delete anonymous cart
    this.cartStorage.deleteCart()

    return mappedData
  }

  /**
   * Checkout flow
   *
   * @returns CheckoutResponse
   */
  async checkout() {
    const { data } = await this.client.post<CheckoutResponse>(
      'Authenticated/Checkout',
      {
        RedirectURLs: {
          Pending: getFullSiteUrl(`${ROUTES.SHOP.THANKYOU}?status=pending`),
          Success: getFullSiteUrl(`${ROUTES.SHOP.THANKYOU}?status=success`),
          Error: getFullSiteUrl(`${ROUTES.SHOP.THANKYOU}?status=error`),
          Back: getFullSiteUrl(ROUTES.SHOP.CART)
        }
      },
      {
        withCredentials: true
      }
    )

    logger.log('checkout', data)

    return mapCheckout(data)
  }

  /**
   * Validate cart
   *
   * @returns ValidateCartResponse
   */
  async validate(authenticated = false) {
    const validateCartUrl = authenticated
      ? 'Authenticated/Validate'
      : 'Anonymous/Validate'

    const { data } = await this.client.get<ValidateCartResponse>(
      validateCartUrl,
      {
        withCredentials: authenticated
      }
    )

    logger.log('validate', data)

    return mapValidateCart(data)
  }

  reset() {
    this.cartId = null
    this.cartStorage.deleteCart()
  }
}

export const CartApi = new HCCartApi()
