import { StyledContainer, StyledContainerInner } from './ContainerLayout.styles'

interface ContainerLayoutProps {
  children?: React.ReactNode
}

export const ContainerLayout: React.FC<ContainerLayoutProps> = ({
  children
}) => {
  return (
    <StyledContainer>
      <StyledContainerInner>{children}</StyledContainerInner>
    </StyledContainer>
  )
}
