import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next'

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMounted } from '../../hooks/use-mounted'
import { HrefLink } from '../../utils/types'

export interface LinkProps {
  link: HrefLink | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const Link: React.FC<LinkProps> = ({ link, ...props }) => {
  const hasMounted = useMounted()

  // Avoid Hydration issues
  if (!hasMounted) {
    return null
  }

  const { isExternal = false, openInNewWindow, href = '' } = link || {}
  const target = openInNewWindow ? '_blank' : ''

  // Filter out some keys that aren't valid props
  const {
    isActiveIndex,
    isActive,
    isFixed,
    isSquare,
    isSmall,
    isReversed,
    isLarge,
    isStretched,
    isAlternative,
    fullWidthMobile,
    ...filteredProps
  } = props

  // Filter out /en/,/de/ to avoid duplicate languagecodes since gatsby-i18n-next handles it
  const url = href?.replace(/^(\/(en|de)\/)/, '/')

  return openInNewWindow || isExternal ? (
    <a href={url} target={target} rel="noreferrer" {...filteredProps} />
  ) : (
    <GatsbyLink {...filteredProps} to={url} />
  )
}
