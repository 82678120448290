import { CornerPosition, CornerVariant } from './Corner.types'
import { StyledCorner } from './Corner.style'

interface ICornerProps {
  className?: string
  variant?: CornerVariant
  position?: CornerPosition
  hasTransparency?: boolean
  isSmall?: boolean
}

export const Corner: React.FC<ICornerProps> = ({
  variant = 'primary',
  position = 'left-top',
  isSmall,
  hasTransparency,
  className
}) => {
  return (
    <StyledCorner
      {...{ className, variant, position, isSmall, hasTransparency }}
    />
  )
}
