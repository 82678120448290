import styled from 'styled-components'

export const StyledContainer = styled.div`
  color: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const StyledImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 0.6rem;
`
