import styled from 'styled-components'
import { Link } from '@hc/shared/components/link'
import { Icon } from '@hc/shared/components/icon'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    flex-direction: row;
    max-height: 100vh;
  }
`

export const StyledSideHeroWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 12rem;
  background-color: ${({ theme }) => theme.COLOR.PURPLE_60_PRIMARY};
  position: relative;

  @media screen and (min-width: ${({ theme }) =>
    theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
  }
}
`

export const StyledLogoLink = styled(Link)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 2rem));

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    left: 4.8rem;
    top: 4.5rem;
    transform: none;
  }
`

export const StyledLogo = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 3.2rem;
`

export const StyledHeroImg = styled.div`
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    height: 4rem;
    width: 100%;
    background-color: white;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS.L}rem
      ${({ theme }) => theme.BORDER_RADIUS.L}rem 0 0;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    &:after {
      display: none;
    }
  }
`

export const StyledContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  padding: 0.8rem 3.2rem 4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px) {
    padding: 0 4rem;
    width: 60%;
    height: 100vh;
    overflow: auto;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding: 0 14rem;
  }
`
