import styled, { css } from 'styled-components'

export const StyledContainer = styled.figure``

interface Props {
  variant: string
}
export const StyledBlockQuote = styled.blockquote<Props>`
  ${({ theme }) => theme.TYPOGRAPHY.TEXT_XXL};
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 1;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: 4rem;
  }

  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      color: ${theme.COMPONENTS.BLOCKQUOTE_COLOR_PRIMARY};
    `};

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      color: ${theme.COMPONENTS.BLOCKQUOTE_COLOR_SECONDARY};
    `};

  ${({ variant, theme }) =>
    variant === 'tertiary' &&
    css`
      color: ${theme.COMPONENTS.BLOCKQUOTE_COLOR_TERTIARY};
    `};
`
export const StyledAuthor = styled.figcaption`
  ${({ theme }) => theme.TYPOGRAPHY.TEXT_XS};
  margin-top: 0.8rem;
`
