import { HCStorage } from '@hc/shared/utils/storage'

const STORAGE_KEY_CART = 'cart'

interface CartStorageData {
  cartId: string | null
}

const EMPTY_CART = { cartId: null }

export class AnonymousCartStorage {
  cartData: CartStorageData = EMPTY_CART

  constructor() {
    const cartData = this.getCartFromStorage()

    if (cartData) {
      this.cartData = cartData
    }
  }

  /**
   * Store some cart data in localstorage
   * @returns
   */
  private getCartFromStorage() {
    return HCStorage.getItem<CartStorageData>(STORAGE_KEY_CART)
  }

  /**
   * Store specific cart data in localstorage
   *
   * @param cartData
   */
  public updateCart(cart: CartStorageData) {
    this.cartData = cart
    this._persistCart()
  }

  /**
   * Persist the cart
   */
  private _persistCart() {
    HCStorage.setItem(STORAGE_KEY_CART, this.cartData)
  }

  /**
   * Delete the locally stored cart
   */
  deleteCart() {
    HCStorage.removeItem(STORAGE_KEY_CART)
    this.cartData = EMPTY_CART
  }
}
