import { ConsoleLogger } from '@hc/shared/utils/logger'

import { REAL_MODE } from './constants'
import {
  PASLoginResponse,
  PASError,
  PASLoggedInPlayerResponse,
  PASLoginAndGetTempTokenResponse,
  PASLogoutResponse,
  PASRealMode,
  PASRequestTemporaryTokenResponse,
  PASTermsAndConditionsError
} from './pas.types'

const pasLogger = new ConsoleLogger({
  prefix: '[PAS]',
  prefixColor: '#43A047'
})

/**
 * Login the user with an username & password.
 *
 * @param username
 * @param password
 */
const loginAndGetTempToken = (username: string, password: string) => {
  return new Promise<PASLoginAndGetTempTokenResponse>((resolve, reject) => {
    iapiSetCallout<PASLoginAndGetTempTokenResponse>(
      iapiCALLOUT_LOGINANDGETTEMPTOKEN,
      response => {
        if (response.errorCode > 0) {
          pasLogger.error('loginAndGetTempToken error', response)

          return reject(
            new PASError(response.errorCode, `loginAndGetTempToken error`)
          )
        }

        pasLogger.log('loginAndGetTempToken response', response)

        if (response.sessionToken && response.sessionToken.sessionToken) {
          pasLogger.log('loginAndGetTempToken valid sessionToken')
          return resolve(response)
        } else {
          const { sessionValidationData } = response
          if (sessionValidationData) {
            const tcVersionData =
              sessionValidationData.SessionValidationByTCVersionData
            if (tcVersionData && tcVersionData.length) {
              pasLogger.log(
                'loginAndGetTempToken retrieved tcVersionData',
                tcVersionData
              )

              return reject(
                new PASTermsAndConditionsError(
                  tcVersionData[0],
                  'Accept Terms and Conditions'
                )
              )
            }
          }
        }
      }
    )

    iapiLoginAndGetTempToken(username, password, 1, 'nl')
  })
}

/**
 * Get logged in player
 */
const getLoggedinPlayer = (realMode: PASRealMode) => {
  return new Promise<boolean>((resolve, reject) => {
    iapiSetCallout<PASLoggedInPlayerResponse>(
      iapiCALLOUT_GETLOGGEDINPLAYER,
      response => {
        if (response.cookieExists === '0') {
          pasLogger.warn('getLoggedinPlayer no cookie', response)

          return reject(
            new PASError(response.errorCode, `getLoggedinPlayer no cookie`)
          )
        }

        pasLogger.log('getLoggedinPlayer response', response)
        return resolve(true)
      }
    )

    iapiGetLoggedInPlayer(realMode)
  })
}

/**
 * Retrieve temporary token, which can be exchanged for a JWT
 *
 * @param realMode
 */
const requestTemporaryToken = (realMode: PASRealMode) => {
  return new Promise<PASRequestTemporaryTokenResponse>((resolve, reject) => {
    iapiSetCallout<PASRequestTemporaryTokenResponse>(
      iapiCALLOUT_TEMPORARYTOKEN,
      response => {
        if (response.errorCode > 0) {
          pasLogger.warn('requestTemporaryToken error', response)

          return reject(
            new PASError(response.errorCode, `requestTemporaryToken error`)
          )
        }

        pasLogger.log('requestTemporaryToken success', response)

        return resolve(response)
      }
    )

    // @TODO: <PASLoginErrorResponse> ?
    iapiRequestTemporaryToken(realMode)
  })
}

/**
 * Logout
 */
const logout = () => {
  return new Promise<PASLogoutResponse>(resolve => {
    iapiSetCallout<PASLogoutResponse>(iapiCALLOUT_LOGOUT, response => {
      pasLogger.log('logout', response)
      return resolve(response)
    })

    iapiLogout(1, REAL_MODE)
  })
}

/**
 * Forgot password. Email and birthdate should match in the backend.
 *
 * @param email The email address from the user
 * @param birthDate Format: yyyy-mm-dd
 */
const forgotPassword = (email: string, birthDate: string) => {
  return new Promise<PASLogoutResponse>((resolve, reject) => {
    iapiSetCallout<PASLogoutResponse>(iapiCALLOUT_FORGOTPASSWORD, response => {
      if (response.errorCode > 0) {
        pasLogger.error('forgotPassword error', response)
        return reject(new PASError(response.errorCode, `forgotPassword error`))
      }

      pasLogger.log('forgotPassword', response)
      return resolve(response)
    })

    iapiForgotPassword(
      '',
      email,
      birthDate,
      REAL_MODE,
      null,
      // @TODO: This URL should be confiable and picked up when implementing the "change pw" page.
      // Now only this old URL is whitelisted.
      'https://cdn-stg-prev.hollandcasino.nl/#requestId=1662725182908x341912',
      null
    )
  })
}

const acceptTC = (tcVersion: string) => {
  return new Promise<PASLoginResponse>((resolve, reject) => {
    iapiSetCallout<PASLoginResponse>(
      iapiCALLOUT_SESSIONVALIDATION,
      response => {
        if (response.errorCode > 0) {
          pasLogger.error('acceptTC error', response)
          return reject(new PASError(response.errorCode, `acceptTC error`))
        }

        pasLogger.log('validateTC', response)
        return resolve(response)
      }
    )

    iapiValidateTCVersion(tcVersion, 1, REAL_MODE)
  })
}

const loginWithCrypoToken = (token: string) => {
  return new Promise<PASLoginResponse>((resolve, reject) => {
    iapiSetCallout<PASLoginResponse>(iapiCALLOUT_LOGIN, response => {
      if (response.errorCode > 0) {
        pasLogger.error('loginWithCrypoToken error', response)
        return reject(
          new PASError(response.errorCode, `loginWithCrypoToken error`)
        )
      }

      pasLogger.log('loginWithCrypoToken', response)
      return resolve(response)
    })

    iapiLoginCryptoToken(token, 'nl')
  })
}

const validatePasswordChange = (oldPassword: string, newPassword: string) => {
  return new Promise<PASLoginResponse>((resolve, reject) => {
    iapiSetCallout<PASLoginResponse>(
      iapiCALLOUT_SESSIONVALIDATION,
      response => {
        if (response.errorCode > 0) {
          pasLogger.error(
            'validatePasswordChange session validation error',
            response
          )
          return reject(
            new PASError(
              response.errorCode,
              `validatePasswordChange session validation error`
            )
          )
        }

        // @TODO: Trigger login session or something?

        pasLogger.log('validatePasswordChange session validation', response)
        return resolve(response)
      }
    )

    iapiValidatePasswordChange(oldPassword, newPassword, 1, 1)
  })
}

export const PASApi = {
  logout,
  loginAndGetTempToken,
  requestTemporaryToken,
  loginWithCrypoToken,
  getLoggedinPlayer,
  forgotPassword,
  validatePasswordChange,
  acceptTC
}
