import {
  StyledContainer,
  StyledBlockQuote,
  StyledAuthor
} from './Blockquote.style'

interface BlockQuoteProps {
  quote: string
  author?: string
  variant: string
}

export const Blockquote = ({ quote, author, variant }: BlockQuoteProps) => (
  <StyledContainer>
    <StyledBlockQuote {...{ variant }}>{quote}</StyledBlockQuote>
    {author && <StyledAuthor>{author}</StyledAuthor>}
  </StyledContainer>
)
