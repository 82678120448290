import { ElementType, ReactNode, RefObject } from 'react'

import { HrefLink } from '../../../utils/types'
import { HeadingTypes } from '../../../types/typography'

import {
  StyledHeading,
  StyledSpan,
  Alignment,
  BorderType
} from './Heading.styles'

export interface IHeadingProps {
  type?: HeadingTypes
  children?: ReactNode
  as?: ElementType
  ref?: RefObject<HTMLElement>
  link?: HrefLink
  border?: BorderType
  alignment?: Alignment
}

export const defaultAs = {
  [HeadingTypes.DISPLAY_XXL]: 'h1',
  [HeadingTypes.DISPLAY_XL]: 'h2',
  [HeadingTypes.DISPLAY_L]: 'h3',
  [HeadingTypes.DISPLAY_M]: 'h4',
  [HeadingTypes.DISPLAY_S]: 'h5',
  [HeadingTypes.DISPLAY_XS]: 'h6',
  [HeadingTypes.DISPLAY_XXS]: 'h6'
}
export const Heading = ({
  type = HeadingTypes.DISPLAY_XXL,
  ref,
  children,
  border,
  alignment,
  as,
  ...props
}: IHeadingProps) => {
  return (
    <StyledHeading
      ref={ref}
      as={as || defaultAs[type]}
      {...{ type, border, alignment, ...props }}
    >
      {border && <StyledSpan {...{ border, alignment }}>{children}</StyledSpan>}
      {!border && children}
    </StyledHeading>
  )
}
