import axios from 'axios'

import { IMShadowApi } from '@/domains/api'
import { hcStore } from '@/store/hc.store'
import { userLoggedOut } from '@/store/slices/session.slice'

import { PASApi } from './pasApi'
import { IMShadowError } from './auth.types'

/**
 * Login the user
 *
 * @param username
 * @param password
 * @returns
 */
const login = async (username: string, password: string) => {
  const loginResponse = await PASApi.loginAndGetTempToken(username, password)

  const tempTokenResponse = await PASApi.requestTemporaryToken(
    loginResponse.realMode
  )

  try {
    // Retrieve and make sure the JWT cookies get set for authenticated API calls
    return IMShadowApi.composeJWT(
      tempTokenResponse.username,
      tempTokenResponse.sessionToken.sessionToken
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new IMShadowError('Failed to compose JWT token')
    }

    throw e
  }
}

/**
 * Setup session if we still have valid tokens
 *
 * @returns userdata
 */
const getSession = async () => {
  await PASApi.getLoggedinPlayer(1)
  const tempTokenResponse = await PASApi.requestTemporaryToken(1)

  // Retrieve and make sure the JWT cookies get set for authenticated API calls
  return IMShadowApi.composeJWT(
    tempTokenResponse.username,
    tempTokenResponse.sessionToken.sessionToken
  )
}

/**
 * Logout the user from PAS and API Gateway
 */
const logout = async () => {
  await Promise.all([PASApi.logout(), IMShadowApi.decomposeJWT()])
  hcStore.dispatch(userLoggedOut())

  return true
}

export const AuthClient = {
  login,
  logout,
  getSession
}
