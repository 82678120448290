import styled from 'styled-components'

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_30};
`

export const StyledContainerInner = styled.div`
  max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_MAX_CONTAINER_WIDTH}px;
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  margin: 0 auto;
`
