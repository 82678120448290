import axios, { AxiosRequestConfig } from 'axios'

interface HttpConfig {
  baseURL: string
  requestConfig?: AxiosRequestConfig
}

const BASE_API_URL = process.env.GATSBY_BASE_API_GATEWAY_URL

export class HttpClient {
  static create(config: HttpConfig) {
    return axios.create({
      baseURL: `${BASE_API_URL}${config.baseURL}`,
      ...config.requestConfig
    })
  }
}
