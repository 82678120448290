import { useCallback, useContext, useEffect, useState } from 'react'
import { IHeaderNavItem } from '@hc/shared/domains/storyblok/mappers/map-header-nav-item'
import { useIsLG } from '@hc/shared/hooks/use-media-query'
import { Link } from '@hc/shared/components/link'
import { IconNames } from '@hc/shared/components/icon/constants'
import { LanguageSwitch } from '@hc/shared/components/language-switch'

import { HeaderContext } from '@/components/header/HeaderContext'

import {
  StyledContainer,
  StyledNav,
  StyledNavList,
  StyledNavItem,
  StyledNavButton,
  StyledIcon
} from './Navigation.style'
import { NavigationSub } from './NavigationSub'
import { NavigationContext } from './NavigationContext'

export interface IProps {
  items: IHeaderNavItem[] | undefined

  [key: string]: unknown
}

export const Navigation: React.FC<IProps> = ({ items, ...props }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const { isActive, setIsActive } = useContext(HeaderContext)

  const isLG = useIsLG()

  useEffect(() => {
    if (!isActive) {
      setActiveIndex(null)
    }
  })

  return (
    <NavigationContext.Provider
      value={{
        activeIndex,
        setActiveIndex
      }}
    >
      <StyledContainer>
        <StyledNav {...props}>
          <StyledNavList>
            {items?.map((item, idx) => {
              const handleToggleClick = useCallback(() => {
                if (item.items?.length) {
                  setActiveIndex(idx === activeIndex ? null : idx)
                  if (isLG) {
                    setIsActive(idx !== activeIndex)
                  }
                } else {
                  setIsActive(false)
                  setActiveIndex(null)
                }
              }, [activeIndex, isActive, isLG])

              return (
                <StyledNavItem key={item.label}>
                  <StyledNavButton
                    onClick={handleToggleClick}
                    isActiveIndex={activeIndex === idx}
                    as={item.items?.length ? 'button' : Link}
                    link={item.link && item.link}
                    {...props}
                  >
                    {item.label}
                    {item.items?.length !== 0 && (
                      <StyledIcon
                        isCaret
                        hasCircle
                        name={IconNames.CARET_DOWN}
                        isActiveIndex={activeIndex === idx}
                        {...props}
                      />
                    )}
                    <StyledIcon
                      isArrow
                      hasCircle
                      name={IconNames.CARET_RIGHT}
                      {...props}
                    />
                  </StyledNavButton>
                  {item.items?.length !== 0 && (
                    <NavigationSub
                      label={item.label}
                      items={item.items}
                      isActive={activeIndex === idx}
                      onClick={handleToggleClick}
                      cardList={item.cardList}
                    />
                  )}
                </StyledNavItem>
              )
            })}
          </StyledNavList>

          {!isLG && <LanguageSwitch {...props} />}
        </StyledNav>
      </StyledContainer>
    </NavigationContext.Provider>
  )
}
