export const Countries = [
  {
    code: 'AD',
    phoneAreaCode: '+376',
    name: { nl: 'Andorra', en: 'Andorra', de: 'Andorra' }
  },
  {
    code: 'AE',
    phoneAreaCode: '+971',
    name: {
      nl: 'Verenigde Arabische Emiraten',
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate'
    }
  },
  {
    code: 'AF',
    phoneAreaCode: '+93',
    name: { nl: 'Afghanistan', en: 'Afghanistan', de: 'Afghanistan' }
  },
  {
    code: 'AG',
    phoneAreaCode: '+1268',
    name: {
      nl: 'Antigua en Barbuda',
      en: 'Antigua and Barbuda',
      de: 'Antigua und Barbuda'
    }
  },
  {
    code: 'AI',
    phoneAreaCode: '+1264',
    name: { nl: 'Anguilla', en: 'Anguilla', de: 'Anguilla' }
  },
  {
    code: 'AL',
    phoneAreaCode: '+355',
    name: { nl: 'Albanië', en: 'Albania', de: 'Albanien' }
  },
  {
    code: 'AM',
    phoneAreaCode: '+374',
    name: { nl: 'Armenië', en: 'Armenia', de: 'Armenien' }
  },
  {
    code: 'AN',
    phoneAreaCode: '+599',
    name: {
      nl: 'Nederlandse Antillen',
      en: 'Netherlands Antilles',
      de: 'Niederländische Antillen'
    }
  },
  {
    code: 'AO',
    phoneAreaCode: '+244',
    name: { nl: 'Angola', en: 'Angola', de: 'Angola' }
  },
  {
    code: 'AQ',
    phoneAreaCode: '+672',
    name: { nl: 'Antarctica', en: 'Antarctica', de: 'Antarctica' }
  },
  {
    code: 'AR',
    phoneAreaCode: '+54',
    name: { nl: 'Argentinië', en: 'Argentina', de: 'Argentinien' }
  },
  {
    code: 'AS',
    phoneAreaCode: '+1684',
    name: { nl: 'American Samoa', en: 'American Samoa', de: 'American Samoa' }
  },
  {
    code: 'AT',
    phoneAreaCode: '+43',
    name: { nl: 'Oostenrijk', en: 'Austria', de: 'Österreich' }
  },
  {
    code: 'AU',
    phoneAreaCode: '+61',
    name: { nl: 'Australia', en: 'Australia', de: 'Australia' }
  },
  {
    code: 'AW',
    phoneAreaCode: '+297',
    name: { nl: 'Aruba', en: 'Aruba', de: 'Aruba' }
  },
  {
    code: 'AX',
    phoneAreaCode: '+358',
    name: { nl: 'Alandeilanden', en: 'Aland Islands', de: 'Aland-Inseln' }
  },
  {
    code: 'AZ',
    phoneAreaCode: '+994',
    name: { nl: 'Azerbaijan', en: 'Azerbaijan', de: 'Azerbaijan' }
  },
  {
    code: 'BA',
    phoneAreaCode: '+387',
    name: {
      nl: 'Bosnia and Herzegovina',
      en: 'Bosnia and Herzegovina',
      de: 'Bosnia and Herzegovina'
    }
  },
  {
    code: 'BB',
    phoneAreaCode: '+1246',
    name: { nl: 'Barbados', en: 'Barbados', de: 'Barbados' }
  },
  {
    code: 'BD',
    phoneAreaCode: '+880',
    name: { nl: 'Bangladesh', en: 'Bangladesh', de: 'Bangladesh' }
  },
  {
    code: 'BE',
    phoneAreaCode: '+32',
    name: { nl: 'België', en: 'Belgium', de: 'Belgien' }
  },
  {
    code: 'BF',
    phoneAreaCode: '+226',
    name: { nl: 'Burkina Faso', en: 'Burkina Faso', de: 'Burkina Faso' }
  },
  {
    code: 'BG',
    phoneAreaCode: '+359',
    name: { nl: 'Bulgaria', en: 'Bulgaria', de: 'Bulgaria' }
  },
  {
    code: 'BH',
    phoneAreaCode: '+973',
    name: { nl: 'Bahrain', en: 'Bahrain', de: 'Bahrain' }
  },
  {
    code: 'BI',
    phoneAreaCode: '+257',
    name: { nl: 'Burundi', en: 'Burundi', de: 'Burundi' }
  },
  {
    code: 'BJ',
    phoneAreaCode: '+229',
    name: { nl: 'Benin', en: 'Benin', de: 'Benin' }
  },
  {
    code: 'BM',
    phoneAreaCode: '+1441',
    name: { nl: 'Bermuda', en: 'Bermuda', de: 'Bermuda' }
  },
  {
    code: 'BN',
    phoneAreaCode: '+673',
    name: { nl: 'Brunei', en: 'Brunei', de: 'Brunei' }
  },
  {
    code: 'BO',
    phoneAreaCode: '+591',
    name: { nl: 'Bolivia', en: 'Bolivia', de: 'Bolivia' }
  },
  {
    code: 'BR',
    phoneAreaCode: '+55',
    name: { nl: 'Brazil', en: 'Brazil', de: 'Brazil' }
  },
  {
    code: 'BS',
    phoneAreaCode: '+1242',
    name: { nl: 'Bahamas', en: 'Bahamas', de: 'Bahamas' }
  },
  {
    code: 'BT',
    phoneAreaCode: '+975',
    name: { nl: 'Bhutan', en: 'Bhutan', de: 'Bhutan' }
  },
  {
    code: 'BV',
    phoneAreaCode: '+47',
    name: { nl: 'Bouvet Island', en: 'Bouvet Island', de: 'Bouvet Island' }
  },
  {
    code: 'BW',
    phoneAreaCode: '+267',
    name: { nl: 'Botswana', en: 'Botswana', de: 'Botswana' }
  },
  {
    code: 'BY',
    phoneAreaCode: '+375',
    name: { nl: 'Wit-Rusland', en: 'Belarus', de: 'Belarus' }
  },
  {
    code: 'BZ',
    phoneAreaCode: '+501',
    name: { nl: 'Belize', en: 'Belize', de: 'Belize' }
  },
  {
    code: 'CA',
    phoneAreaCode: '+1',
    name: { nl: 'Canada', en: 'Canada', de: 'Canada' }
  },
  {
    code: 'CD',
    phoneAreaCode: '+243',
    name: {
      nl: 'The Democratic Republic Of The Congo',
      en: 'The Democratic Republic Of The Congo',
      de: 'The Democratic Republic Of The Congo'
    }
  },
  {
    code: 'CF',
    phoneAreaCode: '+236',
    name: {
      nl: 'Central African Republic',
      en: 'Central African Republic',
      de: 'Central African Republic'
    }
  },
  {
    code: 'CG',
    phoneAreaCode: '+242',
    name: {
      nl: 'Republic of the Congo',
      en: 'Republic of the Congo',
      de: 'Republic of the Congo'
    }
  },
  {
    code: 'CH',
    phoneAreaCode: '+41',
    name: { nl: 'Zwitserland', en: 'Switzerland', de: 'Schweiz' }
  },
  {
    code: 'CI',
    phoneAreaCode: '+225',
    name: { nl: 'Cote d Ivoire', en: 'Cote d Ivoire', de: 'Cote d Ivoire' }
  },
  {
    code: 'CK',
    phoneAreaCode: '+682',
    name: { nl: 'Cook Islands', en: 'Cook Islands', de: 'Cook Islands' }
  },
  {
    code: 'CL',
    phoneAreaCode: '+56',
    name: { nl: 'Chile', en: 'Chile', de: 'Chile' }
  },
  {
    code: 'CM',
    phoneAreaCode: '+237',
    name: { nl: 'Cameroon', en: 'Cameroon', de: 'Cameroon' }
  },
  {
    code: 'CN',
    phoneAreaCode: '+86',
    name: { nl: 'China', en: 'China', de: 'China' }
  },
  {
    code: 'CO',
    phoneAreaCode: '+57',
    name: { nl: 'Colombia', en: 'Colombia', de: 'Colombia' }
  },
  {
    code: 'CR',
    phoneAreaCode: '+506',
    name: { nl: 'Costa Rica', en: 'Costa Rica', de: 'Costa Rica' }
  },
  {
    code: 'CU',
    phoneAreaCode: '+53',
    name: { nl: 'Cuba', en: 'Cuba', de: 'Cuba' }
  },
  {
    code: 'CV',
    phoneAreaCode: '+238',
    name: { nl: 'Cape Verde', en: 'Cape Verde', de: 'Cape Verde' }
  },
  {
    code: 'CX',
    phoneAreaCode: '+61',
    name: {
      nl: 'Christmas Island',
      en: 'Christmas Island',
      de: 'Christmas Island'
    }
  },
  {
    code: 'CY',
    phoneAreaCode: '+357',
    name: { nl: 'Cyprus', en: 'Cyprus', de: 'Cyprus' }
  },
  {
    code: 'CZ',
    phoneAreaCode: '+420',
    name: { nl: 'Tsjechië', en: 'Czech Republic', de: 'Tschechien' }
  },
  {
    code: 'DE',
    phoneAreaCode: '+49',
    name: { nl: 'Duitsland', en: 'Germany', de: 'Deutschland' }
  },
  {
    code: 'DJ',
    phoneAreaCode: '+253',
    name: { nl: 'Djibouti', en: 'Djibouti', de: 'Djibouti' }
  },
  {
    code: 'DK',
    phoneAreaCode: '+45',
    name: { nl: 'Denemarken', en: 'Denmark', de: 'Dänemark' }
  },
  {
    code: 'DM',
    phoneAreaCode: '+1767',
    name: { nl: 'Dominica', en: 'Dominica', de: 'Dominica' }
  },
  {
    code: 'DO',
    phoneAreaCode: '+1809',
    name: {
      nl: 'Dominicaanse Republiek',
      en: 'Dominican Republic',
      de: 'Dominikanische Republik'
    }
  },
  {
    code: 'DZ',
    phoneAreaCode: '+213',
    name: { nl: 'Algerije', en: 'Algeria', de: 'Algerien' }
  },
  {
    code: 'EC',
    phoneAreaCode: '+593',
    name: { nl: 'Ecuador', en: 'Ecuador', de: 'Ecuador' }
  },
  {
    code: 'EE',
    phoneAreaCode: '+372',
    name: { nl: 'Estland', en: 'Estonia', de: 'Estland' }
  },
  {
    code: 'EG',
    phoneAreaCode: '+20',
    name: { nl: 'Egypte', en: 'Egypt', de: 'Ägypten' }
  },
  {
    code: 'EH',
    phoneAreaCode: '+212',
    name: { nl: 'Western Sahara', en: 'Western Sahara', de: 'Western Sahara' }
  },
  {
    code: 'ER',
    phoneAreaCode: '+291',
    name: { nl: 'Eritrea', en: 'Eritrea', de: 'Eritrea' }
  },
  {
    code: 'ES',
    phoneAreaCode: '+34',
    name: { nl: 'Spanje', en: 'Spain', de: 'Spanien' }
  },
  {
    code: 'ET',
    phoneAreaCode: '+251',
    name: { nl: 'Ethiopië', en: 'Ethiopia', de: 'Äthiopien' }
  },
  {
    code: 'FI',
    phoneAreaCode: '+358',
    name: { nl: 'Finland', en: 'Finland', de: 'Finland' }
  },
  {
    code: 'FJ',
    phoneAreaCode: '+679',
    name: { nl: 'Fiji', en: 'Fiji', de: 'Fiji' }
  },
  {
    code: 'FK',
    phoneAreaCode: '+500',
    name: {
      nl: 'Falkland Eilanden',
      en: 'Falkland Islands',
      de: 'Falkland-Inseln'
    }
  },
  {
    code: 'FM',
    phoneAreaCode: '+691',
    name: {
      nl: 'Federated States of Micronesia',
      en: 'Federated States of Micronesia',
      de: 'Federated States of Micronesia'
    }
  },
  {
    code: 'FR',
    phoneAreaCode: '+33',
    name: { nl: 'Frankrijk', en: 'France', de: 'Frankreich' }
  },
  {
    code: 'GA',
    phoneAreaCode: '+241',
    name: { nl: 'Gabon', en: 'Gabon', de: 'Gabun' }
  },
  {
    code: 'GB',
    phoneAreaCode: '+44',
    name: {
      nl: 'Verenigd Koninkrijk',
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich'
    }
  },
  {
    code: 'GD',
    phoneAreaCode: '+1473',
    name: { nl: 'Grenada', en: 'Grenada', de: 'Grenada' }
  },
  {
    code: 'GE',
    phoneAreaCode: '+995',
    name: { nl: 'Georgië', en: 'Georgia', de: 'Georgien' }
  },
  {
    code: 'GF',
    phoneAreaCode: '+594',
    name: { nl: 'French Guiana', en: 'French Guiana', de: 'French Guiana' }
  },
  {
    code: 'GG',
    phoneAreaCode: '+44',
    name: { nl: 'Guernsey', en: 'Guernsey', de: 'Guernsey' }
  },
  {
    code: 'GI',
    phoneAreaCode: '+350',
    name: { nl: 'Gibraltar', en: 'Gibraltar', de: 'Gibraltar' }
  },
  {
    code: 'GL',
    phoneAreaCode: '+299',
    name: { nl: 'Greenland', en: 'Greenland', de: 'Greenland' }
  },
  {
    code: 'GM',
    phoneAreaCode: '+220',
    name: { nl: 'Gambia', en: 'Gambia', de: 'Gambia' }
  },
  {
    code: 'GN',
    phoneAreaCode: '+224',
    name: { nl: 'Guinea', en: 'Guinea', de: 'Guinea' }
  },
  {
    code: 'GP',
    phoneAreaCode: '+590',
    name: { nl: 'Guadeloupe', en: 'Guadeloupe', de: 'Guadeloupe' }
  },
  {
    code: 'GQ',
    phoneAreaCode: '+240',
    name: {
      nl: 'Equatorial Guinea',
      en: 'Equatorial Guinea',
      de: 'Equatorial Guinea'
    }
  },
  {
    code: 'GR',
    phoneAreaCode: '+30',
    name: { nl: 'Griekenland', en: 'Greece', de: 'Griechenland' }
  },
  {
    code: 'GS',
    phoneAreaCode: '+500',
    name: {
      nl: 'South Georgia and the South Sandwich Islands',
      en: 'South Georgia and the South Sandwich Islands',
      de: 'South Georgia and the South Sandwich Islands'
    }
  },
  {
    code: 'GT',
    phoneAreaCode: '+502',
    name: { nl: 'Guatemala', en: 'Guatemala', de: 'Guatemala' }
  },
  {
    code: 'GU',
    phoneAreaCode: '+1671',
    name: { nl: 'Guam', en: 'Guam', de: 'Guam' }
  },
  {
    code: 'GW',
    phoneAreaCode: '+245',
    name: { nl: 'Guinea-Bissau', en: 'Guinea-Bissau', de: 'Guinea-Bissau' }
  },
  {
    code: 'GY',
    phoneAreaCode: '+592',
    name: { nl: 'Guyana', en: 'Guyana', de: 'Guyana' }
  },
  {
    code: 'HK',
    phoneAreaCode: '+852',
    name: { nl: 'Hong Kong', en: 'Hong Kong', de: 'Hong Kong' }
  },
  {
    code: 'HM',
    phoneAreaCode: '+672',
    name: {
      nl: 'Heard Island and McDonald Islands',
      en: 'Heard Island and McDonald Islands',
      de: 'Heard Island and McDonald Islands'
    }
  },
  {
    code: 'HN',
    phoneAreaCode: '+504',
    name: { nl: 'Honduras', en: 'Honduras', de: 'Honduras' }
  },
  {
    code: 'HR',
    phoneAreaCode: '+385',
    name: { nl: 'Kroatië', en: 'Croatia', de: 'Kroatien' }
  },
  {
    code: 'HT',
    phoneAreaCode: '+509',
    name: { nl: 'Haiti', en: 'Haiti', de: 'Haiti' }
  },
  {
    code: 'HU',
    phoneAreaCode: '+36',
    name: { nl: 'Hongarije', en: 'Hungary', de: 'Ungarn' }
  },
  {
    code: 'ID',
    phoneAreaCode: '+62',
    name: { nl: 'Indonesia', en: 'Indonesia', de: 'Indonesia' }
  },
  {
    code: 'IE',
    phoneAreaCode: '+353',
    name: { nl: 'Ierland', en: 'Republic of Ireland', de: 'Irland' }
  },
  {
    code: 'IL',
    phoneAreaCode: '+972',
    name: { nl: 'Israël', en: 'Israel', de: 'Israel' }
  },
  {
    code: 'IM',
    phoneAreaCode: '+44',
    name: { nl: 'Isle of Man', en: 'Isle of Man', de: 'Isle of Man' }
  },
  {
    code: 'IN',
    phoneAreaCode: '+91',
    name: { nl: 'India', en: 'India', de: 'Indien' }
  },
  {
    code: 'IO',
    phoneAreaCode: '+246',
    name: {
      nl: 'British Indian Ocean Territory',
      en: 'British Indian Ocean Territory',
      de: 'British Indian Ocean Territory'
    }
  },
  {
    code: 'IQ',
    phoneAreaCode: '+964',
    name: { nl: 'Irak', en: 'Iraq', de: 'Irak' }
  },
  {
    code: 'IR',
    phoneAreaCode: '+98',
    name: { nl: 'Iran', en: 'Iran', de: 'Iran' }
  },
  {
    code: 'IS',
    phoneAreaCode: '+354',
    name: { nl: 'IJsland', en: 'Iceland', de: 'Island' }
  },
  {
    code: 'IT',
    phoneAreaCode: '+39',
    name: { nl: 'Italië', en: 'Italy', de: 'Italilen' }
  },
  {
    code: 'JE',
    phoneAreaCode: '+441534',
    name: { nl: 'Jersey', en: 'Jersey', de: 'Jersey' }
  },
  {
    code: 'JM',
    phoneAreaCode: '+1876',
    name: { nl: 'Jamaica', en: 'Jamaica ', de: 'Jamaica ' }
  },
  {
    code: 'JO',
    phoneAreaCode: '+962',
    name: { nl: 'Jordanië', en: 'Jordan', de: 'Jordanien' }
  },
  {
    code: 'JP',
    phoneAreaCode: '+81',
    name: { nl: 'Japan', en: 'Japan', de: 'Japan' }
  },
  {
    code: 'KE',
    phoneAreaCode: '+254',
    name: { nl: 'Kenia', en: 'Kenya', de: 'Kenia' }
  },
  {
    code: 'KG',
    phoneAreaCode: '+996',
    name: { nl: 'Kirgisië', en: 'Kyrgyzstan', de: 'Kirgisien' }
  },
  {
    code: 'KH',
    phoneAreaCode: '+855',
    name: { nl: 'Cambodja', en: 'Cambodia', de: 'Kambodscha' }
  },
  {
    code: 'KI',
    phoneAreaCode: '+686',
    name: { nl: 'Kiribati', en: 'Kiribati', de: 'Kiribati' }
  },
  {
    code: 'KM',
    phoneAreaCode: '+269',
    name: { nl: 'Comoren', en: 'Comoros', de: 'Komoren' }
  },
  {
    code: 'KN',
    phoneAreaCode: '+1869',
    name: {
      nl: 'Saint Kitts en Nevis',
      en: 'Saint Kitts and Nevis',
      de: 'St. Kitts und Nevis'
    }
  },
  {
    code: 'KP',
    phoneAreaCode: '+850',
    name: {
      nl: 'Democratische Volksrepubliek Korea',
      en: 'Democratic Peoples Republic of Korea',
      de: 'Demokratische Volksrepublik Korea'
    }
  },
  {
    code: 'KR',
    phoneAreaCode: '+82',
    name: {
      nl: 'Republiek Korea',
      en: 'Republic of Korea',
      de: 'Republik Korea'
    }
  },
  {
    code: 'KW',
    phoneAreaCode: '+965',
    name: { nl: 'Koeweit', en: 'Kuwait', de: 'Kuwait' }
  },
  {
    code: 'KY',
    phoneAreaCode: '+1345',
    name: { nl: 'Kaaiman Eilanden', en: 'Cayman Islands', de: 'Kaimaninseln' }
  },
  {
    code: 'KZ',
    phoneAreaCode: '+7',
    name: { nl: 'Kazachstan', en: 'Kazakhstan', de: 'Kasachstan' }
  },
  {
    code: 'LA',
    phoneAreaCode: '+856',
    name: {
      nl: 'Lao Peoples Democratic Republic',
      en: 'Lao Peoples Democratic Republic',
      de: 'Lao Peoples Democratic Republic'
    }
  },
  {
    code: 'LB',
    phoneAreaCode: '+961',
    name: { nl: 'Lebanon', en: 'Lebanon', de: 'Lebanon' }
  },
  {
    code: 'LC',
    phoneAreaCode: '+1758',
    name: { nl: 'Saint Lucia', en: 'Saint Lucia', de: 'St. Lucia' }
  },
  {
    code: 'LI',
    phoneAreaCode: '+423',
    name: { nl: 'Liechtenstein', en: 'Liechtenstein', de: 'Liechtenstein' }
  },
  {
    code: 'LK',
    phoneAreaCode: '+94',
    name: { nl: 'Sri Lanka', en: 'Sri Lanka', de: 'Sri Lanka' }
  },
  {
    code: 'LR',
    phoneAreaCode: '+231',
    name: { nl: 'Liberia', en: 'Liberia', de: 'Liberia' }
  },
  {
    code: 'LS',
    phoneAreaCode: '+266',
    name: { nl: 'Lesotho', en: 'Lesotho', de: 'Lesotho' }
  },
  {
    code: 'LT',
    phoneAreaCode: '+370',
    name: { nl: 'Litouwen', en: 'Lithuania', de: 'Litauen' }
  },
  {
    code: 'LU',
    phoneAreaCode: '+352',
    name: { nl: 'Luxemburg', en: 'Luxembourg', de: 'Luxemburg' }
  },
  {
    code: 'LV',
    phoneAreaCode: '+371',
    name: { nl: 'Letland', en: 'Latvia', de: 'Lettland' }
  },
  {
    code: 'LY',
    phoneAreaCode: '+218',
    name: {
      nl: 'Libyan Arab Jamahiriya',
      en: 'Libyan Arab Jamahiriya',
      de: 'Libyan Arab Jamahiriya'
    }
  },
  {
    code: 'MA',
    phoneAreaCode: '+212',
    name: { nl: 'Marokko', en: 'Morocco', de: 'Marokko' }
  },
  {
    code: 'MC',
    phoneAreaCode: '+377',
    name: { nl: 'Monaco', en: 'Monaco', de: 'Monaco' }
  },
  {
    code: 'MD',
    phoneAreaCode: '+373',
    name: {
      nl: 'Republiek Moldavië',
      en: 'Republic of Moldova',
      de: 'Republik Moldau'
    }
  },
  {
    code: 'ME',
    phoneAreaCode: '+382',
    name: { nl: 'Montenegro', en: 'Montenegro', de: 'Montenegro' }
  },
  {
    code: 'MG',
    phoneAreaCode: '+261',
    name: { nl: 'Madagascar', en: 'Madagascar', de: 'Madagascar' }
  },
  {
    code: 'MH',
    phoneAreaCode: '+692',
    name: {
      nl: 'Marshall Eilanden',
      en: 'Marshall Islands',
      de: 'Marschall-Inseln'
    }
  },
  {
    code: 'MK',
    phoneAreaCode: '+389',
    name: { nl: 'Macedonië', en: 'Macedonia', de: 'Mazedonien' }
  },
  {
    code: 'ML',
    phoneAreaCode: '+223',
    name: { nl: 'Mali', en: 'Mali', de: 'Mali' }
  },
  {
    code: 'MM',
    phoneAreaCode: '+95',
    name: { nl: 'Myanmar', en: 'Myanmar', de: 'Myanmar' }
  },
  {
    code: 'MN',
    phoneAreaCode: '+976',
    name: { nl: 'Monogolië', en: 'Mongolia', de: 'Mongolei' }
  },
  {
    code: 'MO',
    phoneAreaCode: '+853',
    name: { nl: 'Macao', en: 'Macao', de: 'Macao' }
  },
  {
    code: 'MP',
    phoneAreaCode: '+1670',
    name: {
      nl: 'Noordelijke Marianeneilanden',
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianen-Inseln'
    }
  },
  {
    code: 'MQ',
    phoneAreaCode: '+596',
    name: { nl: 'Martinique', en: 'Martinique', de: 'Martinique' }
  },
  {
    code: 'MR',
    phoneAreaCode: '+222',
    name: { nl: 'Mauritanië', en: 'Mauritania', de: 'Mauretanien' }
  },
  {
    code: 'MS',
    phoneAreaCode: '+1664',
    name: { nl: 'Montserrat', en: 'Montserrat', de: 'Montserrat' }
  },
  {
    code: 'MT',
    phoneAreaCode: '+356',
    name: { nl: 'Malta', en: 'Malta', de: 'Malta' }
  },
  {
    code: 'MU',
    phoneAreaCode: '+230',
    name: { nl: 'Mauritius', en: 'Mauritius', de: 'Mauritius' }
  },
  {
    code: 'MV',
    phoneAreaCode: '+960',
    name: { nl: 'Malediven', en: 'Maldives', de: 'Malediven' }
  },
  {
    code: 'MW',
    phoneAreaCode: '+265',
    name: { nl: 'Malawi', en: 'Malawi', de: 'Malawi' }
  },
  {
    code: 'MX',
    phoneAreaCode: '+52',
    name: { nl: 'Mexico', en: 'Mexico', de: 'Mexico' }
  },
  {
    code: 'MY',
    phoneAreaCode: '+60',
    name: { nl: 'Maleisië', en: 'Malaysia', de: 'Malasia' }
  },
  {
    code: 'MZ',
    phoneAreaCode: '+258',
    name: { nl: 'Mozambique', en: 'Mozambique', de: 'Mozambique' }
  },
  {
    code: 'NA',
    phoneAreaCode: '+264',
    name: { nl: 'Namibië', en: 'Namibia', de: 'Namibia' }
  },
  {
    code: 'NC',
    phoneAreaCode: '+687',
    name: { nl: 'New Caledonia', en: 'New Caledonia', de: 'New Caledonia' }
  },
  {
    code: 'NE',
    phoneAreaCode: '+227',
    name: { nl: 'Niger', en: 'Niger', de: 'Niger' }
  },
  {
    code: 'NF',
    phoneAreaCode: '+672',
    name: { nl: 'Norfolk-eiland', en: 'Norfolk Island', de: 'Norfolkinsel' }
  },
  {
    code: 'NG',
    phoneAreaCode: '+234',
    name: { nl: 'Nigeria', en: 'Nigeria', de: 'Nigeria' }
  },
  {
    code: 'NI',
    phoneAreaCode: '+505',
    name: { nl: 'Nicaragua', en: 'Nicaragua', de: 'Nicaragua' }
  },
  {
    code: 'NL',
    phoneAreaCode: '+31',
    name: { nl: 'Nederland', en: 'Netherlands', de: 'Niederlande' }
  },
  {
    code: 'NO',
    phoneAreaCode: '+47',
    name: { nl: 'Noorwegen', en: 'Norway', de: 'Norwegen' }
  },
  {
    code: 'NP',
    phoneAreaCode: '+977',
    name: { nl: 'Nepal', en: 'Nepal', de: 'Nepal' }
  },
  {
    code: 'NR',
    phoneAreaCode: '+674',
    name: { nl: 'Nauru', en: 'Nauru', de: 'Nauru' }
  },
  {
    code: 'NU',
    phoneAreaCode: '+683',
    name: { nl: 'Niue', en: 'Niue', de: 'Niue' }
  },
  {
    code: 'NZ',
    phoneAreaCode: '+64',
    name: { nl: 'Nieuw-Zeeland', en: 'New Zealand', de: 'Neuseeland' }
  },
  {
    code: 'OM',
    phoneAreaCode: '+968',
    name: { nl: 'Oman', en: 'Oman', de: 'Oman' }
  },
  {
    code: 'PA',
    phoneAreaCode: '+507',
    name: { nl: 'Panama', en: 'Panama', de: 'Panama' }
  },
  {
    code: 'PE',
    phoneAreaCode: '+51',
    name: { nl: 'Peru', en: 'Peru', de: 'Peru' }
  },
  {
    code: 'PF',
    phoneAreaCode: '+689',
    name: {
      nl: 'Frans Polynesië',
      en: 'French Polynesia',
      de: 'Französisch-Polynesien'
    }
  },
  {
    code: 'PG',
    phoneAreaCode: '+675',
    name: {
      nl: 'Papoea Nieuw-Guinea',
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea'
    }
  },
  {
    code: 'PH',
    phoneAreaCode: '+63',
    name: { nl: 'Filippijnen', en: 'Philippines', de: 'Philippinen' }
  },
  {
    code: 'PK',
    phoneAreaCode: '+92',
    name: { nl: 'Pakistan', en: 'Pakistan', de: 'Pakistan' }
  },
  {
    code: 'PL',
    phoneAreaCode: '+48',
    name: { nl: 'Poland', en: 'Poland', de: 'Poland' }
  },
  {
    code: 'PM',
    phoneAreaCode: '+508',
    name: {
      nl: 'Saint Pierre and Miquelon',
      en: 'Saint Pierre and Miquelon',
      de: 'Saint Pierre and Miquelon'
    }
  },
  {
    code: 'PN',
    phoneAreaCode: '+64',
    name: { nl: 'Pitcairn', en: 'Pitcairn', de: 'Pitcairn' }
  },
  {
    code: 'PR',
    phoneAreaCode: '+1',
    name: { nl: 'Puerto Rico', en: 'Puerto Rico', de: 'Puerto Rico' }
  },
  {
    code: 'PS',
    phoneAreaCode: '+970',
    name: {
      nl: 'Palestinian Territory, Occupied',
      en: 'Palestinian Territory, Occupied',
      de: 'Palestinian Territory, Occupied'
    }
  },
  {
    code: 'PT',
    phoneAreaCode: '+351',
    name: { nl: 'Portugal', en: 'Portugal', de: 'Portugal' }
  },
  {
    code: 'PW',
    phoneAreaCode: '+680',
    name: { nl: 'Palau', en: 'Palau', de: 'Palau' }
  },
  {
    code: 'PY',
    phoneAreaCode: '+595',
    name: { nl: 'Paraguay', en: 'Paraguay', de: 'Paraguay' }
  },
  {
    code: 'QA',
    phoneAreaCode: '+974',
    name: { nl: 'Qatar', en: 'Qatar', de: 'Qatar' }
  },
  {
    code: 'RE',
    phoneAreaCode: '+262',
    name: { nl: 'Reunion', en: 'Reunion', de: 'Reunion' }
  },
  {
    code: 'RO',
    phoneAreaCode: '+40',
    name: { nl: 'Roemenië', en: 'Romania', de: 'Rumänien' }
  },
  {
    code: 'RS',
    phoneAreaCode: '+381',
    name: {
      nl: 'Republic of Serbia',
      en: 'Republic of Serbia',
      de: 'Republic of Serbia'
    }
  },
  {
    code: 'RU',
    phoneAreaCode: '+7',
    name: { nl: 'Rusland', en: 'Russia', de: 'Russland' }
  },
  {
    code: 'RW',
    phoneAreaCode: '+250',
    name: { nl: 'Rwanda', en: 'Rwanda', de: 'Rwanda' }
  },
  {
    code: 'SA',
    phoneAreaCode: '+966',
    name: { nl: 'Saoedi-Arabië', en: 'Saudi-Arabien', de: 'Saudi-Arabien' }
  },
  {
    code: 'SB',
    phoneAreaCode: '+677',
    name: {
      nl: 'Solomon Islands',
      en: 'Solomon Islands',
      de: 'Solomon Islands'
    }
  },
  {
    code: 'SC',
    phoneAreaCode: '+248',
    name: { nl: 'Seychelles', en: 'Seychelles', de: 'Seychelles' }
  },
  {
    code: 'SD',
    phoneAreaCode: '+249',
    name: { nl: 'Sudan', en: 'Sudan', de: 'Sudan' }
  },
  {
    code: 'SE',
    phoneAreaCode: '+46',
    name: { nl: 'Zweden', en: 'Sweden', de: 'Schweden' }
  },
  {
    code: 'SG',
    phoneAreaCode: '+65',
    name: { nl: 'Singapore', en: 'Singapore', de: 'Singapore' }
  },
  {
    code: 'SH',
    phoneAreaCode: '+290',
    name: { nl: 'St. Helena', en: 'St. Helena', de: 'St. Helena' }
  },
  {
    code: 'SI',
    phoneAreaCode: '+386',
    name: { nl: 'Slovenia', en: 'Slovenia', de: 'Slovenia' }
  },
  {
    code: 'SJ',
    phoneAreaCode: '+47',
    name: {
      nl: 'Svalbard and Jan Mayen',
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard and Jan Mayen'
    }
  },
  {
    code: 'SK',
    phoneAreaCode: '+421',
    name: { nl: 'Slovakije', en: 'Slovakia', de: 'Slowakei' }
  },
  {
    code: 'SL',
    phoneAreaCode: '+232',
    name: { nl: 'Sierra Leone', en: 'Sierra Leone', de: 'Sierra Leone' }
  },
  {
    code: 'SM',
    phoneAreaCode: '+378',
    name: { nl: 'San Marino', en: 'San Marino', de: 'San Marino' }
  },
  {
    code: 'SN',
    phoneAreaCode: '+221',
    name: { nl: 'Senegal', en: 'Senegal', de: 'Senegal' }
  },
  {
    code: 'SO',
    phoneAreaCode: '+252',
    name: { nl: 'Somalia', en: 'Somalia', de: 'Somalia' }
  },
  {
    code: 'SR',
    phoneAreaCode: '+597',
    name: { nl: 'Suriname', en: 'Suriname', de: 'Suriname' }
  },
  {
    code: 'ST',
    phoneAreaCode: '+239',
    name: {
      nl: 'Sao Tome and Principe',
      en: 'Sao Tome and Principe',
      de: 'Sao Tome and Principe'
    }
  },
  {
    code: 'SV',
    phoneAreaCode: '+503',
    name: { nl: 'El Salvador', en: 'El Salvador', de: 'El Salvador' }
  },
  {
    code: 'SY',
    phoneAreaCode: '+963',
    name: {
      nl: 'Syrian Arab Republic',
      en: 'Syrian Arab Republic',
      de: 'Syrian Arab Republic'
    }
  },
  {
    code: 'SZ',
    phoneAreaCode: '+268',
    name: { nl: 'Swaziland', en: 'Swaziland', de: 'Swaziland' }
  },
  {
    code: 'TC',
    phoneAreaCode: '+1649',
    name: {
      nl: 'Turks and Caicos Islands',
      en: 'Turks and Caicos Islands',
      de: 'Turks and Caicos Islands'
    }
  },
  {
    code: 'TD',
    phoneAreaCode: '+235',
    name: { nl: 'Chad', en: 'Chad', de: 'Chad' }
  },
  {
    code: 'TF',
    phoneAreaCode: '+262',
    name: {
      nl: 'French Southern Territories',
      en: 'French Southern Territories',
      de: 'French Southern Territories'
    }
  },
  {
    code: 'TG',
    phoneAreaCode: '+228',
    name: { nl: 'Togo', en: 'Togo', de: 'Togo' }
  },
  {
    code: 'TH',
    phoneAreaCode: '+66',
    name: { nl: 'Thailand', en: 'Thailand', de: 'Thailand' }
  },
  {
    code: 'TJ',
    phoneAreaCode: '+992',
    name: { nl: 'Tajikistan', en: 'Tajikistan', de: 'Tajikistan' }
  },
  {
    code: 'TK',
    phoneAreaCode: '+690',
    name: { nl: 'Tokelau', en: 'Tokelau', de: 'Tokelau' }
  },
  {
    code: 'TL',
    phoneAreaCode: '+670',
    name: { nl: 'Timor-Leste', en: 'Timor-Leste', de: 'Timor-Leste' }
  },
  {
    code: 'TM',
    phoneAreaCode: '+993',
    name: { nl: 'Turkmenistan', en: 'Turkmenistan', de: 'Turkmenistan' }
  },
  {
    code: 'TN',
    phoneAreaCode: '+216',
    name: { nl: 'Tunisia', en: 'Tunisia', de: 'Tunisia' }
  },
  {
    code: 'TO',
    phoneAreaCode: '+676',
    name: { nl: 'Tonga', en: 'Tonga', de: 'Tonga' }
  },
  {
    code: 'TR',
    phoneAreaCode: '+90',
    name: { nl: 'Turkey', en: 'Turkey', de: 'Turkey' }
  },
  {
    code: 'TT',
    phoneAreaCode: '+1868',
    name: {
      nl: 'Trinidad and Tobago',
      en: 'Trinidad and Tobago',
      de: 'Trinidad and Tobago'
    }
  },
  {
    code: 'TV',
    phoneAreaCode: '+688',
    name: { nl: 'Tuvalu', en: 'Tuvalu', de: 'Tuvalu' }
  },
  {
    code: 'TW',
    phoneAreaCode: '+886',
    name: { nl: 'Taiwan', en: 'Taiwan', de: 'Taiwan' }
  },
  {
    code: 'TZ',
    phoneAreaCode: '+255',
    name: { nl: 'Tanzania', en: 'Tanzania', de: 'Tanzania' }
  },
  {
    code: 'UA',
    phoneAreaCode: '+380',
    name: { nl: 'Ukraine', en: 'Ukraine', de: 'Ukraine' }
  },
  {
    code: 'UG',
    phoneAreaCode: '+256',
    name: { nl: 'Uganda', en: 'Uganda', de: 'Uganda' }
  },
  {
    code: 'UM',
    phoneAreaCode: '+699',
    name: {
      nl: 'United States Minor Outlying Islands',
      en: 'United States Minor Outlying Islands',
      de: 'United States Minor Outlying Islands'
    }
  },
  {
    code: 'US',
    phoneAreaCode: '+1',
    name: { nl: 'United States', en: 'United States', de: 'United States' }
  },
  {
    code: 'UY',
    phoneAreaCode: '+598',
    name: { nl: 'Uruguay', en: 'Uruguay', de: 'Uruguay' }
  },
  {
    code: 'UZ',
    phoneAreaCode: '+998',
    name: { nl: 'Uzbekistan', en: 'Uzbekistan', de: 'Uzbekistan' }
  },
  {
    code: 'VA',
    phoneAreaCode: '+379',
    name: {
      nl: 'Vatican City State',
      en: 'Vatican City State ',
      de: 'Vatican City State '
    }
  },
  {
    code: 'VC',
    phoneAreaCode: '+1784',
    name: {
      nl: 'Saint Vincent and the Grenadines',
      en: 'Saint Vincent and the Grenadines',
      de: 'Saint Vincent and the Grenadines'
    }
  },
  {
    code: 'VE',
    phoneAreaCode: '+58',
    name: { nl: 'Venezuela', en: 'Venezuela', de: 'Venezuela' }
  },
  {
    code: 'VG',
    phoneAreaCode: '+1284',
    name: {
      nl: 'Virgin Islands, British',
      en: 'Virgin Islands, British',
      de: 'Virgin Islands, British'
    }
  },
  {
    code: 'VI',
    phoneAreaCode: '+1340',
    name: {
      nl: 'Virgin Islands, U.S',
      en: 'Virgin Islands, U.S',
      de: 'Virgin Islands, U.S'
    }
  },
  {
    code: 'VN',
    phoneAreaCode: '+84',
    name: { nl: 'Viet Nam', en: 'Viet Nam', de: 'Viet Nam' }
  },
  {
    code: 'VU',
    phoneAreaCode: '+678',
    name: { nl: 'Vanuatu', en: 'Vanuatu', de: 'Vanuatu' }
  },
  {
    code: 'WF',
    phoneAreaCode: '+681',
    name: {
      nl: 'Wallis and Futuna',
      en: 'Wallis and Futuna',
      de: 'Wallis and Futuna'
    }
  },
  {
    code: 'WS',
    phoneAreaCode: '+685',
    name: { nl: 'Samoa', en: 'Samoa', de: 'Samoa' }
  },
  {
    code: 'YE',
    phoneAreaCode: '+967',
    name: { nl: 'Yemen', en: 'Yemen', de: 'Yemen' }
  },
  {
    code: 'YT',
    phoneAreaCode: '+269',
    name: { nl: 'Mayotte', en: 'Mayotte', de: 'Mayotte' }
  },
  {
    code: 'ZA',
    phoneAreaCode: '+27',
    name: { nl: 'South Africa', en: 'South Africa', de: 'South Africa' }
  },
  {
    code: 'ZM',
    phoneAreaCode: '+260',
    name: { nl: 'Zambia', en: 'Zambia', de: 'Zambia' }
  },
  {
    code: 'ZW',
    phoneAreaCode: '+263',
    name: { nl: 'Zimbabwe', en: 'Zimbabwe', de: 'Zimbabwe' }
  }
] as const

export type Country = typeof Countries[number]
export type CountryCode = typeof Countries[number]['code']
export type CountryCodePhone = typeof Countries[number]['phoneAreaCode']

export const getCountryByCode = (countryCode: CountryCode) => {
  return Countries.find(country => country.code === countryCode)
}

export const getCountryPhoneAreaCodes = () => {
  return Countries.map(country => {
    return country.phoneAreaCode
  })
}
