/* eslint-disable react/prop-types */
import { ReactNode } from 'react'
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_HR,
  NODE_IMAGE,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_UL,
  render
} from 'storyblok-rich-text-react-renderer'

import {
  LinkField,
  RichTextField
} from '../../../domains/storyblok/models/fields/types'
import { HeadingTypes, ParagraphTypes } from '../../../types/typography'
import {
  FetchedBlockquote,
  FetchedCard,
  FetchedRestaurantMenuCategory,
  FetchedTextWithIcon
} from '../../../domains/storyblok/models/components/types'
import mapCard from '../../../domains/storyblok/mappers/map-card'
import { Button } from '../../button'
import { Heading, Paragraph } from '../../typography'
import { Hr } from '../../hr'
import type { IUspListItemProps } from '../../usp-list'
import { UspList } from '../../usp-list'
import { IImageProps, Image } from '../../image'
import { CardList } from '../../card-list'
import { TextWithIcon } from '../../text-with-icon'
import { RestaurantMenuCategory } from '../../restaurant-menu-category'
import { LinkProps } from '../../link'
import {
  StyledButton,
  StyledOrderedList,
  StyledUnorderedList
} from '../RichText.style'
import { Blockquote } from '../../blockquote/Blockquote'
import mapLink from '../../../domains/storyblok/mappers/map-link'

export const useRichContent = (
  richContent: RichTextField,
  theme: string,
  paragraphType?: ParagraphTypes,
  isLight?: boolean
): ReactNode => {
  const inlineButtonThemeVariant = () => {
    switch (theme) {
      case 'secondary':
        return 'inline-secondary'
      case 'tertiary':
        return 'inline-tertiary'
      default:
        return 'inline'
    }
  }

  const inlineButton = isLight
    ? 'inline-light'
    : theme
    ? inlineButtonThemeVariant()
    : 'inline'

  const reactComponents = render(richContent, {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { href, linktype, target } = props as LinkProps

        if (linktype === 'email') {
          return (
            <Button
              variant={inlineButton}
              label={children}
              link={{
                href: `mailto:${href}`,
                isExternal: true
              }}
            />
          )
        }
        if (href?.match(/^(https?:)?\/\//)) {
          return (
            <Button
              label={children}
              variant={inlineButton}
              link={{
                href: href as string,
                isExternal: true,
                openInNewWindow: true
              }}
            />
          )
        }
        return (
          <Button
            label={children}
            variant={inlineButton}
            link={{
              href,
              openInNewWindow: target === '_blank'
            }}
          />
        )
      }
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        const type = {
          1: HeadingTypes.DISPLAY_XL,
          2: HeadingTypes.DISPLAY_L,
          3: HeadingTypes.DISPLAY_M,
          4: HeadingTypes.DISPLAY_S,
          5: HeadingTypes.DISPLAY_XS,
          6: HeadingTypes.DISPLAY_XXS
        }
        return <Heading type={type[level]}>{children}</Heading>
      },
      [NODE_PARAGRAPH]: children => (
        <Paragraph type={paragraphType || ParagraphTypes.TEXT_M}>
          {children}
        </Paragraph>
      ),
      [NODE_OL]: children => <StyledOrderedList>{children}</StyledOrderedList>,
      [NODE_UL]: children => (
        <StyledUnorderedList>{children}</StyledUnorderedList>
      ),
      [NODE_HR]: () => <Hr />,
      [NODE_IMAGE]: (_children, props) => {
        return <Image {...(props as IImageProps)} hasBorderRadius />
      }
    },
    blokResolvers: {
      callToAction: props => {
        const link = mapLink(props.link as LinkField)
        return <StyledButton {...props} variant={theme} link={link} />
      },
      uspList: props => {
        return <UspList usps={props.usps as IUspListItemProps[]} />
      },
      cardList: props => {
        const cards = props.cards as FetchedCard[]
        const items = cards.map(card => mapCard(card))
        return <CardList cards={items} />
      },
      textWithIcon: props => {
        return <TextWithIcon {...(props as FetchedTextWithIcon)} />
      },
      restaurantMenuCategory: props => {
        return (
          <RestaurantMenuCategory
            {...(props as FetchedRestaurantMenuCategory)}
          />
        )
      },
      blockquote: props => {
        return <Blockquote {...(props as FetchedBlockquote)} variant={theme} />
      }
    }
  })
  return reactComponents
}
