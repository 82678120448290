import { apiPlugin, storyblokInit } from 'gatsby-source-storyblok'

import { SBPreview } from '../utils/sbPreview'
import { isSBPreviewMode } from '../utils/storyblok'

export const initStoryblokBridge = () => {
  /**
   * By default only initialize storyblok without apiPlugin / accesstoken.
   * We don't want to expose the token on the client side.
   *
   * Only load in the apiPlugin in Storyblok edit mode,
   * so that we can fetch the "draft" content.
   */
  const isPreviewMode = isSBPreviewMode()
  const previewAccessToken = SBPreview.getToken()
  const accessToken = isPreviewMode ? previewAccessToken : undefined
  const use = isPreviewMode && accessToken ? [apiPlugin] : undefined

  storyblokInit({
    bridge: false,
    accessToken,
    use
  })
}
