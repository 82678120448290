import styled from 'styled-components'

import { Paragraph, Heading } from '../typography'

export const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3.2rem;

  & + & {
    margin-top: 2.4rem;
  }
`

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
`

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
  margin-top: 0.4rem;
`

export const StyledPrice = styled(Heading)`
  color: ${({ theme }) => theme.COLOR.NEUTRAL_20};
  text-align: right;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.SM - 1}px) {
    flex: 1 0 auto;
  }
`
