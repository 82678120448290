import { replaceStoryblokCDN, rewriteSlug } from '../../../utils/url'
import { HrefLink } from '../../../utils/types'
import { LinkField } from '../models/fields/types'

const mapLink = (link: LinkField): HrefLink | null => {
  if (link === undefined) {
    return null
  }

  const isExternal = link.linktype !== 'story'
  const openInNewWindow = link?.target === '_blank'

  if ((isExternal && !link.url) || (!isExternal && !link.id)) {
    return null
  }

  let href
  if (link.linktype === 'url' || link.linktype === 'asset') {
    href = replaceStoryblokCDN(link.url)
  } else if (link.linktype === 'story') {
    if (link.story) {
      href = link.story?.full_slug
      if (href && href !== '/') {
        href = `/${link.story?.full_slug}`
        href += link.story?.full_slug?.slice(-1) !== '/' ? '/' : ''
      }
    } else {
      href = link.cached_url
    }
  }

  href = `${rewriteSlug(href || '', 'all')}`
  if (!isExternal && !href.startsWith('/')) {
    href = `/${href}`
  }

  return {
    href,
    isExternal,
    openInNewWindow
  }
}

export default mapLink
