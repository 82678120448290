import styled from 'styled-components'

import { Heading } from '../typography'

export const StyledRestaurantMenuCategory = styled.div`
  & + & {
    margin-top: 4rem;

    @media screen and (min-width: ${({ theme }) =>
        theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
      margin-top: 6.4rem;
    }
  }
`

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_20};
  padding-bottom: 0.8rem;
  margin-bottom: 3.2rem;
`
