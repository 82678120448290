import { ReactNode } from 'react'

import { HrefLink } from '../../utils/types'
import { IconNames } from '../icon/constants'
import { Link } from '../link'
import { SiteUtils } from '../../utils/site'

import { StyledContainer, StyledIcon } from './Button.style'
import { ButtonVariant } from './Button.types'

export interface IButtonProps {
  label?: string | ReactNode
  isSmall?: boolean
  isLarge?: boolean
  variant?: ButtonVariant
  icon?: IconNames
  disabled?: boolean
  link?: HrefLink | null
  isReversed?: boolean
  hasIconCircle?: boolean
  className?: string
  isLoading?: boolean
  isStretched?: boolean
  children?: ReactNode
  [key: string]: unknown
}

export const Button = ({
  label,
  isSmall,
  isLarge,
  variant = 'default',
  disabled,
  icon,
  link,
  isReversed,
  hasIconCircle,
  isLoading,
  isStretched,
  children,
  ...props
}: IButtonProps) => {
  const Component = link ? Link : 'button'
  const isLandbased = SiteUtils.isLandbased()

  return (
    <StyledContainer
      link={link}
      as={Component}
      isSquare={!label && !isStretched && icon}
      {...{
        disabled,
        isSmall,
        isLarge,
        variant,
        isReversed,
        isStretched,
        ...props
      }}
    >
      {label && label}
      {children && children}
      {icon && (
        <StyledIcon name={icon} hasCircle={isLandbased && hasIconCircle} />
      )}
      {isLoading && <StyledIcon name={IconNames.LOADER} />}
    </StyledContainer>
  )
}
