import styled, { css } from 'styled-components'

import { ParagraphTypes } from '../../types/typography'
import { Button } from '../button'

interface Props {
  isLight?: boolean
  paragraphType?: ParagraphTypes
}

export const StyledUnorderedList = styled.ul`
  li {
    list-style: disc;
    padding-left: 0.8rem;
    margin-left: 1.6rem;

    &::marker {
      font-size: 1.8rem;
    }
  }
`

export const StyledOrderedList = styled.ol`
  li {
    list-style: decimal;
    padding-left: 0.8rem;
    margin-left: 1.6rem;

    &::marker {
      ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_S]};
      font-weight: 600;
    }
  }
`

export const StyledButton = styled(Button)`
  margin-top: 4rem;
`

export const StyledContainer = styled.div<Props>`
  ${({ isLight, theme }) =>
    isLight &&
    css`
      color: ${theme.COLOR.NEUTRAL_10};
    `};

  > * + ol,
  > * + ul {
    margin-top: 4rem;
  }

  > * + p,
  > * + figure {
    margin-top: 2.4rem;
  }

  > * + h1,
  > * + h2,
  > * + h3,
  > * + h4,
  > * + h5,
  > * + h6 {
    margin-top: 4rem;
  }

  h4,
  h5,
  h6 {
    text-transform: none;
  }
`
