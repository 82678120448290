import styled from 'styled-components'

import { Icon } from '../icon'
import { Paragraph } from '../typography'

export const StyledItem = styled.li`
  & + & {
    margin-top: 1.6rem;
  }
`

export const StyledParagraph = styled(Paragraph)`
  display: flex;
  gap: 1.6rem;
`

export const StyledIcon = styled(Icon)`
  height: 1.6rem;
  padding: 0.3rem;
  fill: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
  color: ${({ theme }) => theme.COMPONENTS.PRIMARY_COLOR};
  margin-top: 0.2rem;
`
