import styled, { css } from 'styled-components'

interface ImageProps {
  isCovered?: boolean
  hasBorderRadius?: boolean
}

export const SBImageContainer = styled.div<ImageProps>(
  ({ isCovered, hasBorderRadius }) => css`
    display: flex;
    width: 100%;
    height: auto;

    .gatsby-image-wrapper-constrained {
      display: block;
    }

    img {
      object-fit: cover;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    ${isCovered &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    `}

    ${hasBorderRadius &&
    css`
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
    `}
  `
)
