import styled, { css } from 'styled-components'

import { TYPOGRAPHY } from '../../../theme/basic/typography'
import { ParagraphTypes } from '../../../types/typography'

interface IParagraphProps {
  type?: ParagraphTypes
  align?: 'left' | 'right' | 'center'
}

export const StyledParagraph = styled.p<IParagraphProps>(
  ({ type = ParagraphTypes.TEXT_L, align }) => css`
    ${type && TYPOGRAPHY[type]};
    ${align &&
    css`
      text-align: ${align};
    `}
  `
)
