import styled from 'styled-components'

import { Icon, IconProps } from '../icon'

export const SocialListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
`

export const SocialIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
`
