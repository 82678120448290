import { LoginErrors } from './auth.types'

export type PASRealMode = 0 | 1
export type PASBoolean = 0 | 1

export interface PASTime {
  timestamp: string
}

export interface PASLoginErrorResponse {
  errorCode: number
}

export enum PASStatus {
  SUCCESS = 0,
  UNKNOWN_USER = 3,
  WRONG_USERNAME_PASS = 104,
  FRAUD = 48,
  USER_BLOCKED = 178
}

export interface PASRequestTemporaryTokenResponse {
  userId: string
  errorCode: PASStatus
  username: string
  realMode: PASRealMode
  sessionToken: {
    sessionToken: string
    issuerSystemId: string
    creationTime: PASTime
    expirationTime: PASTime
  }
}

export interface SessionValidationByTCVersionData {
  tcVersionUrl: string
  termVersionReference: string
}

export interface PASLoginAndGetTempTokenResponse
  extends PASRequestTemporaryTokenResponse {
  ipCountryCode: string
  sessionValidationData: {
    SessionValidationByTCVersionData: SessionValidationByTCVersionData[]
  }
}

export interface PASLoggedInPlayerResponse {
  cookieExists: '0' | '1'
  errorCode: number
  requestId: string
  sessionRef: string
  userId: string
  username: string
}

export interface PASLogoutResponse {
  errorCode: number
  messageId: string
  flow: string
  closedSessions: string[]
}

export interface PASLoginResponse {
  errorCode: number
  messageId: string
  flow: string
  requestId: string
  sessionRef: string[]
}

export class PASError extends Error {
  type = 'PASError'
  errorCode: PASStatus

  constructor(errorCode: PASStatus, message: string) {
    super(message)
    Object.setPrototypeOf(this, PASError.prototype)

    this.errorCode = errorCode
  }

  getErrorMessage() {
    return `PAS Error: ${this.message}, errorCode: ${this.errorCode}`
  }
}

export function isPASError(error: LoginErrors): error is PASError {
  return (error as PASError).type === 'PASError'
}

export class PASTermsAndConditionsError extends Error {
  termsAndConditions: SessionValidationByTCVersionData

  constructor(
    termsAndConditions: SessionValidationByTCVersionData,
    message: string
  ) {
    super(message)
    Object.setPrototypeOf(this, PASError.prototype)

    this.termsAndConditions = termsAndConditions
  }
}

export function isTermsAndConditionsError(
  error: LoginErrors
): error is PASTermsAndConditionsError {
  return (error as PASTermsAndConditionsError).termsAndConditions !== undefined
}
