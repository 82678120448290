import styled from 'styled-components'

import { Paragraph } from '../typography'

export const StyledPreviewTokenMessage = styled(Paragraph)`
  background-color: ${({ theme }) => theme.COLOR.WARNING};
  padding: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
`

export const StyledEnablePreviewModeLink = styled.a`
  color: white;
  text-decoration: underline;
`
