import { FetchedHeader } from '../models/components/types'

import mapHeaderNavItem, { IHeaderNavItem } from './map-header-nav-item'

export interface IHeaderProps {
  navList?: IHeaderNavItem[] | undefined
}

export default function mapHeader({
  navList,
  ...props
}: FetchedHeader): IHeaderProps {
  return {
    navList: navList ? navList.map(item => mapHeaderNavItem(item)) : [],
    ...props
  }
}
