import slugifyString, { Options as SlugifyOptions } from '@sindresorhus/slugify'

import { StoryBlokSiteFolder } from '../types'

import { SiteUtils } from './site'

export const slugifyOptions: SlugifyOptions = {
  customReplacements: [['&', ' en ']]
}

/**
 * Get querystring parameters
 *
 * @param paramName
 * @returns The value for the given paramName
 */
export const getUrlParam = <T = string>(
  paramName: string,
  defaultValue: string | null = null
): T | string | null => {
  if (typeof window === 'undefined') {
    return null
  }

  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(paramName) || defaultValue
}

/**
 * Get querystring parameters and include duplicates
 *
 * @param paramName
 * @returns An array of parameter values
 */
export const getAllUrlParam = <T = string>(
  paramName: string,
  defaultValue: string[] | null = null
): T | string[] | null => {
  if (typeof window === 'undefined') {
    return null
  }

  const urlParams = new URLSearchParams(window.location.search)
  const urlParamExists = urlParams.get(paramName)
  return urlParamExists ? urlParams.getAll(paramName) : defaultValue
}

/**
 * Return full query string from object
 * NOTE: Supports string and array as a value
 * @param queryObject
 * @param excludeEmptyValues optional exclude parameters which have a empty value
 * @returns a query string like ?test=value
 */
export const getQueryStringFromObject = (
  queryObject: { [key: string]: string | string[] },
  excludeEmptyValues?: boolean
) => {
  const params = new URLSearchParams()
  const objEntries = Object.entries(queryObject) as Array<string | string[]>
  objEntries.forEach(([key, value]) => {
    if (!excludeEmptyValues || value) {
      //  value is a array
      if (Array.isArray(value)) {
        //  exclude empty array if excludeEmptyValues === true
        if (!value.length && !excludeEmptyValues) {
          params.append(key, '')
        }

        //  exclude empty array values if excludeEmptyValues === true
        value.forEach(arrayVal => {
          if (!excludeEmptyValues || arrayVal) {
            params.append(key, arrayVal)
          }
        })
      } else {
        //  value is a string
        params.append(key, value)
      }
    }
  })

  const paramString = params.toString()
  return paramString ? '?' + params : ''
}

/* Get full query string if window defined */
export const getUrlParamsString = () => {
  if (typeof window === 'undefined') {
    return null
  }
  return window.location.search
}

/**
 * Get full site url
 *
 * @param path
 * @returns The full site url
 */
export const getFullSiteUrl = (path = ''): string => {
  const siteURL = process.env.GATSBY_SITE_URL

  return new URL(path, siteURL).toString()
}

export const getPagePathname = (
  returnFullPath: boolean
): string | null | undefined => {
  if (typeof window === 'undefined') {
    return null
  }
  const pathname = window.location.pathname

  if (returnFullPath) {
    return pathname
  }
  const pages = pathname.split('/')
  return pages.pop()
}

/* Add a leading slash if not present */
export const getPathnameLeadingSlash = (path: string) => {
  return path.startsWith('/') ? path : '/' + path
}

/**
 * Rewrite slug to exclude some storyblok prefixes, like landbased / corporate / hr prefixes.
 *
 * @param slug
 * @param storyblokFolder
 * @returns
 */
export const rewriteSlug = (
  slug: string,
  storyblokFolder: StoryBlokSiteFolder | 'all'
) => {
  let newSlug = ''

  // Remove /corporate, /hr, /landbased from the url
  const folderRegex =
    storyblokFolder === 'all' ? 'landbased|corporate|hr' : storyblokFolder
  const storyblokFolderRegex = new RegExp(`^/?((de|en)/?)?(${folderRegex})/`)
  newSlug = slug.replace(storyblokFolderRegex, '/$1')

  // Remove /home from the url
  newSlug = newSlug.replace(/^\/?(de|en)?(\/)?home/, '$1')

  // Home needs to be a single slash
  if (newSlug === '') {
    newSlug = '/'
  }

  return newSlug
}

/**
 * Removes duplicate slashes from a url path.
 * NOTE: Doesn't work with https://
 *
 * @param url Url to clean
 * @returns
 */
export const cleanURLPathSlashes = (url: string) => {
  return url.replace(/(\/)\/+/g, '$1')
}

/**
 * Replaces the storyblok cdn with the local static cdn.
 *
 * @param url The url to replace the storyblok cdn with the local static cdn
 * @returns
 */
export const replaceStoryblokCDN = (url: string | undefined) => {
  const siteUrl = SiteUtils.getSiteURL()

  if (!SiteUtils.isCorporate()) {
    // Only replace the storyblok cdn for corporate since the other sites
    // don't support the CDN yet
    return url || ''
  }

  if (!url) {
    return ''
  }

  return url.replaceAll('https://a.storyblok.com/', `${siteUrl}sb-static/`)
}

/**
 * Change url without navigating by changing state
 *
 * @param newPath string
 * @returns void
 */
export const changeUrlWithoutNavigate = (newPath: string) => {
  if (typeof window === 'undefined') {
    return
  }
  window.history.replaceState('', '', newPath || window.location.pathname)
}

/**
 * Slugify the given string
 *
 * @param input string
 * @returns the slugified string
 */
export const slugify = (input: string) => {
  return slugifyString(input, slugifyOptions)
}
