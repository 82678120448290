import styled, { css } from 'styled-components'

import { Icon } from '../icon'
import { HeadingTypes, ParagraphTypes } from '../../types/typography'

import { ButtonVariant } from './Button.types'

interface Props {
  variant?: ButtonVariant
  isReversed?: boolean
  isSmall?: boolean
  isLarge?: boolean
  isSquare?: boolean
  hasCircle?: boolean
  disabled?: boolean
  isStretched?: boolean

  [key: string]: unknown
}

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 2.4rem;
`

export const StyledContainer = styled.button<Props>`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  position: relative;
  background-color: ${({ theme }) =>
    theme.COMPONENTS.BUTTON_PRIMARY_BACKGROUND};
  color: ${({ theme }) => theme.COMPONENTS.BUTTON_PRIMARY_COLOR};
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
  font-weight: 900;
  padding: 0 2.4rem 0.2rem;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 4rem;
  text-decoration: none;
  transition: ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  ${({ isLarge }) =>
    isLarge &&
    css`
      height: 5.4rem;
    `};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    height: 5.4rem;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.COMPONENTS.BUTTON_PRIMARY_HOVER_BACKGROUND};
  }

  ${({ isReversed }) =>
    isReversed &&
    css`
      flex-direction: row-reverse;
    `};

  ${({ isSquare }) =>
    isSquare &&
    css`
      padding: 0;
      width: 4rem;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        width: 5.4rem;
      }
    `};

  ${({ isSquare, isSmall }) =>
    isSquare &&
    isSmall &&
    css`
      width: 3.2rem;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        width: 4rem;
      }
    `};

  ${({ isSmall }) =>
    isSmall &&
    css`
      ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_XS]};
      font-weight: 700;
      height: 3.2rem;

      ${StyledIcon} {
        height: 1.7rem;
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        height: 4rem;
      }
    `};

  ${({ isStretched }) =>
    isStretched &&
    css`
      width: 100%;
    `};

  ${({ variant, theme }) =>
    variant === 'dark' &&
    css`
      background-color: ${theme.COMPONENTS.BUTTON_DARK_BACKGROUND};
      color: ${theme.COMPONENTS.BUTTON_DARK_COLOR};

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_DARK_COLOR};
      }

      &:hover {
        background-color: ${theme.COMPONENTS.BUTTON_DARK_HOVER_BACKGROUND};
      }
    `};

  ${({ variant, theme }) =>
    variant === 'light' &&
    css`
      background-color: ${theme.COMPONENTS.BUTTON_LIGHT_BACKGROUND};
      border: 1px solid ${theme.COMPONENTS.BUTTON_LIGHT_BORDER_COLOR};
      color: ${theme.COMPONENTS.BUTTON_LIGHT_COLOR};

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_LIGHT_COLOR};
      }

      &:hover {
        background-color: ${theme.COMPONENTS.BUTTON_LIGHT_HOVER_BACKGROUND};
        color: ${theme.COMPONENTS.BUTTON_LIGHT_HOVER_COLOR};

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_LIGHT_HOVER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'ghost' &&
    css`
      background-color: transparent;
      color: ${theme.COMPONENTS.BUTTON_GHOST_COLOR};
      border: 1px solid ${theme.COMPONENTS.BUTTON_GHOST_BORDER_COLOR};

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_GHOST_COLOR};
      }

      &:hover {
        background-color: ${theme.COMPONENTS.BUTTON_GHOST_COLOR};
        border-color: ${theme.COMPONENTS.BUTTON_GHOST_COLOR};
        color: ${theme.COMPONENTS.BUTTON_GHOST_HOVER_COLOR};

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_GHOST_HOVER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'ghost-dark' &&
    css`
      background-color: transparent;
      color: ${theme.COMPONENTS.BODY_COLOR};
      border: 1px solid ${theme.COMPONENTS.BODY_COLOR};

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BODY_COLOR};
      }

      &:hover {
        background-color: ${theme.COMPONENTS.BODY_COLOR};
        border-color: ${theme.COMPONENTS.BODY_COLOR};
        color: white;

        ${StyledIcon} {
          fill: white;
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'ghost-dark-light' &&
    css`
      background-color: transparent;
      color: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_COLOR};
      border: 1px solid ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_BORDER_COLOR};

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_COLOR};
      }

      &:hover {
        background-color: ${theme.COMPONENTS
          .BUTTON_GHOST_LIGHT_DARK_HOVER_COLOR};
        border-color: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_HOVER_COLOR};
        color: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_BORDER_COLOR};

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_DARK_BORDER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'ghost-light' &&
    css`
      background-color: transparent;
      color: ${theme.COLOR.NEUTRAL_10};
      border: 1px solid ${theme.COLOR.NEUTRAL_10};

      ${StyledIcon} {
        fill: ${theme.COLOR.NEUTRAL_10};
      }

      &:hover {
        background-color: ${theme.COLOR.NEUTRAL_10};
        border-color: ${theme.COLOR.NEUTRAL_10};
        color: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_HOVER_COLOR};

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_GHOST_LIGHT_HOVER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'link' &&
    css`
      background-color: transparent;
      padding: 0;
      color: ${theme.COMPONENTS.BUTTON_LINK_COLOR};
      height: auto;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        height: auto;
      }

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_LINK_ICON_COLOR};
        color: ${theme.COMPONENTS.BUTTON_LINK_ICON_COLOR};
      }

      &:hover {
        color: ${theme.COMPONENTS.BUTTON_LINK_HOVER_COLOR};
        background-color: transparent;

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_LINK_ICON_HOVER_COLOR};
          color: ${theme.COMPONENTS.BUTTON_LINK_ICON_HOVER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    variant === 'link-light' &&
    css`
      background-color: transparent;
      padding: 0;
      color: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_COLOR};
      height: auto;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        height: auto;
      }

      ${StyledIcon} {
        fill: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_COLOR};
        color: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_COLOR};
      }

      &:hover {
        color: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_HOVER_COLOR};
        background-color: transparent;

        ${StyledIcon} {
          fill: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_HOVER_COLOR};
          color: ${theme.COMPONENTS.BUTTON_LINK_LIGHT_HOVER_COLOR};
        }
      }
    `};

  ${({ variant, theme }) =>
    (variant === 'inline' ||
      variant === 'inline-dark' ||
      variant === 'inline-light' ||
      variant === 'inline-secondary' ||
      variant === 'inline-tertiary') &&
    css`
      font-size: inherit;
      background-color: transparent;
      padding: 0;
      color: ${theme.COMPONENTS.BUTTON_INLINE_COLOR};
      text-decoration: underline;
      height: auto;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        height: auto;
      }

      &:hover {
        color: ${theme.COMPONENTS.BUTTON_INLINE_HOVER_COLOR};
        background-color: transparent;
      }

      ${variant === 'inline-dark' &&
      css`
        color: ${theme.COMPONENTS.BODY_COLOR};

        &:hover {
          color: ${theme.COLOR.NEUTRAL_100};
        }
      `}

      ${variant === 'inline-light' &&
      css`
        color: ${theme.COMPONENTS.BUTTON_INLINE_COLOR_LIGHT};

        &:hover {
          color: ${theme.COMPONENTS.BUTTON_INLINE_COLOR_LIGHT};
        }
      `}

      ${variant === 'inline-secondary' &&
      css`
        color: ${theme.COMPONENTS.BUTTON_INLINE_SECONDARY_COLOR};

        ${StyledIcon} {
          fill: ${({ theme }) =>
            theme.COMPONENTS.BUTTON_INLINE_SECONDARY_COLOR};
          color: ${({ theme }) =>
            theme.COMPONENTS.BUTTON_INLINE_SECONDARY_COLOR};
        }

        &:hover {
          color: ${theme.COMPONENTS.BUTTON_INLINE_SECONDARY_HOVER_COLOR};

          ${StyledIcon} {
            fill: ${({ theme }) =>
              theme.COMPONENTS.BUTTON_INLINE_SECONDARY_HOVER_COLOR};
            color: ${({ theme }) =>
              theme.COMPONENTS.BUTTON_INLINE_SECONDARY_HOVER_COLOR};
          }
        }
      `}

      ${variant === 'inline-tertiary' &&
      css`
        color: ${theme.COMPONENTS.BUTTON_INLINE_TERTIARY_COLOR};

        ${StyledIcon} {
          fill: ${({ theme }) => theme.COMPONENTS.BUTTON_INLINE_TERTIARY_COLOR};
          color: ${({ theme }) =>
            theme.COMPONENTS.BUTTON_INLINE_TERTIARY_COLOR};
        }

        &:hover {
          color: ${theme.COMPONENTS.BUTTON_INLINE_TERTIARY_HOVER_COLOR};

          ${StyledIcon} {
            fill: ${({ theme }) =>
              theme.COMPONENTS.BUTTON_INLINE_TERTIARY_HOVER_COLOR};
            color: ${({ theme }) =>
              theme.COMPONENTS.BUTTON_INLINE_TERTIARY_HOVER_COLOR};
          }
        }
      `}
    `};

  ${({ variant, theme }) =>
    variant === 'warning' &&
    css`
      background-color: transparent;
      padding: 0;
      color: ${theme.COLOR.WARNING};
      height: auto;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        height: auto;
      }

      &:hover {
        color: ${theme.COLOR.WARNING};
        background-color: transparent;
      }
    `};

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.COMPONENTS.BUTTON_SECONDARY_BACKGROUND};

      &:hover {
        background-color: ${theme.COMPONENTS.BUTTON_SECONDARY_HOVER_BACKGROUND};
      }
    `};

  ${({ variant, theme }) =>
    variant === 'tertiary' &&
    css`
      background-color: ${theme.COMPONENTS.BUTTON_TERTIARY_BACKGROUND};

      &:hover {
        background-color: ${theme.COMPONENTS.BUTTON_TERTIARY_HOVER_BACKGROUND};
      }
    `};

  ${({ disabled, variant, theme }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
      color: ${({ theme }) => theme.COMPONENTS.DISABLED_COLOR};
      pointer-events: none;

      ${variant !== 'link' &&
      variant !== 'warning' &&
      css`
        border: 1px solid ${theme.COMPONENTS.DISABLED_COLOR};
      `};

      ${StyledIcon} {
        fill: ${({ theme }) => theme.COMPONENTS.DISABLED_COLOR};
      }
    `};
`
