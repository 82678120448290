import styled from 'styled-components'
import Carousel from 'react-slick'

export const StyledCardWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2.4rem;
`

export const StyledCardList = styled.div`
  margin-top: 4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-top: 6.4rem;
  }
`

export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    padding-right: 1.6rem;
  }
`
