export interface MeIds {
  salesforce_id: string
}

export interface MePersonal {
  initials: string
  first_name: string
  last_name_prefixes: string
  last_name: string
  gender: 'Male' | 'Female'
  nationality: string
  date_of_birth: string
}

export interface MeAddress {
  street: string
  house_number: string
  house_number_suffixes: string
  postal_code: string
  city: string
  country_code: string
}

export interface MeContact {
  email_address: string
  mobile_number: string
}

export interface MeOptins {
  email_optin: boolean
  email_optin_can_update: boolean
  email_bounced: boolean
  postal_mail_optin: boolean
  postal_optin_can_update: boolean
  postal_mail_bounced: boolean
  sms_optin: boolean
  sms_optin_can_update: boolean
  mobile_phone_optin: boolean
  mobile_phone_optin_can_update: boolean
  bonus_optin: boolean
  bonus_optin_can_update: boolean
}

export interface MeIms {
  user_id: string
  signup_date: string
  status: string
  player_dormancy: string
  suspended: string
  frozen: string
}

export interface MeAccount {
  ims: MeIms
}

export interface MePerson {
  type: string
  subtype: string
  obsolete: string
  ids: MeIds
  personal: MePersonal
  address: MeAddress
  contact: MeContact
  optins: MeOptins
  account: MeAccount
}

export interface MeAuthenticatedResponse {
  person: MePerson
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}
