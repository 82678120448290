import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Paragraph } from '@hc/shared/components/typography'
import { Modal, Variant } from '@hc/shared/components/modal'
import { StyledFooter } from '@hc/shared/components/modal/Modal.style'
import { Button } from '@hc/shared/components/button'
import { ParagraphTypes } from '@hc/shared/types/typography'

interface PropsLogoutModal {
  isOpen: boolean
  onClose: (isLogout: boolean) => void
  isLoading: boolean
}

export const LogoutModal: FC<PropsLogoutModal> = ({
  isOpen,
  onClose,
  isLoading
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      title={t('navigation.logout')}
      variant={Variant.SMALL}
      onClose={() => {
        onClose(false)
      }}
    >
      <Paragraph type={ParagraphTypes.TEXT_M}>
        <Trans i18nKey="navigation.logoutModal.message" />
      </Paragraph>

      <StyledFooter isInline={true}>
        <Button
          label={t('navigation.logoutModal.no')}
          variant="dark"
          onClick={() => {
            onClose(false)
          }}
        />
        <Button
          label={t('navigation.logoutModal.yesLogout')}
          onClick={() => {
            onClose(true)
          }}
        />
      </StyledFooter>
    </Modal>
  )
}
