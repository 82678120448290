import React, { useCallback, useState } from 'react'

import { Modal } from './Modal'
import { Variant } from './Modal.style'

export interface ModalBodyProps<T> {
  modalState: T | null
  hideModal: () => void
}

interface ModalProps<T> {
  body: (props: ModalBodyProps<T | null>) => React.ReactNode
  variant?: Variant
  title: string
  onClose: () => void
  hasCloseButton?: boolean
}

export const useModal = <T,>({
  title,
  onClose,
  variant,
  body,
  hasCloseButton
}: ModalProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalState, setModalState] = useState<T | null>(null)

  const showModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const hideModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const ModalComponent = () => (
    <Modal
      isOpen={isModalOpen}
      {...{ title, onClose, variant, hasCloseButton }}
    >
      {body({
        hideModal,
        modalState
      })}
    </Modal>
  )

  return {
    isModalOpen,
    hideModal,
    showModal,
    setModalState,
    Modal: ModalComponent
  }
}
