import { COLOR } from '../basic/color'

export const COMPONENTS = {
  BODY_COLOR: COLOR.DARK_BLUE,
  PRIMARY_COLOR: COLOR.LIGHT_BLUE,
  OVERLAY_COLOR: COLOR.DARK_BLUE,
  IMAGE_OVERLAY_COLOR: COLOR.DARK_BLUE,
  HEADING_TEXT_TRANSFORM: 'uppercase',
  DISABLED_COLOR: COLOR.NEUTRAL_30,
  BUTTON_PRIMARY_BACKGROUND: COLOR.LIGHT_BLUE,
  BUTTON_PRIMARY_COLOR: COLOR.NEUTRAL_10,
  BUTTON_PRIMARY_HOVER_BACKGROUND: COLOR.MEDIUM_BLUE,
  BUTTON_DARK_BACKGROUND: COLOR.DARK_BLUE,
  BUTTON_DARK_COLOR: COLOR.NEUTRAL_10,
  BUTTON_DARK_HOVER_BACKGROUND: COLOR.NEUTRAL_100,
  BUTTON_LIGHT_BACKGROUND: COLOR.NEUTRAL_10,
  BUTTON_LIGHT_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_BORDER_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_HOVER_BACKGROUND: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_HOVER_COLOR: COLOR.NEUTRAL_10,
  BUTTON_GHOST_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_GHOST_BORDER_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_GHOST_HOVER_COLOR: COLOR.NEUTRAL_10,
  BUTTON_GHOST_LIGHT_DARK_COLOR: COLOR.DARK_BLUE,
  BUTTON_GHOST_LIGHT_DARK_BORDER_COLOR: COLOR.DARK_BLUE,
  BUTTON_GHOST_LIGHT_DARK_HOVER_COLOR: COLOR.NEUTRAL_10,
  BUTTON_GHOST_LIGHT_HOVER_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_SECONDARY_BACKGROUND: COLOR.GOLD,
  BUTTON_SECONDARY_HOVER_BACKGROUND: COLOR.MEDIUM_GOLD,
  BUTTON_TERTIARY_BACKGROUND: COLOR.PINK,
  BUTTON_TERTIARY_HOVER_BACKGROUND: COLOR.MEDIUM_PINK,
  BUTTON_LINK_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_LIGHT_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_LINK_LIGHT_HOVER_COLOR: COLOR.MEDIUM_BLUE,
  BUTTON_INLINE_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_INLINE_HOVER_COLOR: COLOR.MEDIUM_BLUE,
  BUTTON_INLINE_COLOR_LIGHT: COLOR.NEUTRAL_10,
  BUTTON_INLINE_SECONDARY_COLOR: COLOR.GOLD,
  BUTTON_INLINE_SECONDARY_HOVER_COLOR: COLOR.MEDIUM_GOLD,
  BUTTON_INLINE_TERTIARY_COLOR: COLOR.PINK,
  BUTTON_INLINE_TERTIARY_HOVER_COLOR: COLOR.MEDIUM_PINK,
  BUTTON_LINK_HOVER_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_ICON_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_ICON_HOVER_COLOR: COLOR.DARK_BLUE,
  INPUT_FOCUS_COLOR: COLOR.LIGHT_BLUE,
  INPUT_PLACEHOLDER_COLOR: COLOR.NEUTRAL_40,
  INPUT_BORDER_COLOR: COLOR.NEUTRAL_40,
  TEXT_BLOCK_TITLE_COLOR_PRIMARY: COLOR.DARK_BLUE,
  TEXT_BLOCK_TITLE_COLOR_TERTIARY: COLOR.NEUTRAL_10,
  CAROUSEL_DOT_COLOR_INACTIVE: COLOR.DARK_BLUE_30,
  CAROUSEL_DOT_COLOR_ACTIVE: COLOR.DARK_BLUE,
  BLOCK_BACKGROUND_SECONDARY: COLOR.BACKGROUND,
  BLOCK_BACKGROUND_TERTIARY: COLOR.LIGHT_BLUE,
  NEWS_CARD_CATEGORY_COLOR: COLOR.LIGHT_BLUE,
  FOOTER_BACKGROUND: COLOR.DARK_BLUE,
  ACCORDION_TITLE_COLOR: COLOR.LIGHT_BLUE,
  ACCORDION_ICON_COLOR: COLOR.DARK_BLUE,
  ACCORDION_ICON_COLOR_LIGHT: COLOR.LIGHT_BLUE,
  USP_TITLE_COLOR: COLOR.DARK_BLUE,
  USP_ICON_COLOR: COLOR.NEUTRAL_10,
  USP_ICON_BACKGROUND_COLOR: COLOR.DARK_BLUE,
  USP_ICON_BORDER_COLOR: COLOR.DARK_BLUE,
  CORNER_COLOR_PRIMARY: COLOR.LIGHT_BLUE,
  CORNER_COLOR_SECONDARY: COLOR.GOLD,
  CORNER_COLOR_TERTIARY: COLOR.PINK,
  BLOCK_TITLE_WEIGHT: '300',
  HERO_BACKGROUND: COLOR.DARK_BLUE,
  LOADER_COLOR: COLOR.LIGHT_BLUE,
  NOT_FOUND_CARD_COLOR: COLOR.DARK_BLUE,
  NOT_FOUND_CARD_COLOR_BUTTON: COLOR.LIGHT_BLUE,
  NOT_FOUND_CARD_COLOR_BUTTON_HOVER: COLOR.DARK_BLUE,
  BLOCKQUOTE_COLOR_PRIMARY: COLOR.LIGHT_BLUE,
  BLOCKQUOTE_COLOR_SECONDARY: COLOR.GOLD,
  BLOCKQUOTE_COLOR_TERTIARY: COLOR.PINK,
  NAVIGATION_TEXT_TRANSFORM: 'uppercase',
  NAVIGATION_COLOR_PRIMARY: COLOR.NEUTRAL_10,
  NAVIGATION_COLOR_SECONDARY: COLOR.DARK_BLUE
}

export type TComponentName = keyof typeof COMPONENTS
