import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconNames } from '@hc/shared/components/icon'
import { HeadingTypes } from '@hc/shared/types/typography'
import {
  ScrollDirection,
  useScrollDirection
} from '@hc/shared/hooks/use-scroll-direction'

import { MyHcNavigation } from '@/components/myhc-navigation'
import { MyHcHeaderContext } from '@/components/myhc-header/MyHcHeaderContext'
import { myhcNavItems } from '@/components/myhc-navigation/utils'

import {
  StyledHeaderContainer,
  StyledHeaderNavButton,
  StyledHeaderNavButtonIcon,
  StyledHeaderTitle
} from './MyHcHeader.style'

export const MyHcHeader = () => {
  const [isActive, setIsActive] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isFixed, setIsFixed] = useState(false)
  const scrollDirection = useScrollDirection()
  const { t } = useTranslation()

  const handleToggleClick = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive])

  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP) {
      setIsFixed(true)
    } else if (scrollDirection === ScrollDirection.DOWN) {
      setIsFixed(false)
    }
  }, [scrollDirection])

  return (
    <MyHcHeaderContext.Provider
      value={{
        isActive,
        setIsActive,
        activeIndex,
        setActiveIndex
      }}
    >
      <StyledHeaderContainer {...{ isFixed }}>
        <MyHcNavigation />
        <StyledHeaderNavButton
          isActive={isActive}
          aria-label="Open navigation"
          onClick={handleToggleClick}
        >
          <StyledHeaderNavButtonIcon
            hasCircle
            name={isActive ? IconNames.CROSS : IconNames.MENU}
          />
        </StyledHeaderNavButton>
        <StyledHeaderTitle type={HeadingTypes.DISPLAY_XXS}>
          {t(`myHC.navigation.${myhcNavItems[activeIndex].title}`)}
        </StyledHeaderTitle>
      </StyledHeaderContainer>
    </MyHcHeaderContext.Provider>
  )
}
