import { createSlice } from '@reduxjs/toolkit'

interface SessionState {
  isAuthenticated: boolean
  sessionValidated: boolean
  hasError: boolean
}

const initialState: SessionState = {
  isAuthenticated: false,
  sessionValidated: false,
  hasError: false
}

const sessionSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedIn: state => {
      state.isAuthenticated = true
      state.sessionValidated = true
    },
    userLoggedOut: state => {
      state.isAuthenticated = false
    },
    sessionError: state => {
      state.sessionValidated = true
      state.hasError = true
    }
  }
})

export const { userLoggedOut, userLoggedIn, sessionError } =
  sessionSlice.actions
export const sessionReducer = sessionSlice.reducer
