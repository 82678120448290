import { IconNames } from '../constants'
import { SvgComponent } from '../Icon.types'

type IconType = {
  [i in IconNames]: SvgComponent
}

/* eslint-disable @typescript-eslint/no-var-requires */
export const Icons: IconType = {
  [IconNames.CARET_DOWN]: require('./caret-down.svg').default,
  [IconNames.CARET_LEFT]: require('./caret-left.svg').default,
  [IconNames.CARET_RIGHT]: require('./caret-right.svg').default,
  [IconNames.CARET_UP]: require('./caret-up.svg').default,
  [IconNames.CARET_RIGHT_CIRCLE]: require('./caret-right-circle.svg').default,
  [IconNames.CHECK]: require('./check.svg').default,
  [IconNames.CART]: require('./cart.svg').default,
  [IconNames.CHIP]: require('./chip.svg').default,
  [IconNames.CLOVER]: require('./clover.svg').default,
  [IconNames.CROSS]: require('./cross.svg').default,
  [IconNames.DOWNLOAD]: require('./download.svg').default,
  [IconNames.FACEBOOK]: require('./facebook.svg').default,
  [IconNames.FILTER]: require('./filter.svg').default,
  [IconNames.FILTER_LINE]: require('./filter-line.svg').default,
  [IconNames.EYE]: require('./eye.svg').default,
  [IconNames.EYE_OFF]: require('./eye-off.svg').default,
  [IconNames.INFO]: require('./info.svg').default,
  [IconNames.INFO_OUTLINE]: require('./info-outline.svg').default,
  [IconNames.INSTAGRAM]: require('./instagram.svg').default,
  [IconNames.LINK]: require('./link.svg').default,
  [IconNames.LINKEDIN]: require('./linkedin.svg').default,
  [IconNames.LOADER]: require('./loader.svg').default,
  [IconNames.LOGO]: require('./logo.svg').default,
  [IconNames.MENU]: require('./menu.svg').default,
  [IconNames.MINUS]: require('./minus.svg').default,
  [IconNames.PLUS]: require('./plus.svg').default,
  [IconNames.SEARCH]: require('./search.svg').default,
  [IconNames.TWITTER]: require('./twitter.svg').default,
  [IconNames.USER]: require('./user.svg').default,
  [IconNames.YOUTUBE]: require('./youtube.svg').default,
  [IconNames.PLAY]: require('./play.svg').default,
  [IconNames.CARET_DOWN_THIN]: require('./caret-down-thin.svg').default,
  [IconNames.ALERT]: require(`./alert.svg`).default,
  [IconNames.ALERT_MINI]: require(`./alert-mini.svg`).default,
  [IconNames.DOTS]: require(`./dots.svg`).default,
  [IconNames.DOWNLOAD]: require(`./download.svg`).default,
  [IconNames.DOWNLOAD_2]: require(`./download-2.svg`).default,
  [IconNames.LINES]: require(`./lines.svg`).default,
  [IconNames.INFO_MINI]: require(`./info-mini.svg`).default,
  [IconNames.LEGAL_REPRESENTATION]: require(`./legal-representation.svg`)
    .default,
  [IconNames.LINK_THIN]: require(`./link-thin.svg`).default,
  [IconNames.LOCATION]: require(`./location.svg`).default,
  [IconNames.MAIL]: require(`./mail.svg`).default,
  [IconNames.MAIL_MINI]: require(`./mail-mini.svg`).default,
  [IconNames.NEWS]: require(`./news.svg`).default,
  [IconNames.ONLINE_CASINO]: require(`./online-casino.svg`).default,
  [IconNames.PHONE]: require(`./phone.svg`).default,
  [IconNames.PHONE_MINI]: require(`./phone-mini.svg`).default,
  [IconNames.RESULTS]: require(`./results.svg`).default,
  [IconNames.TELEPHONE]: require(`./telephone.svg`).default,
  [IconNames.UPLOAD]: require(`./upload.svg`).default,
  [IconNames.UPLOAD_2]: require(`./upload-2.svg`).default,
  [IconNames.VENUE]: require(`./venue.svg`).default,
  [IconNames.WEB]: require(`./web.svg`).default,
  [IconNames.PERCENTAGE_CIRCLE]: require(`./percentage-circle.svg`).default,
  [IconNames.WHATSAPP]: require(`./whatsapp.svg`).default
}
