import { StoryBlokSiteFolder } from '../types'

const getSite = (): StoryBlokSiteFolder => process.env.GATSBY_SITE

const isLandbased = () => getSite() === 'landbased'
const isCorporate = () => getSite() === 'corporate'
const isHR = () => getSite() === 'hr'

type Specifics<T> = {
  [site in StoryBlokSiteFolder]?: T
} & {
  default?: T
}

/**
 * Returns the value from the given specifics for
 * the current brand.
 *
 * @param specifics
 * @returns site specific properties
 */
const select = <T = string>(specifics: Specifics<T>) => {
  const specific = specifics[getSite()]

  if (specific === undefined && specifics.default) {
    return specifics.default
  }

  return specifics[getSite()]
}

const getSiteURL = () => process.env.GATSBY_SITE_URL || ''

export const SiteUtils = {
  isLandbased,
  isCorporate,
  isHR,
  select,
  getSiteURL
}
