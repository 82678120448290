import { useEffect, useState } from 'react'

export const useMounted = (callback?: () => void) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
    callback?.()
  }, [])

  return hasMounted
}
