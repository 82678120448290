import { HrefLink } from '../../../utils/types'
import { FetchedSocialLink } from '../models/components/types'

import mapLink from './map-link'

export interface ISocialLink {
  type: string
  link: HrefLink | null
}

const mapSocialLink = ({ type, link }: FetchedSocialLink): ISocialLink => {
  return {
    type,
    link: link && mapLink(link)
  }
}

export default mapSocialLink
