import { ISocialLink } from '../../domains/storyblok/mappers/map-social-link'
import { IconNames } from '../icon'
import { Link } from '../link'

import { SocialListContainer, SocialIcon } from './SocialList.styles'

export interface ISocialListProps {
  items: ISocialLink[]
}

export const SocialList: React.FC<ISocialListProps> = ({
  items = [],
  ...props
}) => {
  return (
    <SocialListContainer {...props}>
      {items.map(item => (
        <li key={item.type}>
          <Link link={item.link} aria-label={item.type}>
            <SocialIcon hasCircle name={item.type as IconNames} />
          </Link>
        </li>
      ))}
    </SocialListContainer>
  )
}
