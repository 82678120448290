import { SHARED_THEME } from '@hc/shared/theme'

export const COLOR = {
  ...SHARED_THEME.COLOR,
  GOLD_10: '#FBFAF7',
  GOLD_20: '#F0EBE0',
  GOLD_30: '#E6DDCB',
  GOLD_40: '#DACCB2',
  GOLD_50: '#CDBC98',
  GOLD_60: '#C0AB7E',
  GOLD_70_PRIMARY: '#B49A64',
  GOLD_80: '#968053',
  GOLD_90: '#786743',
  GOLD_100: '#5A4D32',
  BLUE_10: '#CDD3D5',
  BLUE_20: '#ACB5B9',
  BLUE_30: '#829197',
  BLUE_40: '#586C74',
  BLUE_50: '#2F4751',
  BLUE_60_PRIMARY: '#05222E',
  BLUE_70: '#041C26',
  BLUE_80: '#03171F',
  BLUE_90: '#021117',
  BLUE_100: '#020B0F',
  PURPLE_10: '#D4D1D5',
  PURPLE_20: '#B8B3B9',
  PURPLE_30: '#958D97',
  PURPLE_40: '#716774',
  PURPLE_50: '#4E4151',
  PURPLE_60_PRIMARY: '#2A1B2E',
  PURPLE_70: '#231726',
  PURPLE_80: '#1C121F',
  PURPLE_90: '#150E17',
  PURPLE_100: '#0E090F',
  PRS_STATUS_FULL: '#EF8D34',
  PRS_STATUS_AVAILABLE: '#5DC93A',
  PRS_STATUS_FEW_PLACES_AVAILABLE: '#FFFC53',
  PRS_STATUS_WAITINGLIST: '#408EEB',
  PRS_STATUS_CLOSED: '#D01717'
}
