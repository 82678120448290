import styled, { css } from 'styled-components'

type BreakPoints = {
  XXS?: number
  XS?: number
  SM?: number
  MD?: number
  LG?: number
}

interface LayoutContainerProps {
  fullWidth?: boolean
}

export const LayoutContainer = styled.div<LayoutContainerProps>(
  ({ theme: { LAYOUT }, fullWidth }) => {
    return css`
      width: 100%;
      margin: 0 auto;
      ${!fullWidth &&
      css`
        max-width: ${LAYOUT.LAYOUT_MAX_CONTAINER_WIDTH}px;
      `}

      ${Object.entries(LAYOUT.LAYOUT_BREAKPOINT).map(
        ([key, value]) => css`
          @media screen and (min-width: ${value}px) {
            padding-left: ${LAYOUT.LAYOUT_GRID_OFFSET[
              key as keyof BreakPoints
            ]}px;
            padding-right: ${LAYOUT.LAYOUT_GRID_OFFSET[
              key as keyof BreakPoints
            ]}px;
          }
        `
      )}
    `
  }
)

export const LayoutRow = styled.div(
  ({ theme: { LAYOUT } }) => css`
    display: flex;
    flex-wrap: wrap;

    ${Object.entries(LAYOUT.LAYOUT_BREAKPOINT).map(
      ([key, value]) => css`
        @media screen and (min-width: ${value}px) {
          margin: calc(
            -${LAYOUT.LAYOUT_GRID_GAP[key as keyof BreakPoints]}px / 2
          );
        }
      `
    )}
  `
)

interface StyledColumnProps {
  columns: BreakPoints
  offset: BreakPoints
}

export const StyledColumn = styled.div<StyledColumnProps>(
  ({ theme: { LAYOUT }, columns, offset }) => css`
    width: 100%;

    ${Object.entries(LAYOUT.LAYOUT_BREAKPOINT).map(
      ([key, value]) => css`
        @media screen and (min-width: ${value}px) {
          padding: calc(
            ${LAYOUT.LAYOUT_GRID_GAP[key as keyof BreakPoints]}px / 2
          );
          width: ${columns &&
          columns[key as keyof BreakPoints] &&
          `calc(100% / ${LAYOUT.LAYOUT_GRID_COLUMNS} * ${
            columns[key as keyof BreakPoints]
          })`};
          margin-left: ${offset &&
          offset[key as keyof BreakPoints] &&
          `calc(100% / ${LAYOUT.LAYOUT_GRID_COLUMNS} * ${
            offset[key as keyof BreakPoints]
          })`};
        }
      `
    )}
  `
)
