import { DateTime } from 'luxon'

export const isoToDateTime = (date: string) => {
  return DateTime.fromISO(date, {
    zone: 'Europe/Amsterdam'
  }).setLocale('nl')
}

export const getDateToday = () => {
  return DateTime.now().setLocale('nl').toFormat('y-MM-dd')
}

export const getDaysInPast = (days: number) => {
  return DateTime.now().minus({ days }).setLocale('nl').toFormat('y-MM-dd')
}

export const getYearsInPast = (years: number) => {
  return DateTime.now().minus({ years }).setLocale('nl').toFormat('y-MM-dd')
}

export const isValidDate = (date: string) => {
  // check if date is in yyyy-mm-dd format
  if (new Date(date).toString() === 'Invalid Date') {
    return false
  }
  return date.split('-').length === 3
}

export const getSplittedDate = (date: string) => {
  // Should be received in yyyy-mm-dd format
  if (isValidDate(date)) {
    const splittedDate = date.split('-')
    const year = splittedDate[0].replace(/^0/, '')
    const month = splittedDate[1].replace(/^0/, '')
    const day = splittedDate[2].replace(/^0/, '')

    return {
      year,
      month,
      day
    }
  }
}
