import { StyledContainer, StyledMain } from './SimpleLayout.styles'

interface SimpleLayoutProps {
  children?: React.ReactNode
}

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children }) => {
  return (
    <StyledContainer>
      <StyledMain>{children}</StyledMain>
    </StyledContainer>
  )
}
