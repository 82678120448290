import { DefaultTheme } from 'styled-components'

import { COLOR } from './basic/color'
import { TYPOGRAPHY } from './basic/typography'
import { LAYOUT } from './basic/layout'
import { Z_INDEX } from './z-index'
import { TRANSITION } from './transition'
import { BORDER_RADIUS } from './basic/border-radius'
import { FONT_SIZE } from './basic/font-size'
import { FONT } from './basic/font'
import { COMPONENTS } from './components'
import { SHADOW } from './basic/shadow'
import { ICONS } from './basic/icons'
import { GRADIENT } from './basic/gradient'

export const SHARED_THEME: DefaultTheme = {
  BORDER_RADIUS,
  COLOR,
  FONT,
  FONT_SIZE,
  GRADIENT,
  ICONS,
  TYPOGRAPHY,
  LAYOUT,
  Z_INDEX,
  TRANSITION,
  COMPONENTS,
  SHADOW
}
