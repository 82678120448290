// import { SECTION_NAMES } from './section-names'
// import { PAGE_TYPES } from './page-types'
// import { CONTENT_TYPES } from './content-types'
// import { DEFAULT_PAGE_TITLE } from './default-page-title'

const SITE_ROOT = '/'
const STORAGE_PREV_PAGE = 'prev_page'

export {
  // SECTION_NAMES,
  // PAGE_TYPES,
  // CONTENT_TYPES,
  // DEFAULT_PAGE_TITLE,
  STORAGE_PREV_PAGE,
  SITE_ROOT
}
