import { AxiosInstance } from 'axios'
import { HttpClient } from '@hc/shared/domains/api/http'

import { MyHcPersonalDetailPageFormDataType } from '@/components/myhc-personal-form/MyHcPersonalForm.types'

import {
  mapAuthenticatedPerson,
  mapAuthenticatedPersonFormData
} from './me.mappers'
import { PersonData } from './me.models'
import { MeAuthenticatedResponse } from './me.types'

class HCMeApi {
  public client: AxiosInstance

  constructor() {
    this.client = HttpClient.create({
      baseURL: 'v2/Me',
      requestConfig: {
        headers: {
          'X-API-KEY': process.env.GATSBY_ME_API_KEY || ''
        },
        withCredentials: true
      }
    })
  }

  async authenticatedPerson(): Promise<PersonData> {
    const { data } = await this.client.get<MeAuthenticatedResponse>(
      'Authenticated',
      {
        withCredentials: true
      }
    )
    return mapAuthenticatedPerson(data)
  }

  async updateAuthenticatedPerson(
    formData: MyHcPersonalDetailPageFormDataType
  ) {
    const { data } = await this.client.post(
      'Authenticated',
      mapAuthenticatedPersonFormData(formData),
      {
        withCredentials: true
      }
    )
    return data
  }
}

export const MeApi = new HCMeApi()
