import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { Heading } from '../typography'
import { HeadingTypes } from '../../types/typography'
import { IconNames } from '../icon'
import { Loader } from '../loader'
import { useMounted } from '../../hooks/use-mounted'

import {
  StyledContainer,
  StyledHeader,
  StyledModal,
  StyledOverlay,
  StyledButton,
  StyledIcon,
  StyledBody,
  StyledSmallHeading,
  Variant
} from './Modal.style'

export interface IModalProps {
  children: ReactNode
  title: string
  onClose: () => void
  isOpen?: boolean
  variant?: Variant
  isLoading?: boolean
  hasCloseButton?: boolean
}

export const Modal: React.FC<IModalProps> = ({
  children,
  title,
  onClose,
  isOpen,
  variant = Variant.LARGE,
  isLoading,
  hasCloseButton
}) => {
  const hasMounted = useMounted()

  if (!hasMounted) {
    // Component has to be mounted to avoid SSR sync issues
    // Since SSR can't access `document`
    return null
  }

  return createPortal(
    <StyledContainer isOpen={isOpen}>
      <StyledOverlay isOpen={isOpen} onClick={onClose} />
      <StyledModal isOpen={isOpen} variant={variant}>
        {variant === Variant.LARGE && (
          <StyledHeader>
            <Heading type={HeadingTypes.DISPLAY_XXS}>{title}</Heading>
          </StyledHeader>
        )}
        {hasCloseButton && (
          <StyledButton onClick={onClose} {...{ variant }}>
            <StyledIcon name={IconNames.CROSS} hasCircle {...{ variant }} />
          </StyledButton>
        )}

        <StyledBody variant={variant}>
          {!isLoading && (
            <>
              {variant === Variant.SMALL && (
                <StyledSmallHeading type={HeadingTypes.DISPLAY_M}>
                  {title}
                </StyledSmallHeading>
              )}
              {children}
            </>
          )}
          {isLoading && <Loader />}
        </StyledBody>
      </StyledModal>
    </StyledContainer>,
    document.body
  )
}
