import { HeadingTypes } from '../../types/typography'
import {
  RestaurantMenuItem,
  IRestaurantMenuItemProps
} from '../restaurant-menu-item'

import {
  StyledRestaurantMenuCategory,
  StyledHeading
} from './RestaurantMenuCategory.styles'

interface IRestaurantMenuCategoryProps {
  _uid: string
  title: string
  items: IRestaurantMenuItemProps[]
}

export const RestaurantMenuCategory: React.FC<IRestaurantMenuCategoryProps> = ({
  title,
  items
}) => {
  return (
    <StyledRestaurantMenuCategory>
      {title && (
        <StyledHeading type={HeadingTypes.DISPLAY_XS}>{title}</StyledHeading>
      )}
      {items &&
        items?.map(item => <RestaurantMenuItem {...item} key={item._uid} />)}
    </StyledRestaurantMenuCategory>
  )
}
