import styled, { css } from 'styled-components'

import { CornerPosition, CornerVariant } from './Corner.types'

interface IProps {
  variant: CornerVariant
  position: CornerPosition
  isSmall?: boolean
  hasTransparency?: boolean
}

const backgroundColors = {
  primary: css`
    ${({ theme }) =>
      `linear-gradient(-180deg, ${theme.COMPONENTS.CORNER_COLOR_PRIMARY}00 15.63%, ${theme.COMPONENTS.CORNER_COLOR_PRIMARY}89 100%)`}
  `,
  secondary: css`
    ${({ theme }) =>
      `linear-gradient(-180deg, ${theme.COMPONENTS.CORNER_COLOR_SECONDARY}00 1.04%, ${theme.COMPONENTS.CORNER_COLOR_SECONDARY} 100%)`}
  `,
  tertiary: css`
    ${({ theme }) =>
      `linear-gradient(-180deg, ${theme.COMPONENTS.CORNER_COLOR_TERTIARY}00 1.04%, ${theme.COMPONENTS.CORNER_COLOR_TERTIARY}87 100%)`}
  `,
  quaternary: css`
    ${({ theme }) => `
    linear-gradient(-180deg, ${theme.COMPONENTS.CORNER_COLOR_PRIMARY}00 12.8%, ${theme.COMPONENTS.CORNER_COLOR_PRIMARY}03 38.43%, ${theme.COMPONENTS.CORNER_COLOR_PRIMARY}35 87.8%)`}
  `
}

const backgroundPositions = {
  'left-top': 'rotate(180deg)',
  'left-bottom': 'scale(-1, 1)',
  'right-top': 'scale(1, -1)',
  'right-bottom': 'rotate(180deg), scale(-1, 1)'
}

export const StyledCorner = styled.div<IProps>`
  width: 26.4rem;
  height: 73.4rem;
  position: absolute;
  background: ${({ variant }) => backgroundColors[variant]};
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  transform: ${({ position }) => backgroundPositions[position]};
  pointer-events: none;

  ${({ position }) =>
    position &&
    position === 'left-top' &&
    css`
      left: 0;
      top: 0;
    `};

  ${({ position }) =>
    position &&
    position === 'left-bottom' &&
    css`
      left: 0;
      bottom: 0;
    `};

  ${({ position }) =>
    position &&
    position === 'right-top' &&
    css`
      right: 0;
      top: 0;
    `};

  ${({ position }) =>
    position &&
    position === 'right-bottom' &&
    css`
      right: 0;
      bottom: 0;
    `};

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 16.3rem;
      height: 42.7rem;
    `};

  ${({ hasTransparency }) =>
    hasTransparency &&
    css`
      opacity: 0.5;
    `};
`
