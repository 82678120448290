import mapHeader from '@hc/shared/domains/storyblok/mappers/map-header'
import { Footer } from '@hc/shared/components/footer'
import mapFooter from '@hc/shared/domains/storyblok/mappers/map-footer'
import { useLanguageRedirect } from '@hc/shared/hooks/use-language-redirect'
import { initStoryblokBridge } from '@hc/shared/domains/storyblok'
import { ContainerLayout } from '@hc/shared/layouts/container/ContainerLayout'
import { useHCTranslation } from '@hc/shared/hooks/use-hc-translation'

import { Header } from '@/components/header'
import { useGlobal } from '@/domains/storyblok/hooks'
import { useValidateSession } from '@/domains/ims/hooks'

initStoryblokBridge()

interface IAppLayoutProps {
  children?: React.ReactNode
}

export const AppLayout: React.FC<IAppLayoutProps> = ({ children }) => {
  useValidateSession()
  useLanguageRedirect()
  const { currentLanguage } = useHCTranslation()
  const globalData = useGlobal(currentLanguage)

  return (
    <ContainerLayout>
      <Header {...mapHeader(globalData?.content?.header[0])} />
      <main>{children}</main>
      <Footer {...mapFooter(globalData?.content?.footer[0])} />
    </ContainerLayout>
  )
}
