import { RichTextField } from '../../domains/storyblok/models/fields/types'
import { ParagraphTypes } from '../../types/typography'
import { SiteUtils } from '../../utils/site'

import { useRichContent } from './hooks/use-rich-text'
import { StyledContainer } from './RichText.style'

interface Props {
  content: RichTextField
  paragraphType?: ParagraphTypes
  isLight?: boolean
  theme?: string
}

export const RichText = ({
  content,
  theme = 'default',
  isLight,
  paragraphType
}: Props) => {
  const isLandbased = SiteUtils.isLandbased()
  const components = useRichContent(
    content,
    theme,
    paragraphType,
    isLight && !isLandbased
  )

  return <StyledContainer {...{ isLight }}>{components}</StyledContainer>
}
