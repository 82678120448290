import styled, { css } from 'styled-components'
import { ParagraphTypes } from '@hc/shared/types/typography'
import { Icon } from '@hc/shared/components/icon'
import { Link } from '@hc/shared/components/link'
import { Button } from '@hc/shared/components/button'
import { Heading } from '@hc/shared/components/typography'

interface Props {
  isActive?: boolean
  isAlternative?: boolean
}

export const StyledOverlay = styled.div<Props>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.COLOR.BLUE_90};
  opacity: 0;
  transition: opacity 0.2s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
  z-index: ${({ theme }) => theme.Z_INDEX.OVERLAY};

  ${({ isActive }) =>
    isActive &&
    css`
      bottom: 0;
      opacity: 0.4;
    `};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledNav = styled.nav<Props>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  position: fixed;
  z-index: ${({ theme }) => theme.Z_INDEX.NAVIGATION};

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS.L}rem
      ${({ theme }) => theme.BORDER_RADIUS.L}rem
      ${({ theme }) => theme.BORDER_RADIUS.XL}rem
      ${({ theme }) => theme.BORDER_RADIUS.XL}rem;
    top: 0;
    left: 0;
    transform: translateY(calc(-100vh - 1.6rem));
    transition: 0.2s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
    height: calc(100% - 1.6rem);
    max-height: calc(100% - 1.6rem);
    overflow: hidden;

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateY(0);
      `};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-left: auto;
    height: 100%;
    padding: 6.4rem 1.6rem 1.6rem;
    width: 26.6rem;
    border-right: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
  }
`

export const StyledLogoLink = styled(Link)`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: none;
  }
`

export const StyledLogo = styled(Icon)`
  height: 3.2rem;
  fill: ${({ theme }) => theme.COLOR.BLUE_90};
`

export const StyledHeading = styled(Heading)`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_20};
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-top: 5.6rem;
  }
`

export const StyledNavList = styled.ul`
  flex-grow: 1;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding: 1px;
  }
`

export const StyledNavItem = styled.li`
  display: block;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    & + & {
      border-top: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
    }
  }
`

export const StyledIcon = styled(Icon)<Props>`
  fill: ${({ theme }) => theme.COLOR.BLUE_90};
  width: 1.9rem;
  height: 1.9rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    fill: ${({ theme }) => theme.COLOR.BLUE_30};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      fill: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
    `}
`

export const StyledNavButton = styled(Link)<Props>`
  display: flex;
  align-items: baseline;
  gap: 1.6rem;
  width: 100%;
  padding: 2.4rem 3.2rem;
  ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_M]}
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  font-weight: 700;
  transition: ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  &:hover {
    color: ${({ theme }) => theme.COLOR.BLUE_100};

    ${StyledIcon} {
      fill: ${({ theme }) => theme.COLOR.BLUE_100};
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    border-radius: 2rem;
    color: ${({ theme }) => theme.COLOR.BLUE_30};
    gap: 3.2rem;
    padding: 1.6rem;
    margin: 2rem 0;
  }

  ${({ isAlternative }) =>
    isAlternative &&
    css`
      color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};

      ${StyledIcon} {
        fill: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        color: ${({ theme }) => theme.COLOR.BLUE_100};

        ${StyledIcon} {
          fill: ${({ theme }) => theme.COLOR.BLUE_100};
        }
      }
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLOR.GOLD_10};
      color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
      pointer-events: none;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
        color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};

        ${StyledIcon} {
          fill: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
        }
      }
    `};
`

export const StyledButton = styled(Button)`
  flex-shrink: 0;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    color: ${({ theme }) => theme.COLOR.BLUE_10};
    margin-bottom: 4rem;
  }
`
