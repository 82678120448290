import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { sessionReducer } from './slices/session.slice'

const rootReducer = combineReducers({
  session: sessionReducer
})

export const hcStore = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof hcStore.getState>
export type AppDispatch = typeof hcStore.dispatch
