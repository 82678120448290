import { HeadingTypes } from '../../types/typography'
import { HrefLink } from '../../utils/types'
import { RichTextField } from '../../domains/storyblok/models/fields/types'
import { Heading } from '../typography'
import { RichText } from '../rich-text'
import { SBImage, IStoryBlokImage } from '../sb-image'

import { StyledContainer, StyledImageContainer } from './Card.style'

export interface ICardProps {
  _uid: string
  image: IStoryBlokImage
  title: string
  description: RichTextField
  link: HrefLink | null
}

export const Card: React.FC<ICardProps> = ({ image, title, description }) => {
  return (
    <StyledContainer>
      <StyledImageContainer>
        <SBImage image={image} isCovered />
      </StyledImageContainer>
      <Heading type={HeadingTypes.DISPLAY_XXS}>{title}</Heading>
      <RichText content={description} />
    </StyledContainer>
  )
}
