import styled, { css } from 'styled-components'

interface IProps {
  hasSpacing?: boolean
}

export const StyledHr = styled.hr<IProps>`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_40};

  ${({ hasSpacing, theme }) =>
    hasSpacing &&
    css`
      margin: 4rem 0;

      @media screen and (min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        margin: 8rem 0;
      }
    `};
`
