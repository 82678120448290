// Unfortunately there are some issues with the bridge in build mode
// so had to come up with a custom solution:
//
// https://github.com/storyblok/storyblok-react/issues/113
// https://github.com/storyblok/storyblok-react/issues/156

import {
  StoryblokBridgeConfigV2,
  StoryblokBridgeV2,
  ISbStoryData,
  getStoryblokApi
} from 'gatsby-source-storyblok'
import { useEffect, useState } from 'react'

import { Locale } from '../../../utils/types'
import { getUrlParam } from '../../../utils/url'
import { isSBPreviewMode } from '../utils/storyblok'

import { getStoryblokBridge, USE_STORYBLOK_BRIDGE } from './getStoryblokBridge'

export const useEditableContent = (
  initialPage: ISbStoryData,
  bridgeOptions?: StoryblokBridgeConfigV2
) => {
  if (typeof initialPage.content === 'string')
    initialPage.content = JSON.parse(initialPage.content)

  const [page, setPage] = useState(initialPage)

  useEffect(() => {
    setPage(initialPage)
  }, [initialPage])

  useEffect(() => {
    let bridge: StoryblokBridgeV2
    ;(async () => {
      const fetchStory = (storyId?: string | null, language: Locale = 'nl') => {
        if (!storyId) {
          return
        }

        const storyblokApi = getStoryblokApi()
        if (!storyblokApi) {
          console.warn(`can't setup storyblok api (missing token?)`)
          return
        }
        // loading the draft version on initial enter of editor
        storyblokApi
          .get(`cdn/stories/${storyId}`, {
            version: 'draft',
            language,
            resolve_relations: bridgeOptions?.resolveRelations
          })
          .then(({ data }) => {
            setPage(data.story)
          })
          .catch(error => {
            console.error(error)
          })
      }

      if (USE_STORYBLOK_BRIDGE) {
        bridge = await getStoryblokBridge(bridgeOptions)

        bridge.on('input', event => {
          if (event) {
            setPage(event.story as ISbStoryData)
          }
        })

        bridge.on('enterEditmode', event => {
          const storyId = event?.storyId
          const storyLanguage = getUrlParam('_storyblok_lang') as Locale

          fetchStory(`${storyId}`, storyLanguage)
        })

        bridge.on(['change', 'published'], () => window.location.reload())
      } else if (isSBPreviewMode()) {
        const storyId = getUrlParam('_storyblok')
        const storyLanguage = getUrlParam('_storyblok_lang') as Locale

        fetchStory(storyId, storyLanguage)
      }
    })()

    return () => {
      // Unfortunately can't unbind the events due to Storyblok
    }
  }, [])

  return page
}
