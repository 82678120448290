import { useEffect, useState } from 'react'

export enum ScrollDirection {
  UP,
  DOWN
}

export const useScrollDirection = () => {
  const thresholdFromTop = 400
  const threshold = 40
  const [scrollDir, setScrollDir] = useState(ScrollDirection.DOWN)

  useEffect(() => {
    let previousScrollYPosition = window.scrollY

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold

    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0)

    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY

      if (
        scrolledMoreThanThreshold(currentScrollYPosition) &&
        currentScrollYPosition > thresholdFromTop
      ) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? ScrollDirection.DOWN
          : ScrollDirection.UP
        setScrollDir(newScrollDirection)
        previousScrollYPosition =
          currentScrollYPosition > 0 ? currentScrollYPosition : 0
      }

      if (currentScrollYPosition === 0) {
        setScrollDir(ScrollDirection.DOWN)
      }
    }

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection)

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return scrollDir
}
