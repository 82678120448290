import { HCStorage } from '../../../utils/storage'

import { isSBPreviewMode } from './storyblok'

export const TOKEN_STORAGE_KEY = 'hc-preview-access-token'

// @TODO: Cleanup later when we know that most users migrated to the new token storage
const getOldToken = () => {
  if (typeof localStorage === 'undefined') {
    return ''
  }

  const accessToken = localStorage.getItem(TOKEN_STORAGE_KEY) || ''

  // Migrate to new format
  if (accessToken) {
    setToken(accessToken)
  }

  return accessToken
}

const getToken = () => {
  const token = HCStorage.getItem<string>(TOKEN_STORAGE_KEY)
  return token !== null ? token : getOldToken()
}

const setToken = (token: string) => {
  return HCStorage.setItem(TOKEN_STORAGE_KEY, token)
}

const isPreviewAndHasNoPreviewToken = () => {
  const isPreviewMode = isSBPreviewMode()
  const previewAccessToken = getToken()

  return isPreviewMode && !previewAccessToken
}

export const SBPreview = {
  getToken,
  setToken,
  isPreviewAndHasNoPreviewToken
}
