import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  getCurrentLanguagePath,
  stringToLanguage,
  stringToLanguageUpper,
  getLocalisedPath
} from '../i18n/locales'

export const useHCTranslation = () => {
  const translation = useTranslation()
  const currentLanguage = stringToLanguage(translation.i18n.language)
  const currentLanguageUpper = stringToLanguageUpper(translation.i18n.language)
  const currentLanguagePath = getCurrentLanguagePath(translation.i18n.language)
  const toLocalisedPath = (path: string) => {
    return getLocalisedPath(translation.i18n.language, path)
  }

  return {
    ...translation,
    currentLanguage,
    currentLanguageUpper,
    currentLanguagePath,
    toLocalisedPath
  }
}
