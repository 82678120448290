import { useEffect } from 'react'

import { useHCTranslation } from './use-hc-translation'
import useLanguageSwitch from './use-language-switch'

export const useLanguageRedirect = () => {
  const { languageRedirect } = useLanguageSwitch()
  const { currentLanguage } = useHCTranslation()

  useEffect(() => {
    if (currentLanguage) {
      languageRedirect(currentLanguage)
    }
  }, [currentLanguage])
}
