import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@hc/shared/components/link'
import { Button } from '@hc/shared/components/button'
import { useIsLG, useIsSM } from '@hc/shared/hooks/use-media-query'
import { IconNames } from '@hc/shared/components/icon/'

import { LogoutModal } from '@/components/logout-modal'
import { useSession } from '@/store/selectors'
import { ROUTES } from '@/const/routes'
import { useLogout } from '@/domains/ims/hooks'

import {
  StyledButtonContainer,
  StyledButtonDropdown
} from './LoginButton.style'

interface LoginButtonProps {
  isActive: boolean
  isFixed: boolean
}

export const LoginButton: React.FC<LoginButtonProps> = ({
  isActive,
  isFixed
}) => {
  const session = useSession()
  const isLG = useIsLG()
  const isSM = useIsSM()
  const { t } = useTranslation()
  const labelTextLogin = isLG
    ? t('navigation.registerLogin')
    : t('navigation.login')
  const labelTextAccount = isSM ? t('navigation.account') : ''
  const variant = isActive || isFixed ? 'ghost-dark' : 'light'
  const [dropdown, setDropdown] = useState(false)
  const [logoutPopup, setLogoutPopup] = useState(false)
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)
  const { refetch: doLogout } = useLogout()

  useEffect(() => {
    setDropdown(false)
  }, [isFixed])

  const onCloseLogoutModal = (isLogout: boolean) => {
    if (!isLogout) {
      setLogoutPopup(false)
    } else {
      setIsLoadingLogout(true)
      doLogout()
    }
  }

  const handleToggleClick = () => {
    if (isLG) {
      setDropdown(!dropdown)
    }
  }

  if (session.isAuthenticated) {
    return (
      <StyledButtonContainer>
        <LogoutModal
          isLoading={isLoadingLogout}
          onClose={onCloseLogoutModal}
          isOpen={logoutPopup}
        />
        <Button
          variant={variant}
          icon={IconNames.USER}
          isReversed
          label={labelTextAccount}
          isSmall
          link={!isLG ? { href: ROUTES.MYHC.HOME } : undefined}
          onClick={handleToggleClick}
        />
        {dropdown && (
          <StyledButtonDropdown isFixed={isFixed} isActive={isActive}>
            <li>
              <Link link={{ href: ROUTES.MYHC.HOME }}>
                {t('navigation.myHC')}
              </Link>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropdown(false)
                  setLogoutPopup(true)
                }}
              >
                {t('navigation.logout')}
              </div>
            </li>
          </StyledButtonDropdown>
        )}
      </StyledButtonContainer>
    )
  }
  return (
    <Button
      link={{ href: '/login' }}
      variant={variant}
      label={labelTextLogin}
      isSmall
    />
  )
}
