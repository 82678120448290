import { mergePhoneNumberFields } from '@hc/shared/utils/formfields/phone'
import { setFullDateFromFormFields } from '@hc/shared/utils/formfields/date'

import { MyHcPersonalDetailPageFormDataType } from '@/components/myhc-personal-form/MyHcPersonalForm.types'

import { PersonData } from './me.models'
import { Gender, MeAuthenticatedResponse } from './me.types'

export const mapAuthenticatedPerson = (
  response: MeAuthenticatedResponse
): PersonData => {
  const person = response.person
  const personal = {
    ...person.address,
    ...person.personal,
    ...person.contact,
    ...person.optins
  }
  return {
    optins: {
      bonusOptin: personal.bonus_optin,
      bonusOptinCanUpdate: personal.bonus_optin_can_update,
      emailBounced: personal.email_bounced,
      emailOptin: personal.email_optin,
      emailOptinCanUpdate: personal.email_optin_can_update,
      mobilePhoneOptin: personal.mobile_phone_optin,
      mobilePhoneOptinCanUpdate: personal.mobile_phone_optin_can_update,
      postalMailBounced: personal.postal_mail_bounced,
      postalMailOptin: personal.postal_mail_optin,
      postalOptinCanUpdate: personal.postal_optin_can_update,
      smsOptin: personal.sms_optin,
      smsOptinCanUpdate: personal.sms_optin_can_update
    },
    personalDetails: {
      firstName: personal.first_name,
      initials: personal.initials,
      gender: personal.gender === 'Male' ? Gender.Male : Gender.Female,
      lastName: personal.last_name,
      dateOfBirth: personal.date_of_birth,
      lastNamePrefixes: personal.last_name_prefixes,
      city: personal.city,
      countryCode: personal.country_code,
      houseNumber: personal.house_number,
      postalCode: personal.postal_code,
      streetName: personal.street,
      emailAddress: personal.email_address,
      mobileNumber: personal.mobile_number,
      houseNumberSuffix: personal.house_number_suffixes
    }
  }
}

export const mapAuthenticatedPersonFormData = (
  formData: MyHcPersonalDetailPageFormDataType
) => {
  return {
    person: {
      personal: {
        initials: formData.initials,
        first_name: formData.firstName,
        last_name_prefixes: formData.lastNamePrefixes,
        last_name: formData.lastName,
        gender: formData.gender,
        date_of_birth: setFullDateFromFormFields(
          formData.year,
          formData.month,
          formData.day
        )
      },
      address: {
        street: formData.streetName,
        house_number: formData.houseNumber,
        house_number_suffixes: formData.houseNumberSuffix,
        postal_code: formData.postalCode,
        city: formData.city,
        country_code: formData.countryCode
      },
      contact: {
        email_address: formData.emailAddress,
        mobile_number: mergePhoneNumberFields(
          formData.mobileNumber,
          formData.mobileNumberPrefix
        )
      }
    }
  }
}
