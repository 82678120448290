import { useIsSM } from '../../hooks/use-media-query'
import { Card, ICardProps } from '../card'

import {
  StyledCardWrapper,
  StyledCarousel,
  StyledCardList
} from './CardList.styles'

export interface ICardListProps {
  cards: ICardProps[] | undefined
}

export const CardList: React.FC<ICardListProps> = ({
  cards
}: ICardListProps) => {
  const settings = {
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 2.2
  }

  const isSM = useIsSM()
  const CardWrapper = isSM ? StyledCardWrapper : StyledCarousel
  const CardElement = CardWrapper as keyof JSX.IntrinsicElements

  return (
    <StyledCardList>
      <CardElement {...settings}>
        {cards?.map(cardItem => (
          <Card {...cardItem} key={cardItem._uid} />
        ))}
      </CardElement>
    </StyledCardList>
  )
}
