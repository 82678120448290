import { ParagraphTypes } from '../../types/typography'
import { IconNames } from '../icon'

import { StyledIcon, StyledItem, StyledParagraph } from './UspList.styles'

export interface IUspListItemProps {
  _uid: string
  text: string
}

export interface IUspListProps {
  usps: IUspListItemProps[]
}

export const UspList: React.FC<IUspListProps> = ({ usps }) => {
  return (
    <ul>
      {usps?.map(usp => (
        <StyledItem key={usp._uid}>
          <StyledParagraph type={ParagraphTypes.TEXT_M}>
            <StyledIcon name={IconNames.CHECK} hasCircle />
            {usp.text}
          </StyledParagraph>
        </StyledItem>
      ))}
    </ul>
  )
}
