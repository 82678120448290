import { Locale, LocaleUpper } from '../../utils/types'
import { SiteUtils } from '../../utils/site'
import { getPagePathname, getPathnameLeadingSlash } from '../../utils/url'
import { ALL_LANGUAGES } from '../../const'

export const getSupportedLanguages = () => {
  return (
    SiteUtils.select({
      corporate: ['nl'],
      landbased: ['nl', 'de', 'en'],
      hr: ['nl']
    }) || ALL_LANGUAGES
  )
}

export const isMultiLanguage = () => {
  return getSupportedLanguages().length > 1
}

export const stringToLanguageUpper = (lang: string | null): LocaleUpper => {
  return stringToLanguage(lang).toUpperCase() as LocaleUpper
}

export const stringToLanguage = (lang: string | null): Locale => {
  if (lang === 'default') {
    return 'nl'
  }

  if (!lang || !getSupportedLanguages()?.includes(lang as Locale)) {
    throw new Error(`Unsupported language: ${lang}`)
  }

  return lang as Locale
}

export const getCurrentLanguagePath = (lang: string) => {
  return lang === 'nl' ? '' : `/${lang}`
}

export const getPathWithoutLanguage = (lang: string, path?: string) => {
  if (typeof window === 'undefined') {
    return ''
  }

  const langPath = getCurrentLanguagePath(lang)
  const pathName = path || getPagePathname(true) || '/'

  if (langPath && pathName.startsWith(langPath)) {
    return pathName.replace(langPath, '')
  }

  return pathName
}

type LanguageKeys = 'languages.nl' | 'languages.de' | 'languages.en'

export const getFullTranslatedStringFromLocale = (
  lang: string
): LanguageKeys => {
  // t('languages.en')
  // t('languages.de')
  // t('languages.nl')
  switch (lang) {
    case 'en':
      return 'languages.en'
    case 'de':
      return 'languages.de'
    case 'nl':
    default:
      return 'languages.nl'
  }
}

export const getLocalisedPath = (lang: string, path: string) => {
  return getPathnameLeadingSlash(getCurrentLanguagePath(lang) + path)
}
