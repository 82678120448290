import { Locale } from '@hc/shared/utils/types'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocalisedStory } from '@hc/shared/hooks/use-localised-story'
import { GlobalStory } from '@hc/shared/domains/storyblok/models/components/types'

export const useGlobal = (locale: Locale) => {
  const data: Queries.GlobalQuery = useStaticQuery(graphql`
    query Global {
      allStoryblokEntry(
        filter: {
          field_component: { eq: "global" }
          full_slug: { regex: "/landbased/templates/" }
        }
      ) {
        edges {
          node {
            full_slug
            lang
            content
          }
        }
      }
    }
  `)

  const { getStory } = useLocalisedStory<Queries.GlobalQuery>(data)

  return getStory<GlobalStory>(locale)
}
