import { DateTime } from 'luxon'

export enum PokerGameType {
  Cashgame = 'CashGame',
  Tournament = 'Tournament',
  Unknown = 'Unknown'
}

export enum ReservationGameTypes {
  Cashgame = 'Cash Game',
  Tournament = 'Tournament',
  Unknown = 'Unknown'
}

export enum PokerGameStatus {
  Full,
  Available,
  FewPlacesAvailable,
  WaitingList,
  RegistrationClosed,
  Unknown
}

export interface PokerGameBase {
  gameId: number
  venue: string
  label: string
  type: PokerGameType
  startDate: DateTime
  startTime: DateTime
  status: PokerGameStatus
  entryFee: string
  allowBooking: boolean
  priceInCents: number
}

export interface PokerGame extends PokerGameBase {
  serie: string
  buyInMin: number
  buyInMax: number
}

export interface BettingScheduleLevel {
  ante: string
  bigBlind: string
  bigBlindAnte: string
  level: number
  smallBlind: string
}

export interface BettingSchedule {
  name: string
  isBigBlindAnte: boolean
  levels: BettingScheduleLevel[]
}

export interface PokerGameSingle extends PokerGameBase {
  event: string
  gameType: string
  limit: string
  variant: string
  bettingSchedule: BettingSchedule
  levelDuration: string
  totalAmount: number
  buyIn: string
  startStack: string
  dinnerBreak: string
  tournamentDays: number
  rebuy: string
  addon: string
  reEntry: string
  alternateList: string
  serieName: string
  totalSeats: number
}

export interface PokerGames {
  games: PokerGame[]
}

export interface PokerReservation {
  label: string
  type: string
  gameId: number
  venue: string
  buyInMin: number
  buyInMax: number
  entryFee: string
  allowCancel: boolean
  reservationStatus: string
  reservationId: number
  startDate: DateTime
  startTime: DateTime
}
