import { createContext, SetStateAction } from 'react'

export const MyHcHeaderContext = createContext({
  isActive: false,
  setIsActive: () => undefined,
  activeIndex: 0,
  setActiveIndex: () => undefined
} as {
  isActive: boolean
  setIsActive: (value: SetStateAction<boolean>) => void
  activeIndex: number
  setActiveIndex: (value: SetStateAction<number>) => void
})
