import { createGlobalStyle } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import '@hc/shared/src/theme/font-face/fonts.css'

// Keep this file as small as possible.
export const SharedGlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.6rem;
  color: ${({ theme }) => theme.COMPONENTS.BODY_COLOR};
  width: 100%;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
`
