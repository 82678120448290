import styled, { css } from 'styled-components'
import { ParagraphTypes } from '@hc/shared/types/typography'

export const StyledButtonContainer = styled.div`
  position: relative;
`
interface IPropsButtonDropdown {
  isFixed?: boolean
  isActive?: boolean
}

export const StyledButtonDropdown = styled.ul<IPropsButtonDropdown>`
  position: absolute;
  right: 0px;
  top: ${({ isFixed }) => (isFixed ? '6' : '5')}rem;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
  background-color: white;
  width: 100%;
  padding: 0.8rem 0;
  min-width: 10rem;

  ${({ isActive }) =>
    isActive &&
    css`
      width: calc(100vw - 2rem);
      right: -1.8rem;
      border-bottom: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
      border-radius: 0;
    `}

  li {
    padding: 0.3rem 1rem;
    display: block;

    a {
      color: ${({ theme }) => theme.COLOR.BLUE_100};
    }

    a,
    div {
      display: block;
      padding: 0.6rem 0.3rem;
      text-align: center;
      ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_S]};

      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
      }
    }

    div {
      color: ${({ theme }) => theme.COLOR.BLUE_10};
    }
  }
`
