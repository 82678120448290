import { IconNames } from '@hc/shared/components/icon'
import { useLanguageRedirect } from '@hc/shared/hooks/use-language-redirect'
import { useHCTranslation } from '@hc/shared/hooks/use-hc-translation'

import { LoginHero } from '@/components/login-hero'
import { SITE_ROOT } from '@/const'
import { useGlobal } from '@/domains/storyblok/hooks'
import { useValidateSession } from '@/domains/ims/hooks'

import {
  StyledContainer,
  StyledSideHeroWrapper,
  StyledLogoLink,
  StyledLogo,
  StyledHeroImg,
  StyledContentWrapper
} from './authentication.styles'

interface ILoginLayoutProps {
  children?: React.ReactNode
}

export const AuthenticationLayout: React.FC<ILoginLayoutProps> = ({
  children
}) => {
  useLanguageRedirect()
  useValidateSession()
  const { currentLanguage } = useHCTranslation()
  const globalData = useGlobal(currentLanguage)

  return (
    <StyledContainer>
      <StyledSideHeroWrapper>
        <StyledHeroImg>
          <LoginHero image={globalData?.content?.loginhero} />
        </StyledHeroImg>
        <StyledLogoLink link={{ href: SITE_ROOT }} aria-label="Home">
          <StyledLogo name={IconNames.LOGO} />
        </StyledLogoLink>
      </StyledSideHeroWrapper>
      <StyledContentWrapper>
        <main>{children}</main>
      </StyledContentWrapper>
    </StyledContainer>
  )
}
