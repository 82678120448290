import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { useEffect } from 'react'
import { ConsoleLogger } from '@hc/shared/utils/logger'

import { CartApi, IMSApi } from '@/domains/api'
import { IMSCreateAccountResponse } from '@/domains/api/ims/ims.api.types'
import {
  IMSAccount,
  IMSCreateAccountError
} from '@/domains/api/ims/ims.api.models'
import { useSession } from '@/store/selectors'
import { useAppDispatch } from '@/store/hooks'
import { sessionError, userLoggedIn } from '@/store/slices/session.slice'

import { IMShadowJWTResponse } from '../api/imshadow/imshadow.types'

import { AuthClient } from './auth'
import { PASApi } from './pasApi'
import { PASLoginResponse } from './pas.types'
import { LoginErrors } from './auth.types'

interface ValidateSessionOptions {
  onSuccess?: () => void
  onError?: () => void
}

const logger = new ConsoleLogger({
  prefix: '[SESSION]',
  prefixColor: '#43A047'
})

export const useCreateAccount = (
  options?: UseMutationOptions<
    IMSCreateAccountResponse,
    IMSCreateAccountError,
    IMSAccount
  >
) => {
  return useMutation((account: IMSAccount) => IMSApi.createAccount(account), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(options as any)
  })
}

export const useValidateSession = (options?: ValidateSessionOptions) => {
  const session = useSession()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Initialize the session
    const initSession = async () => {
      if (!session.isAuthenticated) {
        try {
          await AuthClient.getSession()
          dispatch(userLoggedIn())

          logger.log('Successfully setup session')
          options?.onSuccess?.()
        } catch (e) {
          // @TODO: Logout the user?
          logger.log('Failed to setup session', (e as Error).message)
          dispatch(sessionError())
          options?.onError?.()
        }
      } else {
        logger.log('Already logged in')
        options?.onSuccess?.()
      }
    }

    initSession()
  }, [])

  return []
}

const QUERY_KEY_LOGOUT = 'logout'

export const useLogout = (
  options?: Omit<UseQueryOptions<boolean>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()

  return useQuery<boolean>({
    queryKey: [QUERY_KEY_LOGOUT],
    queryFn: () => AuthClient.logout(),
    cacheTime: 0,
    enabled: false,
    onSettled: (data, error) => {
      logger.log('LOGOUT', 'Invalidating queries')
      // Remove all react query cache
      queryClient.resetQueries()

      // Make sure to clean up cart data
      CartApi.reset()
      options?.onSettled?.(data, error)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(options as any)
  })
}

export const useAcceptTC = (
  options?: UseMutationOptions<PASLoginResponse, Error>
) => {
  return useMutation((tcVersion: string) => PASApi.acceptTC(tcVersion), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(options as any)
  })
}

interface ChangePasswordArguments {
  oldPassword: string
  newPassword: string
}

export const useChangePassword = (
  options?: UseMutationOptions<PASLoginResponse, Error, ChangePasswordArguments>
) => {
  return useMutation<PASLoginResponse, Error, ChangePasswordArguments>(
    ({ oldPassword, newPassword }) =>
      PASApi.validatePasswordChange(oldPassword, newPassword),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any)
    }
  )
}

export const useLoginWithToken = (
  options?: UseMutationOptions<PASLoginResponse, Error, string>
) => {
  return useMutation<PASLoginResponse, Error, string>(
    (token: string) => PASApi.loginWithCrypoToken(token),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any)
    }
  )
}

interface LoginParameters {
  username: string
  password: string
}

export const useLogin = (
  options?: UseMutationOptions<
    IMShadowJWTResponse,
    LoginErrors,
    LoginParameters
  >
) => {
  const dispatch = useAppDispatch()

  return useMutation<IMShadowJWTResponse, LoginErrors, LoginParameters>(
    ({ username, password }: LoginParameters) =>
      AuthClient.login(username, password),
    {
      onSuccess: (data, variables, context) => {
        dispatch(userLoggedIn())
        options?.onSuccess?.(data, variables, context)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any)
    }
  )
}
