import styled, { css } from 'styled-components'
import { Icon } from '@hc/shared/components/icon'
import { Link } from '@hc/shared/components/link'

interface Props {
  isActive?: boolean
  isFixed?: boolean
  showPreviewTokenMessage?: boolean
}

export const StyledHeader = styled.header<Props>`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};

  ${({ showPreviewTokenMessage }) =>
    showPreviewTokenMessage &&
    css`
      margin-top: 3.8rem;
    `};

  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
    `};
`

export const StyledHeaderInner = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.4rem;
  margin-top: 0.8rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  padding: 0 1.6rem;

  ${({ isFixed }) =>
    isFixed &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10}eb;
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
      border: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_20}eb;
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
      border: 1px solid ${({ theme }) => theme.COLOR.BLUE_20}20;
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.L}rem
        ${({ theme }) => theme.BORDER_RADIUS.L}rem 0 0;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem
          ${({ theme }) => theme.BORDER_RADIUS.S}rem 0 0;
      }
    `};

  @media screen and (min-width: ${({ theme }) =>
    theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    justify-content: flex-start;
    margin-top: 2.4rem;

  }
}
`

export const StyledLogoLink = styled(Link)`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const StyledLogo = styled(Icon)<Props>`
  height: 4rem;
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};

  ${({ isActive }) =>
    isActive &&
    css`
      fill: ${({ theme }) => theme.COLOR.BLUE_90};
    `};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    height: 3.2rem;
    margin-right: 2.4rem;
  }
`

interface NavButtonProps {
  isActive: boolean
}

export const NavButton = styled.button<NavButtonProps>`
  margin-right: 2.4rem;
  border: none;
  background: transparent;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const NavButtonIcon = styled(Icon)<Props>`
  fill: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
  color: ${({ theme }) => theme.COLOR.GOLD_70_PRIMARY};
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 3.2rem;
  padding: 1rem 0.8rem;

  ${({ isActive }) =>
    isActive &&
    css`
      fill: ${({ theme }) => theme.COLOR.BLUE_90};
      color: ${({ theme }) => theme.COLOR.BLUE_90};
      padding: 1rem;
    `};
`

export const StyledOverlay = styled.div<Props>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.COLOR.BLUE_90};
  opacity: 0;
  transition: opacity 0.2s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
  z-index: ${({ theme }) => theme.Z_INDEX.OVERLAY};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    background-color: transparent;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      bottom: 0;
      opacity: 0.4;
    `};
`
