import { useStaticQuery, graphql } from 'gatsby'
import { IStoryBlokImage } from '@hc/shared/components/sb-image'
import { stringToLanguage } from '@hc/shared/i18n/locales'
import { useHCTranslation } from '@hc/shared/hooks/use-hc-translation'
import { FetchedVenue } from '@hc/shared/domains/storyblok/models/blocks/types'

interface GameContent {
  title: string
  description?: string
  image: IStoryBlokImage
  venues: string[]
  hideFromCarousel: boolean
}

interface localizedGames {
  nl: GameContent[]
  de: GameContent[]
  en: GameContent[]
}

export const mapLocalisedGames = (
  gamesData: Queries.GamesQuery
): localizedGames => {
  const localisedGames: localizedGames = {
    nl: [],
    en: [],
    de: []
  }

  gamesData.games.edges.forEach(gameItem => {
    const game = gameItem.node
    const gameContent = game.content ? JSON.parse(game.content) : null

    if (gameContent?.name && gameContent?.image) {
      const venueCodes = gameContent.venue.map(
        (venue: FetchedVenue) => venue?.content?.siteCode
      )

      localisedGames[stringToLanguage(game.lang)].push({
        title: gameContent.name,
        description: gameContent?.description,
        image: gameContent.image,
        venues: venueCodes,
        hideFromCarousel:
          gameContent?.hideFromCarousel || !!gameContent?.hideFromCarousel
      })
    }
  })

  return localisedGames
}

export const mapGamesForCarousel = (games: GameContent[], venue: string) => {
  return games.filter(
    game =>
      (!venue || !game.venues.length || game.venues.includes(venue)) &&
      !game.hideFromCarousel
  )
}

export const useGames = () => {
  const data = useStaticQuery(graphql`
    query Games {
      games: allStoryblokEntry(
        sort: { position: ASC }
        filter: { field_component: { eq: "game" } }
      ) {
        edges {
          node {
            name
            uuid
            content
            field_component
            position
            lang
            slug
          }
        }
      }
    }
  `)
  const localizedGames = mapLocalisedGames(data)
  const { currentLanguage } = useHCTranslation()

  const getGamesForCarousel = (venue: string) => {
    // if no venue is set for the carousel, show all games
    // else only show games with overlapping venue or no venue selected
    return mapGamesForCarousel(localizedGames[currentLanguage], venue)
  }

  return { getGamesForCarousel }
}
