import styled, { css } from 'styled-components'

import { BlockVariant } from './Block.types'

interface Props {
  hasGap?: boolean
  variant?: BlockVariant
}

export const StyledBlock = styled.div<Props>`
  margin: 7.2rem 0;

  ${({ hasGap }) =>
    hasGap &&
    css`
      display: flex;
      flex-direction: column;
      gap: 4rem;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        gap: 8rem;
      }
    `};

  ${({ variant }) =>
    variant &&
    variant !== 'primary' &&
    css`
      padding: 7.2rem 0;
      margin: 0;
    `};

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background: ${theme.COMPONENTS.BLOCK_BACKGROUND_SECONDARY};
    `};

  ${({ variant, theme }) =>
    variant === 'tertiary' &&
    css`
      color: ${theme.COLOR.NEUTRAL_10};
      background: ${theme.COMPONENTS.BLOCK_BACKGROUND_TERTIARY};
    `};
`
