import { useHCTranslation } from '../../../hooks/use-hc-translation'
import { stringToLanguage } from '../../../i18n/locales'
import { IStoryBlokImage } from '../../../components/sb-image'
import { Locale } from '../../../utils/types'

interface VenueContent {
  name?: string
  siteCode?: string
  _uid?: string
  image: IStoryBlokImage
  slug: string
  street: string
  houseNumber: string
  houseNumberSuffix?: string
  postalCode: string
  city: string
}

export interface Venue {
  name: string | null
  siteCode: string
  uid: string
  image: IStoryBlokImage
  link: string
  street: string
  houseNumber: string
  houseNumberSuffix?: string
  postalCode: string
  city: string
}

interface LocalisedVenues {
  nl: Venue[]
  en: Venue[]
  de: Venue[]
}

export const getVenueBySiteCode = (
  locale: Locale,
  localisedVenues: LocalisedVenues,
  siteCode: string
) => {
  const venues = getVenuesByLocale(locale, localisedVenues)
  return venues.find(venue => venue.siteCode === siteCode)
}

export const getVenuesByLocale = (
  locale: Locale,
  localisedVenues: LocalisedVenues
) => {
  return localisedVenues[locale]
}

const getVenueByUid = (
  locale: Locale,
  localisedVenues: LocalisedVenues,
  uid?: string
) => {
  const venues = localisedVenues[locale]
  return venues.find(venue => venue.uid === uid)
}

const getVenuesByUids = (
  locale: Locale,
  localisedVenues: LocalisedVenues,
  uids: string[]
) => {
  const venues = localisedVenues[locale]
  return venues.filter(venue => uids?.includes(venue.uid))
}

export const mapLocalisedVenues = (
  venueData: Queries.VenuesQuery
): LocalisedVenues => {
  // Each Venue can be localised, return all grouped by locale
  const localisedVenues: LocalisedVenues = {
    nl: [],
    en: [],
    de: []
  }

  venueData.venues.edges.forEach(venueItem => {
    const venue = venueItem.node
    const venueContent: VenueContent = venue.content
      ? JSON.parse(venue.content)
      : null

    // Not supporting incomplete venues
    if (venueContent?.name && venueContent?.siteCode) {
      localisedVenues[stringToLanguage(venue.lang)].push({
        name: venueContent.name,
        siteCode: venueContent.siteCode,
        uid: venue?.uuid || '',
        image: venueContent.image,
        link: `/vestigingen/${venue?.slug}`,
        street: venueContent.street,
        houseNumber: venueContent.houseNumber,
        houseNumberSuffix: venueContent.houseNumberSuffix,
        postalCode: venueContent.postalCode,
        city: venueContent.city
      })
    }
  })

  return localisedVenues
}

export interface UseVenues {
  localisedVenues: LocalisedVenues
  getVenues: (locale?: Locale) => Venue[]
  getVenue: (siteCode: string, locale?: Locale) => Venue | undefined
  getVenueByStoryBlokId: (uid: string, locale?: Locale) => Venue | undefined
  getVenuesByStoryBlokIds: (
    uids: string[],
    locale?: Locale
  ) => Venue[] | undefined
}

/**
 * Returns all venues from Storyblok
 *
 * localisedVenues: All venues grouped by locale
 * getVenues: Get venues by locale
 * getVenue: Get a specific venue by locale and siteCode
 */
export const useVenuesShared = (data: Queries.VenuesQuery): UseVenues => {
  const localisedVenues = mapLocalisedVenues(data)
  const { currentLanguage } = useHCTranslation()

  const getVenues = (locale?: Locale) => {
    const currentLocale = locale || currentLanguage
    return getVenuesByLocale(currentLocale, localisedVenues)
  }

  const getVenue = (siteCode: string, locale?: Locale) => {
    const currentLocale = locale || currentLanguage
    return getVenueBySiteCode(currentLocale, localisedVenues, siteCode)
  }

  const getVenueByStoryBlokId = (uid?: string, locale?: Locale) => {
    const currentLocale = locale || currentLanguage
    return getVenueByUid(currentLocale, localisedVenues, uid)
  }

  const getVenuesByStoryBlokIds = (uids: string[], locale?: Locale) => {
    const currentLocale = locale || currentLanguage
    return getVenuesByUids(currentLocale, localisedVenues, uids)
  }

  return {
    localisedVenues,
    getVenues,
    getVenue,
    getVenueByStoryBlokId,
    getVenuesByStoryBlokIds
  }
}
