import { useContext } from 'react'
import { useTheme } from 'styled-components'

import type { TBreakPoints, TBreakPointEntries } from '../../utils/types'

import ColumnContext, { IColumnContext } from './ColumnContext'
import { StyledColumn } from './Column.styles'

const calculateMaximumContainerWidth = (
  nextBreakpointValue: number,
  maxContainerWidth: number,
  layoutGridOffset: number
) =>
  Math.min(
    maxContainerWidth,
    (nextBreakpointValue || maxContainerWidth) - layoutGridOffset * 2
  )

const getNextBreakpointWidth = (
  breakpointsEntries: TBreakPointEntries,
  idx: number
) => {
  const nextBreakpoint = breakpointsEntries[idx + 1]
  return nextBreakpoint && nextBreakpoint[1] - 1
}

const calculateMaximumColumnWidth = (
  maximumContainerWidth: number,
  gridGap: number,
  totalColumns: number,
  columns: number
) =>
  Math.floor(
    ((maximumContainerWidth + gridGap) / totalColumns) * columns - gridGap
  )

export interface ILayoutProps {
  columns: TBreakPoints
  offset?: TBreakPoints
  [key: string]: unknown
}

export const LayoutColumn = ({
  columns = {
    XXS: 12,
    XS: 12,
    SM: 12,
    MD: 12,
    LG: 12
  },
  offset = {},
  ...others
}: ILayoutProps): JSX.Element => {
  const columnContext = useContext(ColumnContext)

  const {
    LAYOUT: {
      LAYOUT_BREAKPOINT,
      LAYOUT_MAX_CONTAINER_WIDTH,
      LAYOUT_GRID_OFFSET,
      LAYOUT_GRID_GAP,
      LAYOUT_GRID_COLUMNS
    }
  } = useTheme()

  const breakpointsEntries: TBreakPointEntries = Object.entries(
    LAYOUT_BREAKPOINT
  ) as TBreakPointEntries

  const reducedColumns: TBreakPoints = Object.keys(LAYOUT_BREAKPOINT).reduce(
    (accumulator, key, idx) => {
      return {
        ...accumulator,
        [key]:
          columns[key as keyof TBreakPoints] ||
          Object.values(accumulator)[idx - 1] ||
          LAYOUT_GRID_COLUMNS
      }
    },
    {}
  ) as TBreakPoints

  const columnContextValue: IColumnContext = breakpointsEntries.reduce(
    (accumulator, [key, value], idx) => {
      const nextBreakpointWidth = getNextBreakpointWidth(
        breakpointsEntries,
        idx
      )
      const maximumContainerWidth =
        (columnContext && columnContext[key]?.maximumColumnWidth) ||
        calculateMaximumContainerWidth(
          nextBreakpointWidth,
          LAYOUT_MAX_CONTAINER_WIDTH,
          LAYOUT_GRID_OFFSET[key as keyof TBreakPoints]
        )
      const maximumColumnWidth = calculateMaximumColumnWidth(
        maximumContainerWidth,
        LAYOUT_GRID_GAP[key],
        LAYOUT_GRID_COLUMNS,
        reducedColumns[key] || 0
      )

      return {
        ...accumulator,
        [key]: {
          breakpointValue: value,
          nextBreakpointWidth,
          maximumColumnWidth
        }
      }
    },
    {}
  )

  return (
    <ColumnContext.Provider value={columnContextValue}>
      <StyledColumn columns={columns} offset={offset} {...others} />
    </ColumnContext.Provider>
  )
}
