import { IFooterProps } from '../../domains/storyblok/mappers/map-footer'
import { HeadingTypes, ParagraphTypes } from '../../types/typography'
import { useIsLG } from '../../hooks/use-media-query'
import { SocialList } from '../social-list'
import { IconNames } from '../icon'
import { initialAccordionItemStates } from '../accordion-item'
import { Link } from '../link'
import { LayoutRow, LayoutColumn, LayoutContainer } from '../layout'
import { LanguageSwitch, LanguageSwitchVariant } from '../language-switch'
import { SiteUtils } from '../../utils/site'
import { isMultiLanguage } from '../../i18n/locales'

import {
  StyledAccordion,
  StyledAccordionItem,
  StyledBottom,
  StyledBottomList,
  StyledBottomListLink,
  StyledCopyRight,
  StyledDownloadDescription,
  StyledDownloadImage,
  StyledDownloadList,
  StyledDownloadTitle,
  StyledFooter,
  StyledIcon,
  StyledImageList,
  StyledImage,
  StyledItem,
  StyledLinkListLink,
  StyledRow,
  StyledSocialListWrapper,
  StyledCorner
} from './Footer.style'

export const Footer: React.FC<IFooterProps> = ({
  navLists = [],
  bottomList = [],
  bottomImageList = [],
  socialList = [],
  copyright,
  downloadButtons,
  downloadTitle,
  downloadDescription
}) => {
  const isLG = useIsLG()
  const isLandbased = SiteUtils.isLandbased()
  const hasDownloadSection =
    downloadTitle !== '' || (downloadButtons && downloadButtons.length > 0)

  return (
    <StyledFooter>
      {!isLandbased && (
        <StyledCorner
          variant={SiteUtils.select({
            corporate: 'quaternary',
            default: 'primary'
          })}
          position="right-bottom"
        />
      )}
      <LayoutContainer>
        <StyledRow>
          <LayoutColumn
            columns={{ LG: hasDownloadSection ? 5 : 12 }}
            offset={{ LG: hasDownloadSection ? 3 : 1 }}
          >
            <StyledAccordion>
              {navLists &&
                navLists.map(list => (
                  <StyledAccordionItem
                    isActive={!isLG}
                    key={list._uid}
                    initialState={initialAccordionItemStates.CLOSED}
                    title={list.label}
                    variant="dark"
                    isCompact={!hasDownloadSection}
                  >
                    <ul>
                      {list.items &&
                        list.items.map(item => (
                          <StyledItem key={item._uid}>
                            <StyledLinkListLink {...item}>
                              {item.label}
                              <StyledIcon
                                name={IconNames.CARET_RIGHT}
                                hasCircle={isLandbased}
                              />
                            </StyledLinkListLink>
                          </StyledItem>
                        ))}
                    </ul>
                  </StyledAccordionItem>
                ))}
            </StyledAccordion>
          </LayoutColumn>
          {hasDownloadSection && (
            <LayoutColumn columns={{ LG: 3 }}>
              {downloadTitle && (
                <StyledDownloadTitle type={HeadingTypes.DISPLAY_XXS}>
                  {downloadTitle}
                </StyledDownloadTitle>
              )}
              {downloadDescription && (
                <StyledDownloadDescription type={ParagraphTypes.TEXT_M}>
                  {downloadDescription}
                </StyledDownloadDescription>
              )}
              {downloadButtons && (
                <StyledDownloadList>
                  {downloadButtons.map(downloadButton => (
                    <li key={downloadButton._uid}>
                      <Link
                        link={downloadButton.link}
                        aria-label={downloadButton.type}
                      >
                        <StyledDownloadImage
                          width={120}
                          src={`/images/download/${downloadButton.type}.png`}
                        />
                      </Link>
                    </li>
                  ))}
                </StyledDownloadList>
              )}
            </LayoutColumn>
          )}
        </StyledRow>
        <LayoutRow>
          <LayoutColumn columns={{ LG: 10 }} offset={{ LG: 1 }}>
            <StyledBottom>
              <StyledSocialListWrapper>
                <SocialList items={socialList} />
              </StyledSocialListWrapper>

              <StyledBottomList>
                {copyright && (
                  <StyledCopyRight type={ParagraphTypes.TEXT_XS}>
                    {copyright}
                  </StyledCopyRight>
                )}
                {bottomList &&
                  bottomList.map(item => (
                    <li key={item._uid}>
                      <StyledBottomListLink {...item}>
                        {item.label}
                      </StyledBottomListLink>
                    </li>
                  ))}
              </StyledBottomList>
              {bottomImageList && (
                <StyledImageList>
                  {bottomImageList.map(image => (
                    <li key={image.id}>
                      <StyledImage
                        image={image}
                        options={{
                          layout: 'constrained',
                          height: 72
                        }}
                      />
                    </li>
                  ))}
                </StyledImageList>
              )}
              {isMultiLanguage() && (
                <LanguageSwitch variant={LanguageSwitchVariant.FOOTER} />
              )}
            </StyledBottom>
          </LayoutColumn>
        </LayoutRow>
      </LayoutContainer>
    </StyledFooter>
  )
}
