import { DefaultTheme } from 'styled-components'
import { SHARED_THEME } from '@hc/shared/theme'

import { COMPONENTS } from './components'
import { COLOR } from './basic/color'

export const THEME: DefaultTheme = {
  ...SHARED_THEME,
  COLOR,
  COMPONENTS: {
    ...SHARED_THEME.COMPONENTS,
    ...COMPONENTS
  }
}
