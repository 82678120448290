import { z } from 'zod'

export const IMSCreateAccountSchema = z.object({
  statusCode: z.number().nullable(),
  errorCode: z.union([z.number(), z.string()]).nullable().optional(),
  message: z.string().optional().nullable()
})

export type IMSCreateAccountResponse = z.infer<typeof IMSCreateAccountSchema>

export enum IMSCreateAccountErrorCodeResponse {
  MISSING_MANDATORY_FIELDS = 1001,
  ACCOUNT_ALREADY_EXISTS = 1002,
  PASSWORD_LENGTH_OUT_OF_RANGE = 'UMS-151',
  INVALID_NEW_PASSWORD = 'UMS-148',
  UNKNOWN_ERROR = 3000
}

export interface IMSCreateAccountErrorResponse {
  statusCode: number
  errorCode: IMSCreateAccountErrorCodeResponse
  message: string
}

export function isCreateAccountError(
  response: IMSCreateAccountResponse | IMSCreateAccountErrorResponse
): response is IMSCreateAccountErrorResponse {
  const statusCode = (response as IMSCreateAccountErrorResponse).statusCode
  return !(statusCode === null || statusCode === 200)
}
