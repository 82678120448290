import { IconNames } from '../icon'
import { RichText } from '../rich-text'
import { RichTextField } from '../../domains/storyblok/models/fields/types'

import { StyledContainer, StyledIcon, StyledBody } from './TextWithIcon.styles'

export interface ITextWithIconProps {
  icon: IconNames
  description: RichTextField
}

export const TextWithIcon: React.FC<ITextWithIconProps> = ({
  icon,
  description
}) => {
  return (
    <StyledContainer>
      {icon && <StyledIcon name={icon} />}
      <StyledBody>
        <RichText content={description} />
      </StyledBody>
    </StyledContainer>
  )
}
