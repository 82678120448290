import styled from 'styled-components'

import { Icon } from '../icon'

export const StyledLoader = styled(Icon)`
  margin: 0 auto;
  display: block;
  height: 4rem;
  width: 4rem;
  fill: ${({ theme }) => theme.COMPONENTS.LOADER_COLOR};
`
