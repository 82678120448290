import { stringToLanguage } from '../i18n/locales'
import { Locale } from '../utils/types'

interface LocalisedStories<T> {
  nl?: T
  en?: T
  de?: T
}

interface AllStoryBlokQuery {
  readonly allStoryblokEntry: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly lang: string | null
        readonly content: string | null
      }
    }>
  }
}

export const useLocalisedStory = <T extends AllStoryBlokQuery>(data: T) => {
  type Story = T['allStoryblokEntry']['edges'][0]['node']
  const localisedStories: LocalisedStories<Story> = {}

  data.allStoryblokEntry.edges.forEach(story => {
    localisedStories[stringToLanguage(story.node.lang)] = story.node
  })

  const getStory = <TStory>(locale: Locale) => {
    const storyData = localisedStories[locale]
    const story = storyData
    let storyContent = story?.content
    if (storyContent) {
      try {
        storyContent = JSON.parse(storyContent)
      } catch {
        // skip
      }
    }

    return {
      ...story,
      content: storyContent as TStory
    }
  }

  return {
    getStory
  }
}
