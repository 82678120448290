import { DateTime } from 'luxon'

import {
  PokerGame,
  PokerGames,
  PokerGameStatus,
  PokerGameType,
  PokerGameSingle,
  BettingScheduleLevel,
  PokerReservation,
  ReservationGameTypes
} from './prs.models'
import type {
  IGetPRSGamesResponse,
  IGetPRSGamesSingleListResponse,
  IPRSGame,
  IGetSingleGameResponse,
  IBettingScheduleLevel,
  IGetPRSBookingsForUserResponse,
  IPRSReservation
} from './prs.types'

export const getGameStatus = (
  game: IPRSGame | IGetSingleGameResponse
): PokerGameStatus => {
  switch (game.AvailabilityCode) {
    case 0:
      return PokerGameStatus.Full
    case 1:
      return PokerGameStatus.Available
    case 2:
      return PokerGameStatus.FewPlacesAvailable
    case 3:
      return PokerGameStatus.WaitingList
    case 4:
      return PokerGameStatus.RegistrationClosed
    default:
      return PokerGameStatus.Unknown
  }
}

export const getBuyinMinMax = (buyIn: string) => {
  const splitBuyin = buyIn.split('-').map(amount => amount.replace(/\D/g, ''))
  const min = splitBuyin[0] ? parseInt(splitBuyin[0]) : 0
  const max = splitBuyin[1] ? parseInt(splitBuyin[1]) : 0
  return {
    min,
    max
  }
}

export const normalizedGameType = (type: string | undefined): PokerGameType => {
  let gameType = PokerGameType.Unknown

  switch (type) {
    case 'CashGame':
      gameType = PokerGameType.Cashgame
      break
    case 'Cash Game':
      gameType = PokerGameType.Cashgame
      break
    case 'Tournament':
      gameType = PokerGameType.Tournament
  }
  return gameType
}

const mapPokerGame = (game: IPRSGame, gameType?: PokerGameType): PokerGame => {
  const status = getGameStatus(game)

  return {
    gameId: game.GameId,
    venue: game.Site,
    serie: game.Serie,
    label: game.Label,
    startDate: DateTime.fromISO(game.StartDate).setLocale('nl'),
    startTime: DateTime.fromISO(game.StartTime).setLocale('nl'),
    status,
    buyInMin: game.BuyInMin,
    buyInMax: game.BuyInMax,
    entryFee: game.EntryFee,
    type: gameType || game.TypeName || PokerGameType.Unknown,
    allowBooking:
      status !== PokerGameStatus.RegistrationClosed && game.AllowBooking,
    priceInCents: game.PriceInCents
  }
}

export const mapBettingScheduleLevels = (
  levels: IBettingScheduleLevel[]
): BettingScheduleLevel[] =>
  levels.map(level => ({
    ante: level.Ante,
    bigBlind: level.BigBlind,
    bigBlindAnte: level.BigBlindAnte,
    level: level.Level,
    smallBlind: level.SmallBlind
  }))

export const mapSingleGame = (
  game: IGetSingleGameResponse,
  gameType?: PokerGameType
): PokerGameSingle => {
  const status = getGameStatus(game)
  const bettingSchedule = game.BettingSchedule

  return {
    event: game.Event,
    gameType: game.GameType,
    limit: game.Limit,
    variant: game.Variant,
    bettingSchedule: {
      name: bettingSchedule.Name,
      isBigBlindAnte: bettingSchedule.IsBigBlindAnte,
      levels: mapBettingScheduleLevels(bettingSchedule.Levels)
    },
    levelDuration: game.LevelDuration,
    totalAmount: game.TotalAmount,
    startStack: game.Startstack,
    dinnerBreak: game.DinnerBreak,
    tournamentDays: game.TournamentDays,
    rebuy: game.Rebuy,
    addon: game.Addon,
    reEntry: game.ReEntry,
    alternateList: game.AlternateList,
    serieName: game.SerieName,
    totalSeats: game.TotalSeats,
    status,
    gameId: game.GameId,
    venue: game.Site,
    label: game.Label,
    startDate: DateTime.fromISO(game.StartDate).setLocale('nl'),
    startTime: DateTime.fromISO(game.StartTime).setLocale('nl'),
    buyIn: game.BuyIn,
    entryFee: game.EntryFee,
    type: gameType || normalizedGameType(game.TypeName),
    allowBooking:
      status !== PokerGameStatus.RegistrationClosed && game.AllowBooking,
    priceInCents: game.PriceInCents
  }
}

export const mapPokerGames = (response: IGetPRSGamesResponse): PokerGames => {
  return {
    games: [
      // Merge CashGames and Tournaments into a single array
      ...response.CashGames.map(game =>
        mapPokerGame(game, PokerGameType.Cashgame)
      ),
      ...response.Tournaments.map(game =>
        mapPokerGame(game, PokerGameType.Tournament)
      )
    ]
  }
}

export const mapPokerGamesSingleList = (
  response: IGetPRSGamesSingleListResponse
): PokerGames => {
  return {
    games: response.map(game => mapPokerGame(game))
  }
}

export const mapSingleReservedGame = (
  game: IPRSReservation
): PokerReservation => {
  const buyInMinMax = getBuyinMinMax(game.BuyIn)

  return {
    label: game.Description,
    type: normalizedGameType(game.Type),
    gameId: game.GameInstanceId,
    venue: game.SiteLabel,
    buyInMin: buyInMinMax.min,
    buyInMax: buyInMinMax.max,
    entryFee: game.EntryFee,
    allowCancel: AllowedToCancel(game.AllowCancel, game.Type),
    reservationStatus: game.ReservationStatus,
    reservationId: game.ReservationId,
    startDate: DateTime.fromISO(game.DateTime).toUTC().setLocale('nl'), // toUTC because no timezone is returned
    startTime: DateTime.fromISO(game.DateTime).toUTC().setLocale('nl')
  }
}

export const mapReservedGames = (
  response: IGetPRSBookingsForUserResponse
): PokerReservation[] => {
  const reservations: PokerReservation[] = []

  response.forEach(booking => {
    booking.Reservations.forEach(reservation =>
      reservations.push(mapSingleReservedGame(reservation))
    )
  })
  return reservations
}

export const AllowedToCancel = (allowCancel: boolean, type: string) => {
  return allowCancel && type !== ReservationGameTypes.Tournament
}
