/* eslint-disable @typescript-eslint/no-explicit-any */
interface IStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
}

/**
 * Used as fallback in case window doesn't exist (buildtime node environment)
 */
const noopStorage: IStorage = {
  setItem: () => null,
  getItem: () => null,
  removeItem: () => null
}

export class StorageService {
  storage: IStorage

  constructor() {
    this.storage = typeof window !== 'undefined' ? localStorage : noopStorage
  }

  /**
   * Get item from localStorage
   * @param key
   * @returns JSON decoded value from the localstorage if object is found
   */
  getItem<T>(key: string): T | null {
    const data = this.storage.getItem(key)

    if (!data) {
      return null
    }

    try {
      const item = JSON.parse(data)

      // If TTL has expired, remove the item from localStorage and return null
      if (item.ttl !== null && Date.now() > item.ttl) {
        this.storage.removeItem(key)
        return null
      }

      // return data if not expired
      return item.value
    } catch {
      // Invalid JSON
      return null
    }
  }

  /**
   * Store an item in the localStorage
   * @param key The name of the item to store
   * @param value The value to store, can be any JSON serializable value
   * @param ttl TTL of the item in seconds
   */
  setItem(key: string, value: any, ttl: number | null = null): void {
    const ttlValue = ttl ? Date.now() + ttl * 1000 : null

    const data = {
      value,
      ttl: ttlValue
    }

    // store data in LocalStorage
    this.storage.setItem(key, JSON.stringify(data))
  }

  /**
   * Remove an item from the localstorage
   *
   * @param key The item to remove
   */
  removeItem(key: string) {
    this.storage.removeItem(key)
  }
}

export const HCStorage = new StorageService()
