import { PASError, PASTermsAndConditionsError } from './pas.types'

export class IMShadowError extends Error {
  type = 'IMShadowError'

  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, IMShadowError.prototype)
  }

  getErrorMessage() {
    return `IMShadowError Error: ${this.message}`
  }
}

export type LoginErrors = PASError | PASTermsAndConditionsError | IMShadowError

export function isIMShadowError(error: LoginErrors): error is IMShadowError {
  return (error as IMShadowError).type === 'IMShadowError'
}
