import { useContext, useMemo } from 'react'
import { useTheme, BreakPoints } from 'styled-components'

import ColumnContext from '../layout/ColumnContext'

import { ImageContainer } from './Image.styles'

const DEFAULT_BREAKPOINT = 'XXS'

export interface IImageProps extends Partial<HTMLImageElement> {
  src: string
  ogWidth?: number
  ogHeight?: number
  isExtraWidth?: boolean
  isCovered?: boolean
  hasBorderRadius?: boolean
  loading?: 'lazy' | 'eager'
}

export const Image: React.FC<IImageProps> = ({
  src,
  ogWidth,
  ogHeight,
  width,
  isExtraWidth,
  isCovered,
  loading = 'lazy',
  hasBorderRadius,
  alt,
  className
}) => {
  const columns = useContext(ColumnContext)

  const {
    LAYOUT: { LAYOUT_MAX_CONTAINER_WIDTH, LAYOUT_EXTRA_WIDTH }
  } = useTheme()

  const { imgSrc, srcSet, sizes } = useMemo(() => {
    const defaultMaximumColumnWidth = columns
      ? columns[DEFAULT_BREAKPOINT].maximumColumnWidth
      : LAYOUT_MAX_CONTAINER_WIDTH

    const getImageSRC = (src: string, width: number): string =>
      `${src}?w=${width}&fm=webp&q=75`

    const getSRCSetItem = (src: string, width: number): string =>
      !ogWidth || width <= ogWidth
        ? `${getImageSRC(src, width)} ${width}w, `
        : ''

    const getMediaQuery = (
      currentBreakpointWidth: number | undefined,
      nextBreakpointWidth: number | undefined
    ): string =>
      `(min-width: ${currentBreakpointWidth}px)${
        nextBreakpointWidth ? ` and (max-width: ${nextBreakpointWidth}px)` : ''
      }`

    const getExtraWidth = (breakpoint: keyof BreakPoints) =>
      isExtraWidth ? LAYOUT_EXTRA_WIDTH[breakpoint] : 0

    const calculateRequiredImageWidth = (
      maximumColumnWidth: number | undefined = 0,
      extraWidth: number
    ) => (width && width * 2) || maximumColumnWidth + extraWidth * 2

    const srcSet =
      columns && !width
        ? Object.entries(columns)
            .reduce((accumulator, [key, { maximumColumnWidth }]) => {
              const extraWidth = getExtraWidth(key as keyof BreakPoints)
              const imgWidth: number = calculateRequiredImageWidth(
                maximumColumnWidth,
                extraWidth
              )

              return accumulator.concat(
                getSRCSetItem(src, imgWidth),
                getSRCSetItem(src, imgWidth * 2)
              )
            }, '')
            .concat(ogWidth ? getSRCSetItem(src, ogWidth) : '')
            .slice(0, -2)
        : undefined

    const sizes =
      columns && !width
        ? Object.entries(columns)
            .reduce(
              (
                accumulator,
                [
                  key,
                  { breakpointValue, maximumColumnWidth, nextBreakpointWidth }
                ]
              ) => {
                const extraWidth = getExtraWidth(key as keyof BreakPoints)
                const imgWidth: number = calculateRequiredImageWidth(
                  maximumColumnWidth,
                  extraWidth
                )

                return accumulator.concat(
                  `${getMediaQuery(
                    breakpointValue,
                    nextBreakpointWidth
                  )} ${imgWidth}px, `
                )
              },
              ''
            )
            .slice(0, -2)
        : undefined

    return {
      imgSrc: getImageSRC(
        src,
        calculateRequiredImageWidth(
          defaultMaximumColumnWidth,
          getExtraWidth(DEFAULT_BREAKPOINT)
        )
      ),
      srcSet,
      sizes
    }
  }, [src, width, isExtraWidth])

  return (
    <ImageContainer
      src={imgSrc}
      width={ogWidth}
      height={ogHeight}
      loading={loading}
      srcSet={srcSet}
      sizes={sizes}
      isCovered={isCovered}
      hasBorderRadius={hasBorderRadius}
      alt={alt}
      className={className}
    />
  )
}
