import { COLOR } from '../basic/color'

export const COMPONENTS = {
  BODY_COLOR: COLOR.BLUE_90,
  PRIMARY_COLOR: COLOR.GOLD_70_PRIMARY,
  OVERLAY_COLOR: COLOR.BLUE_90,
  IMAGE_OVERLAY_COLOR: COLOR.PURPLE_60_PRIMARY,
  HEADING_TEXT_TRANSFORM: 'unset',
  DISABLED_COLOR: COLOR.BLUE_10,
  BUTTON_PRIMARY_BACKGROUND: COLOR.GOLD_70_PRIMARY,
  BUTTON_PRIMARY_COLOR: COLOR.GOLD_10,
  BUTTON_PRIMARY_HOVER_BACKGROUND: COLOR.GOLD_90,
  BUTTON_DARK_BACKGROUND: COLOR.BLUE_60_PRIMARY,
  BUTTON_DARK_COLOR: COLOR.BLUE_10,
  BUTTON_DARK_HOVER_BACKGROUND: COLOR.BLUE_80,
  BUTTON_LIGHT_BACKGROUND: COLOR.GOLD_10,
  BUTTON_LIGHT_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_BORDER_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_HOVER_BACKGROUND: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_HOVER_COLOR: COLOR.GOLD_10,
  BUTTON_GHOST_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_GHOST_BORDER_COLOR: COLOR.GOLD_30,
  BUTTON_GHOST_HOVER_COLOR: COLOR.GOLD_10,
  BUTTON_GHOST_LIGHT_DARK_COLOR: COLOR.BLUE_60_PRIMARY,
  BUTTON_GHOST_LIGHT_DARK_BORDER_COLOR: COLOR.BLUE_10,
  BUTTON_GHOST_LIGHT_DARK_HOVER_COLOR: COLOR.BLUE_90,
  BUTTON_GHOST_LIGHT_HOVER_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_SECONDARY_BACKGROUND: COLOR.BLUE_60_PRIMARY,
  BUTTON_SECONDARY_HOVER_BACKGROUND: COLOR.BLUE_90,
  BUTTON_TERTIARY_BACKGROUND: COLOR.PURPLE_60_PRIMARY,
  BUTTON_TERTIARY_HOVER_BACKGROUND: COLOR.PURPLE_90,
  BUTTON_LINK_COLOR: COLOR.BLUE_90,
  BUTTON_LINK_HOVER_COLOR: COLOR.BLUE_100,
  BUTTON_LINK_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LINK_ICON_HOVER_COLOR: COLOR.GOLD_90,
  BUTTON_LINK_LIGHT_COLOR: COLOR.GOLD_10,
  BUTTON_LINK_LIGHT_HOVER_COLOR: COLOR.GOLD_20,
  BUTTON_INLINE_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_INLINE_HOVER_COLOR: COLOR.GOLD_90,
  BUTTON_INLINE_COLOR_LIGHT: COLOR.GOLD_70_PRIMARY,
  BUTTON_INLINE_SECONDARY_COLOR: COLOR.BLUE_60_PRIMARY,
  BUTTON_INLINE_SECONDARY_HOVER_COLOR: COLOR.BLUE_90,
  BUTTON_INLINE_TERTIARY_COLOR: COLOR.PURPLE_60_PRIMARY,
  BUTTON_INLINE_TERTIARY_HOVER_COLOR: COLOR.PURPLE_90,
  TEXT_BLOCK_TITLE_COLOR_PRIMARY: COLOR.GOLD_70_PRIMARY,
  TEXT_BLOCK_TITLE_COLOR_TERTIARY: COLOR.GOLD_70_PRIMARY,
  CAROUSEL_DOT_COLOR_INACTIVE: COLOR.GOLD_30,
  CAROUSEL_DOT_COLOR_ACTIVE: COLOR.GOLD_70_PRIMARY,
  BLOCK_BACKGROUND_SECONDARY: COLOR.GOLD_10,
  BLOCK_BACKGROUND_TERTIARY: COLOR.BLUE_60_PRIMARY,
  NEWS_CARD_CATEGORY_COLOR: COLOR.BLUE_90,
  FOOTER_BACKGROUND: COLOR.BLUE_80,
  ACCORDION_TITLE_COLOR: COLOR.GOLD_70_PRIMARY,
  ACCORDION_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  ACCORDION_ICON_COLOR_LIGHT: COLOR.GOLD_70_PRIMARY,
  USP_TITLE_COLOR: COLOR.GOLD_70_PRIMARY,
  USP_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  USP_ICON_BACKGROUND_COLOR: 'transparant',
  USP_ICON_BORDER_COLOR: COLOR.GOLD_70_PRIMARY,
  CORNER_COLOR_PRIMARY: COLOR.GOLD_70_PRIMARY,
  CORNER_COLOR_SECONDARY: COLOR.BLUE_60_PRIMARY,
  CORNER_COLOR_TERTIARY: COLOR.PURPLE_60_PRIMARY,
  INPUT_FOCUS_COLOR: COLOR.GOLD_70_PRIMARY,
  INPUT_PLACEHOLDER_COLOR: COLOR.BLUE_10,
  INPUT_BORDER_COLOR: COLOR.BLUE_10,
  BLOCK_TITLE_WEIGHT: '800',
  HERO_BACKGROUND: COLOR.PURPLE_60_PRIMARY,
  LOADER_COLOR: COLOR.GOLD_70_PRIMARY,
  NOT_FOUND_CARD_COLOR: COLOR.GOLD_50,
  NOT_FOUND_CARD_COLOR_BUTTON: COLOR.GOLD_70_PRIMARY,
  NOT_FOUND_CARD_COLOR_BUTTON_HOVER: COLOR.GOLD_90,
  BLOCKQUOTE_COLOR_PRIMARY: COLOR.GOLD_70_PRIMARY,
  BLOCKQUOTE_COLOR_SECONDARY: COLOR.BLUE_60_PRIMARY,
  BLOCKQUOTE_COLOR_TERTIARY: COLOR.PURPLE_60_PRIMARY,
  NAVIGATION_TEXT_TRANSFORM: 'none'
}

export type TComponentName = keyof typeof COMPONENTS
