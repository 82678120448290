import { IStoryBlokImage } from '../../../components/sb-image'
import { ImageField } from '../models/fields/types'

export interface IImageProps extends IStoryBlokImage {
  src?: string
}

const mapImage = ({
  name: alt,
  filename: src,
  copyright,
  fieldtype,
  filename,
  focus,
  id,
  name,
  title
}: ImageField): IImageProps => {
  return {
    src,
    alt: alt || '',
    copyright,
    fieldtype,
    filename,
    focus,
    id,
    name,
    title
  }
}

// TODO: Remove this ( IStaticImageProps, StaticImageField and mapStaticImage) once every static image is replaced by SB images
interface IStaticImageProps {
  src: string
  alt?: string
  loading?: 'lazy' | 'eager'
  width?: number
  isExtraWidth?: boolean
  isCovered?: boolean
  [key: string]: unknown
}

interface StaticImageField {
  name: string
  filename: string
}

export const mapStaticImage = ({
  name: alt,
  filename: src
}: StaticImageField): IStaticImageProps => {
  return {
    src,
    alt: alt || ''
  }
}

export default mapImage
