import { ReactNode } from 'react'

import { StyledAccordion } from './Accordion.style'

interface IAccordionProps {
  children?: ReactNode
  className?: string
}

export const Accordion: React.FC<IAccordionProps> = ({
  children,
  className
}) => {
  return <StyledAccordion {...{ className }}>{children}</StyledAccordion>
}

export default Accordion
