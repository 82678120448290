import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { TBreakPoints } from '../utils/types'
import { BreakpointTypes } from '../types/breakpoints'

const useMediaQuery = (query: string, defaultValue = false) => {
  const [matches, setMatches] = useState(defaultValue)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, query])

  return matches
}

const useIsLG = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px)`)
}

const useIsMD = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.MD}px)`)
}

const useIsSM = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.SM}px)`)
}

const useIsXS = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.XS}px)`)
}

const useIsXXS = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.LAYOUT_BREAKPOINT.XXS}px)`)
}

function useCurrentBreakpoint(
  defaultValue?: keyof TBreakPoints
): keyof TBreakPoints {
  const isLG = useIsLG()
  const isMD = useIsMD()
  const isSM = useIsSM()
  const isXS = useIsXS()
  const isXXS = useIsXXS()

  if (isLG) {
    return BreakpointTypes.LG
  }

  if (isMD) {
    return BreakpointTypes.MD
  }

  if (isSM) {
    return BreakpointTypes.SM
  }

  if (isXS) {
    return BreakpointTypes.XS
  }

  if (isXXS) {
    return BreakpointTypes.XXS
  }

  return defaultValue || BreakpointTypes.XXS
}

function useBreakpointBasedValue<T extends number | string>(
  breakpoints: { [key in keyof TBreakPoints]: T },
  defaultValue?: keyof TBreakPoints
) {
  const currentBreakpoint = useCurrentBreakpoint(defaultValue)

  const value = breakpoints[currentBreakpoint] || breakpoints.XXS

  return value
}

export {
  useCurrentBreakpoint,
  useBreakpointBasedValue,
  useIsLG,
  useIsMD,
  useIsSM,
  useIsXS,
  useIsXXS
}

export default useMediaQuery
