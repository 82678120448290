import styled, { css } from 'styled-components'
import { Icon } from '@hc/shared/components/icon'
import { Heading } from '@hc/shared/components/typography'

interface Props {
  isActive?: boolean
  isFixed?: boolean
}

export const StyledHeaderContainer = styled.header<Props>`
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    right: 0.8rem;
    padding: 0 1.6rem;
    height: 6.4rem;

    ${({ isFixed, theme }) =>
      isFixed &&
      css`
        position: fixed;
        background-color: ${theme.COLOR.NEUTRAL_10};
        border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
        border: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_20}eb;
      `};
  }
`
export const StyledHeaderTitle = styled(Heading)`
  width: 100%;
  text-align: center;
  padding-right: 3.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledHeaderNavButton = styled.button<Props>`
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};

  ${({ isActive }) =>
    isActive &&
    css`
      position: fixed;
    `};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledHeaderNavButtonIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.BLUE_90};
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 3.2rem;
  padding: 1rem;
`
