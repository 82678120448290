/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void
}

/** Basic logger interface */
export interface Logger {
  log: LogFn
  warn: LogFn
  error: LogFn
}

interface LoggerOptions {
  prefix?: string
  prefixColor?: string
}

export class ConsoleLogger implements Logger {
  readonly options?: LoggerOptions

  constructor(options?: LoggerOptions) {
    this.options = options
  }

  public log(message?: any, ...optionalParams: any[]): void {
    return this.runIfEnabled(() =>
      console.log(...this.addPrefix(message), ...optionalParams)
    )
  }

  public error(message?: any, ...optionalParams: any[]): void {
    return this.runIfEnabled(() =>
      console.error(...this.addPrefix(message), ...optionalParams)
    )
  }

  public warn(message?: any, ...optionalParams: any[]): void {
    return this.runIfEnabled(() =>
      console.warn(...this.addPrefix(message), ...optionalParams)
    )
  }

  protected addPrefix(message: any) {
    if (this.options?.prefix) {
      if (this.options.prefixColor) {
        return [
          `%c${this.options.prefix}`,
          `color: ${this.options.prefixColor}; font-weight: bold;`,
          message
        ]
      }

      return [this.options.prefix, message]
    }

    return [message]
  }

  protected runIfEnabled(action: () => void) {
    if (process.env.GATSBY_LOGGER_ENABLED && !process.env.CI) {
      action()
    }
  }
}

export const logger = new ConsoleLogger({
  prefix: '[DEFAULT]'
})
