import { IconNames } from './constants'
import { StyledIcon } from './Icon.styles'
import { Icons } from './svg'

type HtmlTags = 'span' | 'div'

export interface IconProps {
  name: IconNames
  color?: string
  size?: string
  className?: string
  hasCircle?: boolean
  hasBorder?: boolean
  asHtmlTag?: HtmlTags
}

export const Icon: React.FC<IconProps> = ({
  name,
  color,
  size = '2.4rem',
  hasCircle,
  className,
  hasBorder = true,
  asHtmlTag = 'div'
}) => {
  const SvgIcon = Icons[name]

  if (SvgIcon) {
    return (
      <StyledIcon
        color={color}
        size={size}
        hasCircle={hasCircle}
        className={className}
        hasBorder={hasBorder}
        as={asHtmlTag}
      >
        <SvgIcon />
      </StyledIcon>
    )
  }

  return null
}
