export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  FORM_CONFIRMATION_PAGE: '/formulier-verzonden',
  SHOP: {
    CART: '/shop/cart',
    THANKYOU: '/shop/thank-you',
    TERMS_AND_CONDITIONS: '/'
  },
  MYHC: {
    HOME: '/myhc/',
    CHANGE_PASSWORD: '/myhc/change-password/',
    MY_POKER: '/myhc/my-poker/',
    MY_RESERVATIONS: '/myhc/my-reservations/',
    PERSONAL_DETAILS: '/myhc/personal-details/',
    PREFERENCES: '/myhc/preferences/'
  },
  CUSTOMER_SERVICE: {
    CONTACT: '/customer-service/contact'
  },
  VISIT_RESTRICTION_FORM: {
    TERMS_AND_CONDITIONS: '/over-ons/bezoekbeperking-voorwaarden',
    EXTERNAL: {
      TERMS_AND_CONDITIONS: '/over-ons/melding-over-een-ander-voorwaarden'
    }
  }
}
