import { HttpClient } from '@hc/shared/domains/api/http'

import {
  mapPokerGames,
  mapPokerGamesSingleList,
  mapSingleGame,
  mapReservedGames
} from './prs.mappers'
import { PokerGames } from './prs.models'
import type {
  IGetPRSGamesResponse,
  IGetPRSGamesSingleListResponse,
  IGetSingleGameResponse,
  IPRSGameFilters,
  IPokerReservationFilter
} from './prs.types'

const client = HttpClient.create({
  baseURL: 'v3/PRS',
  requestConfig: {
    headers: {
      'X-API-KEY': process.env.GATSBY_PRS_API_KEY || ''
    }
  }
})

export class PrsApi {
  // @TODO: Might not be needed anymore since we switch to SingleList
  static async getGames(filters?: IPRSGameFilters): Promise<PokerGames> {
    const { data } = await client.get<IGetPRSGamesResponse>('Anonymous/Games', {
      params: filters
    })
    return mapPokerGames(data)
  }

  static async getGamesSingleList(
    filters?: IPRSGameFilters
  ): Promise<PokerGames> {
    const { data } = await client.get<IGetPRSGamesSingleListResponse>(
      'Anonymous/GamesSingleList',
      {
        params: filters
      }
    )
    return mapPokerGamesSingleList(data)
  }

  static async getSingleGame(GameId: number) {
    const { data } = await client.get<IGetSingleGameResponse>(
      'Anonymous/Game',
      {
        params: { GameId }
      }
    )
    return mapSingleGame(data)
  }

  static async getBookingsForUser(filter: IPokerReservationFilter) {
    const { data } = await client.get('Authenticated/GetBookingsForUser', {
      withCredentials: true,
      params: filter
    })

    return mapReservedGames(data)
  }

  static async cancelReservation(ReservationId: number) {
    const { data } = await client.post(
      'Authenticated/CancelReservation',
      null,
      {
        params: {
          ReservationId
        },
        withCredentials: true
      }
    )
    return data
  }
}
