import { JackpotValue } from './jackpot.models'
import {
  IjackpotValue,
  IJackpotValuesResponse,
  IJackpotValuesResponseLevel,
  PROGRESSIVE_JACKPOT_SITECODE
} from './jackpot.types'

const getHighestJackpotAmount = (levels: IJackpotValuesResponseLevel[]) => {
  let amount = 0
  for (let i = 0; i < levels.length; i++) {
    const levelAmount = parseInt(levels[i].amount)
    if (levelAmount > amount) {
      amount = levelAmount
    }
  }
  return amount
}

export const mapJackpotValue = (jackpot: IjackpotValue, siteCode: string) => {
  return {
    active: jackpot.active,
    name: jackpot.category,
    amount: getHighestJackpotAmount(jackpot.levels),
    venue: siteCode,
    isProgressive: siteCode === PROGRESSIVE_JACKPOT_SITECODE
  }
}

export const mapJackpotValuesList = (
  response: IJackpotValuesResponse
): JackpotValue[] => {
  const jackpotValues: JackpotValue[] = []
  response.jackpot_values.forEach(jackpotContainer => {
    jackpotContainer.values.forEach(jackpot => {
      jackpotValues.push(mapJackpotValue(jackpot, jackpotContainer.site_code))
    })
  })

  return jackpotValues
}

export const mapJackpotCarouselDataSet = (
  data: JackpotValue[],
  venue?: string
) => {
  let filteredData = data

  if (venue) {
    filteredData = data.filter(
      item =>
        item.venue === venue || item.venue === PROGRESSIVE_JACKPOT_SITECODE
    )
  }
  const sortItems = (a: JackpotValue, b: JackpotValue) => b.amount - a.amount
  return filteredData.sort(sortItems).slice(0, 5)
}
