import { FC, ReactNode } from 'react'

import { StyledBlock } from './Block.style'
import { BlockVariant } from './Block.types'

interface IProps {
  children: ReactNode
  hasGap?: boolean
  variant?: BlockVariant
  className?: string
}

export const Block: FC<IProps> = ({ children, hasGap, variant, className }) => {
  return (
    <StyledBlock {...{ hasGap, variant, className }}>{children}</StyledBlock>
  )
}
