import React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type {
  GatsbyBrowser,
  RouteUpdateArgs,
  WrapRootElementBrowserArgs
} from 'gatsby'
import { useI18next, setI18n } from 'gatsby-plugin-react-i18next'
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types'

import { HCStorage } from './src/utils/storage'
import { SharedGlobalStyle } from './src/theme'
import { STORAGE_PREV_PAGE } from './src/const'
import { SimpleLayout } from './src/layouts/SimpleLayout'

export const shouldStorePrevPage = (
  prevPageIgnoreList: string[],
  url: string
) => {
  const regex = new RegExp(`^(/en|/de)?(${prevPageIgnoreList.join('|')})/?$`)
  return url && !regex.test(url)
}

const onRouteUpdate = (
  { location }: RouteUpdateArgs,
  prevPageIgnoreList: string[] = []
) => {
  if (
    location?.pathname &&
    shouldStorePrevPage(prevPageIgnoreList, location.pathname)
  ) {
    HCStorage.setItem(STORAGE_PREV_PAGE, location.pathname)
  }
}

type WrapPageElementParams = Parameters<
  NonNullable<GatsbyBrowser['wrapPageElement']>
>

const createWrapPageElement = (
  { element, props: { pageContext } }: WrapPageElementParams[0],
  AppLayout: React.FC<{ children?: React.ReactNode }>
) => {
  const i18nPageContext = pageContext as PageContext
  document.documentElement.lang = i18nPageContext.i18n.language || 'nl'

  const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const i18n = useI18next()
    setI18n(i18n.i18n)

    if (pageContext.layout === 'simple') {
      return <SimpleLayout>{children}</SimpleLayout>
    }

    return <AppLayout>{children}</AppLayout>
  }

  return React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(
        Layout,
        undefined,
        element.props.children.props.children
      )
    )
  )
}

const queryClient = new QueryClient()

const createWrapRootElement = (
  { element }: WrapRootElementBrowserArgs,
  THEME: DefaultTheme
) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={THEME}>
      <SharedGlobalStyle />
      {element}
    </ThemeProvider>
  </QueryClientProvider>
)

export const SharedGatsbyBrowser = {
  onRouteUpdate,
  createWrapPageElement,
  createWrapRootElement
}
