import { useCallback, useContext, useEffect, useState } from 'react'
import { HeadingTypes } from '@hc/shared/types/typography'
import { IconNames } from '@hc/shared/components/icon'
import { ICallToAction } from '@hc/shared/domains/storyblok/mappers/map-call-to-action'
import { Card, ICardProps } from '@hc/shared/components/card'
import { LayoutRow, LayoutColumn } from '@hc/shared/components/layout'
import { Link } from '@hc/shared/components/link'

import { HeaderContext } from '@/components/header/HeaderContext'

import {
  StyledContainer,
  StyledHeader,
  StyledIcon,
  StyledBackIcon,
  StyledLabel,
  StyledLink,
  StyledInner,
  StyledList,
  StyledItem,
  StyledNavSub,
  StyledButton,
  StyledCardsColumn,
  StyledRow,
  StyledColumn
} from './NavigationSub.style'

interface IProps {
  items: ICallToAction[] | undefined
  label?: string
  cardList?: ICardProps[] | undefined
  onClick: () => void

  [key: string]: unknown
}

export const NavigationSub = ({
  items,
  label,
  cardList,
  onClick,
  ...props
}: IProps): JSX.Element => {
  const { isActive, setIsActive } = useContext(HeaderContext)
  const [itemsPerRow, setItemsPerRow] = useState<number>(0)

  const handleToggleClick = useCallback(() => {
    setIsActive(false)
  }, [isActive])

  useEffect(() => {
    if (items?.length && items.length >= 15) {
      setItemsPerRow(Math.ceil(items?.length / 3))
    } else {
      setItemsPerRow(5)
    }
  }, [items])

  return (
    <StyledNavSub {...props}>
      <StyledContainer>
        <StyledInner>
          <StyledRow>
            <StyledColumn columns={{ LG: 8 }}>
              <StyledHeader>
                <StyledButton onClick={onClick}>
                  <StyledBackIcon hasCircle name={IconNames.CARET_LEFT} />
                </StyledButton>
                <StyledLabel type={HeadingTypes.DISPLAY_XXS}>
                  {label}
                </StyledLabel>
              </StyledHeader>
              <StyledList {...{ itemsPerRow }}>
                {items?.map(item => (
                  <StyledItem key={item._uid}>
                    <StyledLink link={item.link} onClick={handleToggleClick}>
                      {item.label}
                      <StyledIcon hasCircle name={IconNames.CARET_RIGHT} />
                    </StyledLink>
                  </StyledItem>
                ))}
              </StyledList>
            </StyledColumn>
            {cardList && (
              <StyledCardsColumn columns={{ LG: 4 }}>
                <LayoutRow>
                  {cardList.map(item => (
                    <LayoutColumn columns={{ LG: 6 }} key={item._uid}>
                      <Link link={item.link} onClick={handleToggleClick}>
                        <Card {...item} />
                      </Link>
                    </LayoutColumn>
                  ))}
                </LayoutRow>
              </StyledCardsColumn>
            )}
          </StyledRow>
        </StyledInner>
      </StyledContainer>
    </StyledNavSub>
  )
}
