export enum IconNames {
  CARET_DOWN = 'caret-down',
  CARET_LEFT = 'caret-left',
  CARET_RIGHT = 'caret-right',
  CARET_UP = 'caret-up',
  CARET_RIGHT_CIRCLE = 'caret-right-circle',
  CART = 'cart',
  CHECK = 'check',
  CHIP = 'chip',
  CLOVER = 'clover',
  CROSS = 'cross',
  DOWNLOAD = 'download',
  FACEBOOK = 'facebook',
  FILTER = 'filter',
  FILTER_LINE = 'filter-line',
  EYE = 'eye',
  EYE_OFF = 'eye_off',
  INFO = 'info',
  INFO_OUTLINE = 'info-outline',
  INSTAGRAM = 'instagram',
  LINK = 'link',
  LINKEDIN = 'linkedin',
  LOADER = 'loader',
  LOGO = 'logo',
  MENU = 'menu',
  MINUS = 'minus',
  PLUS = 'plus',
  SEARCH = 'search',
  TWITTER = 'twitter',
  USER = 'user',
  YOUTUBE = 'youtube',
  PLAY = 'play',
  ALERT = 'alert',
  ALERT_MINI = 'alert-mini',
  CARET_DOWN_THIN = 'caret-down-thin',
  DOTS = 'dots',
  DOWNLOAD_2 = 'download-2',
  INFO_MINI = 'info-mini',
  LEGAL_REPRESENTATION = 'legal-representation',
  LINK_THIN = 'link-thin',
  LOCATION = 'location',
  LINES = 'lines',
  MAIL = 'mail',
  MAIL_MINI = 'mail-mini',
  NEWS = 'news',
  ONLINE_CASINO = 'online-casino',
  PHONE = 'phone',
  PHONE_MINI = 'phone-mini',
  RESULTS = 'results',
  TELEPHONE = 'telephone',
  UPLOAD = 'upload',
  UPLOAD_2 = 'upload-2',
  VENUE = 'venue',
  WEB = 'web',
  PERCENTAGE_CIRCLE = 'percentage-circle',
  WHATSAPP = 'whatsapp'
}
