import { createContext } from 'react'

const initialActiveIndex: null | number = null
// eslint-disable-next-line @typescript-eslint/no-empty-function
const setActiveIndex: (index: number | null) => void = () => {}

export const NavigationContext = createContext({
  activeIndex: initialActiveIndex as null | number,
  setActiveIndex
})
