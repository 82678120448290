import { FetchedFooterNavList } from '../models/components/types'

import mapCallToAction, { ICallToAction } from './map-call-to-action'

export interface IFooterNavList {
  _uid: string
  label?: string
  items?: ICallToAction[] | undefined
}

const mapFooterNavList = ({
  label,
  items,
  ...props
}: FetchedFooterNavList): IFooterNavList => {
  return {
    label,
    items: items && items.map(item => mapCallToAction(item)),
    ...props
  }
}

export default mapFooterNavList
