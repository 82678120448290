import { useCallback, useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { IconNames } from '@hc/shared/components/icon'
import { getPagePathname } from '@hc/shared/utils/url'
import { useIsLG } from '@hc/shared/hooks/use-media-query'
import { HeadingTypes } from '@hc/shared/types/typography'

import { LogoutModal } from '@/components/logout-modal'
import { SITE_ROOT } from '@/const'
import { MyHcHeaderContext } from '@/components/myhc-header/MyHcHeaderContext'
import { myhcNavItems } from '@/components/myhc-navigation/utils'
import { useLogout } from '@/domains/ims/hooks'

import {
  StyledOverlay,
  StyledButton,
  StyledLogoLink,
  StyledLogo,
  StyledHeading,
  StyledBody,
  StyledIcon,
  StyledNav,
  StyledNavButton,
  StyledNavItem,
  StyledNavList
} from './MyHcNavigation.style'

export const MyHcNavigation = () => {
  const { isActive, setIsActive } = useContext(MyHcHeaderContext)
  const { activeIndex, setActiveIndex } = useContext(MyHcHeaderContext)
  const [logoutPopup, setLogoutPopup] = useState(false)
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)
  const pagePathname = getPagePathname(true)
  const { t } = useTranslation()
  const isLG = useIsLG()
  const { refetch: doLogout } = useLogout()

  const handleOnClick = useCallback((index: number) => {
    setActiveIndex(index)
    setIsActive(false)
  }, [])

  const onCloseLogoutModal = useCallback(async (isLogout: boolean) => {
    if (!isLogout) {
      setLogoutPopup(false)
    } else {
      setIsLoadingLogout(true)
      doLogout()
      navigate(SITE_ROOT)
    }
  }, [])

  useEffect(() => {
    myhcNavItems.forEach((item, index) => {
      if (pagePathname === item.url) {
        setActiveIndex(index)
      }
    })
  }, [pagePathname])

  return (
    <>
      <LogoutModal
        isLoading={isLoadingLogout}
        onClose={onCloseLogoutModal}
        isOpen={logoutPopup}
      />
      <StyledOverlay isActive={isActive} />
      <StyledNav isActive={isActive}>
        <StyledLogoLink link={{ href: SITE_ROOT }} aria-label="Home">
          <StyledLogo name={IconNames.LOGO} />
        </StyledLogoLink>
        <StyledHeading type={HeadingTypes.DISPLAY_XXS}>
          {t('navigation.myHC')}
        </StyledHeading>
        <StyledBody>
          <StyledNavList>
            {myhcNavItems && myhcNavItems.map((item, index) => (
              <StyledNavItem key={`nav-item-${index}`}>
                <StyledNavButton
                  onClick={() => handleOnClick(index)}
                  isActive={activeIndex === index}
                  link={{ href: item.url }}
                >
                  <StyledIcon
                    isActive={activeIndex === index}
                    name={IconNames.USER}
                  />
                  {t(`myHC.navigation.${item.title}`)}
                </StyledNavButton>
              </StyledNavItem>
            ))}
            <StyledNavItem>
              <StyledNavButton isAlternative link={{ href: SITE_ROOT }}>
                <StyledIcon name={IconNames.USER} />
                {t('myHC.navigation.backToHc')}
              </StyledNavButton>
            </StyledNavItem>
          </StyledNavList>
          <StyledButton
            label={t('navigation.logout')}
            isStretched={true}
            variant={isLG ? 'dark' : 'link'}
            icon={isLG && IconNames.FILTER_LINE}
            isReversed={true}
            onClick={() => {
              setLogoutPopup(true)
            }}
          />
        </StyledBody>
      </StyledNav>
    </>
  )
}
