import styled, { css } from 'styled-components'

import { HeadingTypes } from '../../../types/typography'

export type BorderType = 'primary' | 'secondary' | 'tertiary' | 'light'
export type Alignment = 'left' | 'center' | 'right'

interface IHeadingProps {
  type?: HeadingTypes
  border?: BorderType
  alignment?: Alignment
}

export const StyledHeading = styled.h1<IHeadingProps>`
  ${({ type, theme }) =>
    type &&
    css`
      ${theme.TYPOGRAPHY[type]};
    `};

  ${({ alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `};
`

export const StyledSpan = styled.span<IHeadingProps>`
  position: relative;
  padding-bottom: 1.6rem;
  display: inline-block;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding-bottom: 2.4rem;
  }

  &:after {
    content: '';
    border-bottom: 0.4rem solid ${({ theme }) => theme.COLOR.LIGHT_BLUE};
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;

    ${({ border, theme }) =>
      border === 'secondary' &&
      css`
        border-color: ${theme.COLOR.GOLD};
      `};

    ${({ border, theme }) =>
      border === 'tertiary' &&
      css`
        border-color: ${theme.COLOR.PINK};
      `};

    ${({ border, theme }) =>
      border === 'light' &&
      css`
        border-color: ${theme.COLOR.NEUTRAL_10};
      `};
  }
`
