import styled from 'styled-components'

import { Button } from './Button'

export const StyledFilterButton = styled(Button)`
  position: sticky;
  bottom: 1.6rem;
  margin: 0 -0.8rem -0.8rem;
  width: calc(100% + 1.6rem);
`
