import {
  StoryblokBridgeConfigV2,
  StoryblokBridgeV2
} from 'gatsby-source-storyblok'

let bridgePromise: Promise<StoryblokBridgeV2>

const loadBridge = (bridgeOptions?: StoryblokBridgeConfigV2) => {
  return new Promise<StoryblokBridgeV2>((resolve, reject) => {
    if (typeof window === 'undefined') {
      return
    }
    if (document.getElementById('storyblok-javascript-bridge')) {
      return
    }

    const script = document.createElement('script')
    script.async = true
    script.src = 'https://app.storyblok.com/f/storyblok-v2-latest.js'
    script.id = 'storyblok-javascript-bridge'

    script.onerror = error => {
      reject(error)
      console.log(error)
    }
    script.onload = () => {
      resolve(new window.StoryblokBridge(bridgeOptions))
    }

    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

export const getStoryblokBridge = async (
  bridgeOptions?: StoryblokBridgeConfigV2
): Promise<StoryblokBridgeV2> => {
  if (!bridgePromise) {
    bridgePromise = loadBridge(bridgeOptions)
  }

  return bridgePromise
}

export const USE_STORYBLOK_BRIDGE =
  typeof window !== 'undefined' && window.location !== window.parent.location
