import { DateTime } from 'luxon'

import { CartValidationErrorCode, CartItemVendorId } from './cart.api.types'

export interface CartBaseItem {
  productId: number
  vendorId: CartItemVendorId
}

export interface CartItem extends CartBaseItem {
  quantity: number
  price: number
  description: string
  startDate: DateTime
  startTime: DateTime
  venue: string
}

interface CartValidationError {
  code: CartValidationErrorCode
  message: string
}

export interface CartItemValidated extends CartItem {
  errors: CartValidationError[]
}

export interface Cart<T = CartItem> {
  cartId: string
  expire: string
  totalPrice: number
  items: T[]
}

export interface CheckoutRedirectURLs {
  success: string
  error: string
  back: string
  pending: string
}

export interface Checkout {
  redirectUrls: CheckoutRedirectURLs
  cartId: string
  orderId: string
  paymentNeeded: boolean
  paymentUrl?: string
}

export interface ValidatedCart extends Cart<CartItemValidated> {
  valid: boolean
}

export class CartError extends Error {
  constructor(message: string) {
    super(`CartError: ${message}`)
    Object.setPrototypeOf(this, CartError.prototype)
  }
}
