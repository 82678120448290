import styled, { css } from 'styled-components'
import {
  LayoutColumn, LayoutContainer, LayoutRow
} from '@hc/shared/components/layout'
import { Heading } from '@hc/shared/components/typography'
import { Icon } from '@hc/shared/components/icon'
import { Link } from '@hc/shared/components/link'
import { HeadingTypes } from '@hc/shared/types/typography'

interface StyledNavSubProps {
  isActive?: boolean
}

export const StyledNavSub = styled.div<StyledNavSubProps>`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    transition: .3s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
    transform: translateX(100vw);
    position: fixed;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    transform: translateY(-100vh);
    position: fixed;
    width: 100%;
    left: 0;
    top: 8.8rem;
    z-index: -1;
  }

  ${({ isActive }) => (isActive) && css`
    @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
      transform: translateX(0);
    }

    @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
      transform: translateY(0);
    }
  `};
`

export const StyledHeader = styled.div`
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    border-bottom: 1px solid ${({ theme }) => theme.COLOR.BLUE_20}20;
  }
  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-bottom: 2.4rem;
  }
`

export const StyledLabel = styled(Heading)`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const StyledButton = styled.button`
  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.BLUE_10};
  color: ${({ theme }) => theme.COLOR.BLUE_10};

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledBackIcon = styled(StyledIcon)`
  height: 3.2rem;
  padding: 1rem;
`

export const StyledContainer = styled(LayoutContainer)`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    padding: 0;
    height: 100%;
  }
`

export const StyledRow = styled(LayoutRow)`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    height: 100%;
  }
`

export const StyledColumn = styled(LayoutColumn)`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const StyledInner = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    height: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    padding: 6.4rem 2.4rem 6.4rem 12.4rem;
    margin: 0 -2.4rem;
    border-radius: 0 0 ${({ theme }) => theme.BORDER_RADIUS.S}rem ${({ theme }) => theme.BORDER_RADIUS.S}rem;
    border: 1px solid ${({ theme }) => theme.COLOR.BLUE_20}20;
    border-top: none;
  }
`

interface IStyledListProps {
  itemsPerRow?: number
}

export const StyledList = styled.ul<IStyledListProps>`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    overflow: auto;
    flex-grow: 1;
  }
  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    ${({ itemsPerRow }) => (itemsPerRow) && css`
      max-height: calc(${itemsPerRow} * 3.52rem);
    `};
  }
`

export const StyledItem = styled.li`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    & + & {
      border-top: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    width: calc(100% / 3);
  }
`

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  padding: 2.8rem;
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-weight: 400;
    padding: 0;
    margin-top: 1.6rem;
  }
`

export const StyledCardsColumn = styled(LayoutColumn)`
  @media screen and (max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: none;
  }
`
