import { useStaticQuery, graphql } from 'gatsby'
import {
  useVenuesShared,
  UseVenues
} from '@hc/shared/domains/storyblok/hooks/use-venues-shared'

export const useVenues = (): UseVenues => {
  const data: Queries.VenuesQuery = useStaticQuery(graphql`
    query Venues {
      venues: allStoryblokEntry(
        sort: { name: ASC }
        filter: { field_component: { eq: "venue" } }
      ) {
        edges {
          node {
            name
            uuid
            content
            field_component
            position
            lang
            slug
          }
        }
      }
    }
  `)

  return useVenuesShared(data)
}
