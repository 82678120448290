export interface IjackpotLevel {
  name: string
  amount: string
}

export interface IjackpotValue {
  active: string
  name: string
  levels: IjackpotLevel[]
  category: string
}

export interface IjackpotSiteWrapper {
  site_code: string
  values: IjackpotValue[]
}

export interface Ijackpot {
  jackpot_values: IjackpotSiteWrapper[]
}

export interface IJackpotAmount {
  min: number
  max: number
}

export interface IJackpotFilters {
  siteCode: Array<string>
  amount: IJackpotAmount
}

export interface IJackpotValuesResponseLevel {
  amount: string
  name: string
}

export interface IJackpotValuesResponseValue {
  active: string
  levels: IJackpotValuesResponseLevel[]
  name: string
  category: string
}

export interface IJackpotValuesResponseSiteWrapper {
  site_code: string
  values: IJackpotValuesResponseValue[]
}

export interface IJackpotValuesResponse {
  jackpot_values: IJackpotValuesResponseSiteWrapper[]
}

export const PROGRESSIVE_JACKPOT_SITECODE = 'HK'
