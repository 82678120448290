import { ICardProps } from '../../../components/card'
import { FetchedCard } from '../models/components/types'

import mapLink from './map-link'

const mapCard = ({ image, link, ...props }: FetchedCard): ICardProps => {
  return {
    image,
    link: link && mapLink(link),
    ...props
  }
}

export default mapCard
