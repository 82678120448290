import libphonenumber from 'google-libphonenumber'

import {
  Countries,
  CountryCodePhone,
  getCountryPhoneAreaCodes
} from '../../../types/countries'

export const mergePhoneNumberFields = (phoneNumber: string, prefix: string) => {
  return (prefix + phoneNumber).replace(/\s/g, '')
}

export const getPhoneAreaCodes = () => {
  return Countries.map(country => country.phoneAreaCode)
}

export const areaCodeExists = (areaCode: string) => {
  const availableAreaCodes = getCountryPhoneAreaCodes()
  return availableAreaCodes.includes(areaCode as CountryCodePhone)
}

export const getPhoneNumberWithPlusPrefix = (phone: string) => {
  // removes leading zero's from phone number
  if (phone && phone[0] === '+') {
    return phone
  }
  return `+${phone.replace(/^00/, '')}`
}

export const splitPhoneNumberWithAreaCode = (phone: string) => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
  const prefixedNumber = getPhoneNumberWithPlusPrefix(phone)
  let splittedNumber = {
    areaCode: '',
    phoneNumber: phone
  }
  try {
    const parsedNumber = phoneUtil.parse(prefixedNumber, '')
    const areaCode = parsedNumber?.getCountryCode()?.toString()
    const phoneNumber = parsedNumber?.getNationalNumber()?.toString()

    splittedNumber = areaCodeExists(`+${areaCode}`)
      ? {
          areaCode: `+${areaCode}`,
          phoneNumber: phoneNumber || phone
        }
      : {
          areaCode: '',
          phoneNumber: phone
        }
  } catch (e) {}
  return splittedNumber
}
