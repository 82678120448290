import { z } from 'zod'

export const CDDNAddressSchema = z.object({
  street: z.string(),
  houseNumber: z.number(),
  postalCode: z.string(),
  city: z.string(),
  municipality: z.string(),
  province: z.string(),
  houseNumberAdditions: z.array(z.string()),
  latitude: z.number(),
  longitude: z.number()
})

export const CDDNGetAddressDetailsSchema = z.object({
  status: z.enum(['OK', 'NotFound']),
  address: z.nullable(CDDNAddressSchema)
})

export type CDDNGetAddressDetailsResponse = z.infer<
  typeof CDDNGetAddressDetailsSchema
>

export class CDDNGetAddressDetailsValidationError extends Error {
  constructor(message: string) {
    super(`CDDNGetAddressDetailsValidationError: ${message}`)
    Object.setPrototypeOf(this, CDDNGetAddressDetailsValidationError.prototype)
  }
}
